import React, { useEffect, useState } from "react";
import ActiveLastBreadcrumb from "../../components/ActiveLastBreadcrumb";
import { useNavigate, useParams } from "react-router-dom";
import { UserIcon } from "../../assets";
import CustomTabs from "../../components/CustomTabs";
import { CircularProgress } from "@mui/material";
import CustomPopoverSection from "../../components/menuOption/CustomPopoverSection";
import { _delete_user_api, _detail_client_api } from "../../DAL/User/User";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import CustomPopover from "../../components/CustomPopover";
import DeleteConfirmation from "../../components/DeleteConfirmation";
import { s3BaseUrl } from "../../config/config";
import { fShortenNumber } from "../../utils/constant";
import TransactionUser from "./ProfileTabs/TransactionUser";
import SubscriptionUser from "./ProfileTabs/SubscriptionUser";
import InvoiceUser from "./ProfileTabs/InvoiceUser";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const status_object = ["invoice", "transection", "subscription", "customer"];

const UserProfile = () => {
  const classes = useStyles();
  const params = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [tabValue, setTabValue] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isTabLoading, setIsTabLoading] = useState(false);
  const [tabData, setTabData] = useState([]);
  const [userList, setUserList] = useState({});
  const [open1, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [userAmount, setUserAmount] = useState({});
  const [openDelete, setOpenDelete] = useState(false);
  const [delLoading, setDelLoading] = useState(false);
  const [formData, setFormData] = useState(true);
  const [editData, setEditData] = useState({});

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const get_profile_detail = async (type) => {
    setIsTabLoading(true);
    let tab_name = status_object[tabValue];

    if (tab_name == "invoice") {
      tab_name = "";
    }
    let postData = {
      business_id: params.user_id,
      tab: tab_name,
    };
    const result = await _detail_client_api(postData);
    if (result.code == 200) {
      if (!tab_name || type) {
        setUserList(result.client?.business);
        setUserAmount(result.client);
      }
      setTabData(result.client?.tab_data);
      setIsLoading(false);
      setIsTabLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
      setIsTabLoading(false);
    }
  };

  const TABS_OPTIONS = [
    {
      title: "Invoices",
      component: isTabLoading ? (
        <CircularProgress className={classes.loading} color="primary" />
      ) : (
        <InvoiceUser tabData={tabData} />
      ),
    },
    {
      title: "Transactions",
      component: isTabLoading ? (
        <CircularProgress className={classes.loading} color="primary" />
      ) : (
        <TransactionUser tabData={tabData} />
      ),
    },
    {
      title: "Subscriptions",
      component: isTabLoading ? (
        <CircularProgress className={classes.loading} color="primary" />
      ) : (
        <SubscriptionUser tabData={tabData} />
      ),
    },
  ];

  let breadCrumbMenu = [
    {
      title: "Users",
      navigation: `/users`,
      active: false,
    },
    {
      title: "Jhon Doe (jhondoe@gmail.com)",
      // userList?.first_name +
      // " " +
      // userList?.last_name +
      // " (" +
      // userList?.email +
      // ")",
      active: true,
    },
  ];

  const handleClickEdit = (row) => {
    setEditData(row);
    setOpen(true);
    setFormData(false);
  };

  const handleAgreeDelete = (row) => {
    setDeleteId(row._id);
    setOpenDelete(true);
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    // setDelLoading(true);
    // const result = await _delete_user_api(deleteId);
    // if (result.code === 200) {
    //   navigate(`/busines-clients`);
    //   setOpenDelete(false);
    //   setDelLoading(false);
    //   enqueueSnackbar(result.message, { variant: "success" });
    // } else {
    //   setDelLoading(false);
    //   enqueueSnackbar(result.message, { variant: "error" });
    // }
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleClickEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  // useEffect(() => {
  //   get_profile_detail();
  // }, [tabValue]);

  // if (isLoading) {
  //   return <CircularProgress className={classes.loading} color="primary" />;
  // }

  return (
    <>
      <DeleteConfirmation
        open={openDelete}
        isLoading={delLoading}
        setOpen={setOpenDelete}
        title={"Are you sure you want to remove this user ?"}
        handleAgree={handleDelete}
      />
      <div className="row user_profile">
        <div className="d-flex justify-content-between align-items-center flex-wrap  ">
          <div className="page-title-box">
            <h4>User Profile</h4>
          </div>
          <div>
            <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
          </div>
        </div>
        <div className="col-12 col-md-6 mt-3">
          <div className="card text-center profile-card">
            <div className="card-body">
              <h3>£{fShortenNumber(userAmount?.total_amount)}</h3>
              <h6 className="text-muted">Total Amount</h6>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6 mt-3">
          <div className="card text-center profile-card">
            <div className="card-body">
              <h3>£{fShortenNumber(userAmount?.total_dp_fee)}</h3>
              <h6 className="text-muted">Total DP Fee</h6>
            </div>
          </div>
        </div>
        <div className="col-12 mt-3 user-profile-detail">
          <div className="card">
            <div className="card-body">
              <div className="row client_profile">
                <div className="col-12 col-md-7 d-flex align-items-center">
                  <img
                    className="rounded-circle"
                    height="75px"
                    width="75px"
                    src={
                      userList?.image ? s3BaseUrl + userList?.image : UserIcon
                    }
                  />
                  <div className="ms-3">
                    <p className="m-0 fw-bold ">
                      {/* {userList?.first_name + " " + userList?.last_name} */}
                      Jhon Doe
                    </p>
                    <div className="text-muted fw-14">
                      {/* {userList?.email} */}
                      jhondeo@gmail.com
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-5 d-flex justify-content-end">
                  <div className="d-flex me-1 text-muted fw-14 m_top">
                    <div>Created At:&nbsp;</div>
                    {moment(userList?.createdAt).format("DD-MM-YYYY")}
                  </div>
                  <CustomPopoverSection menu={MENU_OPTIONS} data={userList} />
                </div>
              </div>
              <div className="row mt-4 client_profile">
                <div className="col-5 col-md-6 col-lg-3 mb-3">
                  <h6>Phone Number:</h6>
                </div>
                <div className="col-7 col-md-6 col-lg-3 text_muted">
                  {/* {`+${userList?.contact_number}`} */}
                  +923037301108
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-3">
          <CustomTabs
            data={TABS_OPTIONS}
            value={tabValue}
            handleChange={handleChange}
          />
        </div>
      </div>
      {/* <CustomPopover
        isOpenPop={open1}
        isClosePop={setOpen}
        title={formData ? "Add Client" : "Edit Client"}
        componentToPassDown={
          <AddOrUpdateClient
            formType={formData}
            setOpen={setOpen}
            get_user_list={get_profile_detail}
            editData={editData}
          />
        }
      /> */}
    </>
  );
};

export default UserProfile;
