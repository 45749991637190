import React, { useEffect, useState } from "react";
import ChatCard from "./ChatCard";
import ActiveLastBreadcrumb from "../../../components/ActiveLastBreadcrumb";
import {
  audioImage,
  csvImage,
  docImage,
  otherImage,
  pdfImage,
  wordImage,
  xlsxImage,
} from "../../../assets";
import { useLocation, useParams } from "react-router-dom";
import {
  _list_comment_with_support_ticket_api,
  _mark_resolve_support_ticket_api,
} from "../../../DAL/SupportTicket";
import CustomConfirmation from "../../../components/CustomConfirmation";
import { s3BaseUrl } from "../../../config/config";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import moment from "moment";
import { htmlDecode } from "../../../utils/convertHtml";
import { Avatar, Button, CircularProgress } from "@mui/material";
import InternalNote from "./TabsData/InternalNote";
import FullPagePopupForTitle from "../../../components/FullPagePopupForTitle";
import CustomPopover from "../../../components/CustomPopover";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const TicketsDetail = () => {
  const classes = useStyles();
  const params = useParams();
  const state = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [ticketData, setTicketData] = useState({});
  const [commentData, setCommentData] = useState([]);
  const [open, setOpen] = useState(false);
  const [markLoadin, setMarkLoading] = useState(false);
  const [drawerInternalNote, setdrawerInternalNote] = useState(false);
  const [openImage, setOpenImage] = useState(false);
  const [imageData, setImageData] = useState("");

  const get_support_tickets_comment = async () => {
    setIsLoading(true);
    const result = await _list_comment_with_support_ticket_api(
      params.detail_id
    );
    if (result.code === 200) {
      setTicketData(result.support_ticket);
      localStorage.setItem(
        "internal_notes_count",
        result.support_ticket.internal_notes.length
      );
      setCommentData(result.list_support_ticket_comment);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleOpenNoteDrawer = () => {
    setdrawerInternalNote(true);
  };

  const handleCloseNoteDrawer = () => {
    setdrawerInternalNote(false);
  };

  const imageLinks = {
    docx: wordImage,
    mp3: audioImage,
    pdf: pdfImage,
    csv: csvImage,
    doc: docImage,
    xlsx: xlsxImage,
    xls: xlsxImage,
    other: otherImage,
  };

  const getCommentImage = (file) => {
    const ext = file.split(".").pop();
    if (
      ext == "jpg" ||
      ext == "JPG" ||
      ext == "png" ||
      ext == "webp" ||
      ext == "jpeg" ||
      ext == "JPEG" ||
      ext == "PNG"
    ) {
      return s3BaseUrl + file;
    } else if (imageLinks[ext]) {
      return imageLinks[ext];
    } else {
      return imageLinks.other;
    }
  };

  const handleOpenImage = (image) => {
    setOpenImage(true);
    setImageData(image);
    console.log("image _image", image);
  };

  let breadCrumbMenu = [
    {
      title: "Support Tickets",
      navigation: `/support-tickets/${params.type}`,
      active: false,
      tab_Value: state,
    },
    {
      title: ticketData?.subject,
      active: true,
    },
  ];

  useEffect(() => {
    get_support_tickets_comment();
  }, []);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <CustomPopover
        isOpenPop={openImage}
        isClosePop={setOpenImage}
        // title="View Full Image"
        title=""
        width="600px"
        imageContainer={true}
        componentToPassDown={
          <>
            <div className="d-flex justify-content-center">
              <img
                className="p-0 "
                style={{ maxWidth: "100%" }}
                src={s3BaseUrl + imageData}
                alt="full image"
              />
            </div>
          </>
        }
      />

      <div className="container">
        <div className="row">
          <div className="d-flex justify-content-between align-items-center  mb-3 ">
            <span>
              <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
            </span>
            <Button variant="contained" onClick={handleOpenNoteDrawer}>
              Internal Notes (
              {localStorage.getItem("internal_notes_count") !== null
                ? localStorage.getItem("internal_notes_count")
                : "0"}
              )
            </Button>
          </div>
        </div>
        <div className="row my-3 internal_card">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              <Avatar
                alt=""
                src={s3BaseUrl + ticketData?.user_info?.profile_image}
                onClick={() =>
                  handleOpenImage(ticketData?.user_info?.profile_image)
                }
                className="pointer"
              />
              <div className="ms-2">
                <span className="mb-0 fw-14 fw-bold">
                  {ticketData.user_info?.first_name +
                    " " +
                    ticketData.user_info?.last_name}
                </span>
                <p className="fw-14 mb-0">{ticketData.user_info?.email}</p>
              </div>
            </div>
            <div className="text-end">
              {ticketData.support_ticket_date && (
                <span>
                  <span className="fw-bold">Created:</span>{" "}
                  {moment(ticketData.support_ticket_date).format(
                    // "DD-MMMM-YYYY hh:mm A"
                    "DD-MM-YYYY hh:mm A"
                  )}
                </span>
              )}
              <br />
              {ticketData.resolve_date && (
                <span>
                  <span className="fw-bold">Responded: </span>{" "}
                  {moment(ticketData.resolve_date).format(
                    // "DD-MMMM-YYYY hh:mm A"
                    "DD-MM-YYYY hh:mm A"
                  )}
                </span>
              )}
            </div>
          </div>
          <div className="mt-3">
            <h4>{htmlDecode(ticketData.subject)}</h4>
            <p>{htmlDecode(ticketData.description)}</p>
          </div>
          <div className="d-flex flex-wrap">
            {ticketData.ticket_images &&
              ticketData.ticket_images.map((image, i) => {
                if (!image?.code && !!image) {
                  const ext = image.split(".").pop();
                  if (
                    ext == "jpg" ||
                    ext == "JPG" ||
                    ext == "png" ||
                    ext == "webp" ||
                    ext == "jpeg" ||
                    ext == "JPEG" ||
                    ext == "PNG"
                  ) {
                    return (
                      <span
                        onClick={() => handleOpenImage(image)}
                        className="preview pointer"
                        key={i}
                      >
                        <img className="p-0" src={s3BaseUrl + image} />
                      </span>
                    );
                  } else {
                    return (
                      <span
                        onClick={() => handleOpenImage(image)}
                        className="preview pointer"
                        key={i}
                      >
                        <a
                          href={s3BaseUrl + image}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img src={getCommentImage(image)} />
                        </a>
                      </span>
                    );
                  }
                }
              })}
          </div>
        </div>
        <ChatCard
          ticketData={ticketData}
          commentData={commentData}
          setCommentData={setCommentData}
          get_data_list={get_support_tickets_comment}
        />
        <FullPagePopupForTitle
          open={drawerInternalNote}
          handleClosePopup={handleCloseNoteDrawer}
          title={`${
            ticketData.user_info?.first_name +
            " " +
            ticketData.user_info?.last_name +
            " (" +
            ticketData.user_info?.email +
            ")"
          } | ${ticketData.subject}`}
          disableTitle={true}
          componentToPassDown={
            <InternalNote
              handleClosePopup={handleCloseNoteDrawer}
              title={`${
                ticketData.user_info?.first_name +
                " " +
                ticketData.user_info?.last_name +
                " (" +
                ticketData.user_info?.email +
                ")"
              } | ${ticketData.subject}`}
              ticketId={ticketData}
            />
          }
        />
      </div>
    </>
  );
};

export default TicketsDetail;
