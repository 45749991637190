import React, { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useSnackbar } from "notistack";
import { s3BaseUrl } from "../../config/config";
import ActiveLastBreadcrumb from "../../components/ActiveLastBreadcrumb";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { useNavigate, useParams } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import {
  _add_admin_user,
  _detail_admin_by_userid,
  _edit_admin_by_admin,
} from "../../DAL/AdminUsers/AdminUsers";
import MUIAutocomplete from "../../components/MUIAutocomplete";

const Input = styled("input")({
  display: "none",
});

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const AddOrUpdateAdminUser = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { user_id } = useParams();
  const navigate = useNavigate();
  const [file, setProfileImage] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(true);

  const [inputs, setInputs] = useState({
    first_name: "",
    last_name: "",
    email: "",
    image: "",
    oldimage: "",
    password: "",
    status: true,
    role: "admin",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const fileChangedHandler = (e) => {
    setProfileImage(URL.createObjectURL(e.target.files[0]));
    setInputs({
      ...inputs,
      image: e.target.files[0],
    });
  };

  const get_user_detail = async () => {
    setIsPageLoading(true);
    const result = await _detail_admin_by_userid(user_id);
    if (result.code === 200) {
      setInputs(() => ({
        ...inputs,
        ...result.user,
        oldimage: result.user?.profile_image,
        email: result.user?.user_id?.email,
        role: result.user?.role,
      }));
      setIsPageLoading(false);
    } else {
      setIsPageLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("first_name", inputs.first_name);
    formData.append("last_name", inputs.last_name);
    formData.append("email", inputs.email);
    formData.append("status", inputs.status);
    formData.append("role", inputs.role);
    if (!user_id) {
      formData.append("password", inputs.password);
    }
    if (file) {
      formData.append("image", inputs.image);
    }
    setIsLoading(true);
    const result = user_id
      ? await _edit_admin_by_admin(formData, user_id)
      : await _add_admin_user(formData);
    if (result.code === 200) {
      navigate(`/admin-users`);
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
    setIsLoading(false);
  };

  let breadCrumbMenu = [
    {
      title: "Admin Users",
      navigation: `/admin-users`,
      active: false,
    },
    {
      title: user_id ? "Edit Admin User" : "Add Admin User",
      active: true,
    },
  ];

  useEffect(() => {
    if (user_id) {
      get_user_detail();
    } else {
      setIsPageLoading(false);
    }
  }, [user_id]);

  if (isPageLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="d-flex justify-content-between align-items-center flex-wrap ">
          <div className="page-title-box">
            <h4>{user_id ? "Edit Admin User" : "Add Admin User"}</h4>
          </div>
          <div>
            <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
          </div>
        </div>
        <div className="col-12 col-md-6 mt-3">
          <TextField
            id="outlined-basic"
            label="First Name"
            variant="outlined"
            fullWidth
            required
            name="first_name"
            value={inputs.first_name}
            placeholder="First Name *"
            onChange={handleChange}
          />
        </div>
        <div className="col-12 col-md-6 mt-3">
          <TextField
            id="outlined-basic"
            label="Last Name"
            variant="outlined"
            fullWidth
            required
            name="last_name"
            value={inputs.last_name}
            placeholder="Last Name *"
            onChange={handleChange}
          />
        </div>
        <div className="col-12 col-md-6 mt-3">
          <TextField
            id="outlined-basic"
            label="Email"
            variant="outlined"
            required
            fullWidth
            name="email"
            value={inputs.email}
            placeholder="Email *"
            onChange={handleChange}
          />
        </div>
        {!user_id && (
          <div className="col-12 col-md-6 mt-3">
            <TextField
              id="outlined-basic"
              label="Password"
              variant="outlined"
              required
              fullWidth
              name="password"
              value={inputs.password}
              placeholder="Password *"
              onChange={handleChange}
            />
          </div>
        )}
        <div className="col-12 col-md-6 mt-3">
          <FormControl fullWidth required>
            <InputLabel id="demo-simple-select-label">Status</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={inputs.status}
              label="Status"
              name="status"
              required
              onChange={handleChange}
            >
              <MenuItem value={true}>Active</MenuItem>
              <MenuItem value={false}>Disable</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="col-12 col-md-6 mt-3">
          <FormControl fullWidth required>
            <InputLabel id="demo-simple-select-label">Role</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={inputs.role}
              label="Role"
              name="role"
              required
              onChange={handleChange}
            >
              <MenuItem value="admin">Admin</MenuItem>
              <MenuItem value="manager">Manager</MenuItem>
              <MenuItem value="team">Team</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="col-12 mt-4">
          <div className="row w-100 div-style ms-0 pt-0">
            <div className="col-lg-5">
              <p className="text-black">Upload Image</p>
              <FormHelperText className="pt-0 helper_text">
                Image Size(1000 X 670) ("JPG", "JPEG", "PNG","WEBP")
              </FormHelperText>
            </div>
            <div className="col-lg-2">
              <img
                src={
                  file
                    ? file
                    : inputs.oldimage
                    ? s3BaseUrl + inputs.oldimage
                    : ""
                }
                height="50"
              />
            </div>
            <div className="col-lg-5 text-end pt-2">
              <label htmlFor="contained-button-file">
                <Input
                  accept="image/*"
                  id="contained-button-file"
                  multiple
                  type="file"
                  name="image"
                  onChange={fileChangedHandler}
                />

                <Button
                  className="btn btn-primary "
                  startIcon={<FileUploadIcon />}
                  component="span"
                >
                  Upload
                </Button>
              </label>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-end gap-3 mt-4">
          <Button variant="contained" type="submit">
            {user_id
              ? `${isLoading ? "Loading.." : "Update"}`
              : `${isLoading ? "Loading.." : "Add Admin"}`}
          </Button>
        </div>
      </div>
    </form>
  );
};

export default AddOrUpdateAdminUser;
