import React, { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  TextareaAutosize,
} from "@mui/material";
import ActiveLastBreadcrumb from "../../components/ActiveLastBreadcrumb";
import { useSnackbar } from "notistack";
import { _add_transaction_api } from "../../DAL/Transaction/Transaction";
import MUIAutocomplete from "../../components/MUIAutocomplete";
import {
  _add_payment_request_api,
  _get_payment_request_api,
  _payment_request_paid_or_canceled,
  _update_payment_request_api,
} from "../../DAL/Payment";
import {
  _user_list_api,
  _users_list_for_payment_request_api,
} from "../../DAL/User/User";
import { useNavigate, useParams } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import GeneralCkeditor from "../../components/GeneralCkeditor";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const PaymentRequestPaidOrCanceled = ({
  rowData,
  type,
  setOpenPopover,
  paymentList,
  setPaymentList,
  tabType,
  get_payment_list,
}) => {
  const classes = useStyles();
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [personName, setPersonName] = useState([]);
  const [member, setMember] = useState(null);
  const [userName, setUserName] = useState([]);
  const [user, setUser] = useState(null);
  const [installmentAmount, setInstallmentAmount] = useState("");
  const [paymentType, setPaymentType] = useState(["Credit Card"]);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [getLoading, setGetLoading] = useState(true);

  const [inputs, setInputs] = useState({
    total_amount: "",
    member: "",
    request_type: "onetime",
    currency: "GBP",
    note: "",
    client_info: {
      first_name: "",
      last_name: "",
      email: "",
      user_id: "",
    },
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const updateListObject = () => {
    if (tabType == "all") {
      get_payment_list();
      //   const newList = paymentList.map((item) => {
      //     if (item._id === rowData?._id) {
      //       return {
      //         ...item,
      //         is_first_paid: true,
      //         payment_status: "paid",
      //         note: inputs?.note,
      //       };
      //     }
      //     return item;
      //   });
      //   setPaymentList(newList);
    } else {
      let user_list = paymentList.filter(
        (user_id) => user_id._id !== rowData?._id
      );
      setPaymentList(user_list);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!inputs?.note) {
      let error = "Note is Required";
      enqueueSnackbar(error, { variant: "error" });
      return;
    }

    let postData = {
      mark_as_paid: type === "paid" ? true : false,
      mark_as_canceled: type === "paid" ? false : true,
      note: inputs?.note,
    };

    setIsLoading(true);
    const result = await _payment_request_paid_or_canceled(
      postData,
      rowData?._id
    );

    if (result.code === 200) {
      updateListObject();
      enqueueSnackbar(result.message, { variant: "success" });
      setOpenPopover(false);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    let memberName = "";
    if (rowData?.client_info) {
      memberName =
        rowData?.client_info?.first_name +
        " " +
        rowData?.client_info?.last_name;
    }

    setInputs({
      ...inputs,
      ...rowData,
      member: memberName,
    });
    console.log("rowData ___rowData", rowData);
  }, [rowData]);

  return (
    <>
      <div className="card mt-2">
        <div className="card-body">
          <form className="row" onSubmit={handleSubmit}>
            <div className="col-12 col-md-6 mt-2">
              <TextField
                id="outlined-basic"
                label="Member"
                placeholder="Member"
                variant="outlined"
                fullWidth
                name="member"
                value={inputs.member}
                onChange={handleChange}
                disabled
              />
            </div>

            <div className="col-12 col-md-6 mt-2">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Currency</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="currency"
                  value={inputs.currency}
                  label="currency"
                  onChange={handleChange}
                  disabled
                >
                  <MenuItem value="GBP">UK Pounds</MenuItem>
                  <MenuItem value="EUR">Euro</MenuItem>
                </Select>
              </FormControl>
            </div>

            <div className="col-12 col-md-6 mt-4">
              <FormControl fullWidth required>
                <InputLabel id="demo-simple-select-label">
                  Request Type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="request_type"
                  value={inputs.request_type}
                  label="Request Type"
                  onChange={handleChange}
                  disabled
                >
                  <MenuItem value="onetime">One Time</MenuItem>
                  <MenuItem value="recurring">Recurring</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="col-12 col-md-6 mt-4">
              <TextField
                id="outlined-basic"
                label="Total Amount"
                placeholder="Total Amount"
                variant="outlined"
                fullWidth
                type="number"
                name="total_amount"
                value={inputs.total_amount}
                onChange={handleChange}
                disabled
              />
            </div>

            <div className="col-12 mt-3">
              <h6 className="text-black">Note *</h6>
              <GeneralCkeditor
                setInputs={setInputs}
                inputs={inputs}
                name="note"
                // editorHeight={200}
              />
            </div>

            {/* <div className="col-12 mt-3">
              <div className="mb-1 fw-14 ms-1">Note</div>
              <TextareaAutosize
                aria-label="empty textarea"
                className="textarea-autosize"
                placeholder="Note"
                name="note"
                value={inputs?.note}
                required
                onChange={handleChange}
                style={{
                  width: "100%",
                  height: "100px",
                  backgroundColor: "white",
                  color: "#000",
                  borderRadius: "5px",
                  outline: "none",
                  paddingTop: "8px",
                  paddingLeft: "10px",
                  border: "1px solid #00000038",
                }}
              />
            </div> */}
            <div className="text-end mt-4">
              <Button
                variant="contained"
                color="error"
                onClick={() => {
                  setOpenPopover(false);
                }}
              >
                Cancel
              </Button>

              <Button className="ms-3" type="submit" variant="contained">
                {isLoading ? "Loading.." : "Agree"}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default PaymentRequestPaidOrCanceled;
