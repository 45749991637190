import React, { useEffect, useState } from "react";
import { Button, CircularProgress } from "@mui/material";
import CustomTable from "../../components/customTable/CustomTable";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { _list_subscription_api } from "../../DAL/Subscriptions";
import moment from "moment";
import { PAYMENT_STATUS, show_proper_words } from "../../utils/constant";
import CustomDrawer from "../../components/CustomDrawer";
import SubscriptionFilter from "./SubscriptionFilter";
import {
  check_from_local_storage,
  get_from_localStorage,
  set_to_localStorage,
} from "../../utils/LocalStorage";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { projectMode } from "../../config/config";
import ReactFilterChips from "react-filter-chips";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const currentDate = moment();

let selected_transaction_mode = [];
let selected_status = [
  {
    _id: "1",
    chip_value: "active",
    chip_label: "Active",
  },
];

if (projectMode == "dev") {
  selected_transaction_mode = [
    {
      _id: "1",
      chip_value: "sandBox",
      chip_label: "SandBox",
    },
  ];
}

if (projectMode == "prod") {
  selected_transaction_mode = [
    {
      _id: "2",
      chip_value: "live",
      chip_label: "Live",
    },
  ];
}

const EMPTY_FILTER = {
  user_type: 0,
  date_from: moment(currentDate).format("YYYY-MM-DD"),
  date_to: moment(currentDate).format("YYYY-MM-DD"),
  userObj: null,
  user_obj_id: "",
  invoiceObj: null,
  invoice_obj_id: "",
  transaction_platform: "all",
  transaction_made_by: "all",
  transaction_mode: "",
  transaction_status: "",
  agreementCheckBox: false,
  selectedStatus: selected_status,
  selectedTransactionMode: selected_transaction_mode,
};

const Subscriptions = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [userList, setUserList] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const [searchText, setSearchText] = useState(
    check_from_local_storage("subscription_search_text")
      ? get_from_localStorage("subscription_search_text")
      : ""
  );

  const [inputs, setInputs] = useState(
    check_from_local_storage("subscription_inputs")
      ? get_from_localStorage("subscription_inputs")
      : EMPTY_FILTER
  );

  const [filterState, setFilterState] = useState(EMPTY_FILTER);

  const [agreementCheckBox, setAgreementCheckBox] = useState(
    check_from_local_storage("subscription_check_box")
      ? get_from_localStorage("subscription_check_box")
      : false
  );

  const [selectedStatus, setSelectedStatus] = useState(
    check_from_local_storage("subscription_status")
      ? get_from_localStorage("subscription_status")
      : selected_status
  );

  const [selectedTransactionMode, setSelectedTransactionMode] = useState(
    check_from_local_storage("subscription_mode")
      ? get_from_localStorage("subscription_mode")
      : selected_transaction_mode
  );
  const [filterOpen, setFilterOpen] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const handleOpenFilterDrawer = () => {
    setFilterOpen(true);
  };

  const handleCloseFilterDrawer = () => {
    setFilterOpen(false);
  };

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const processInputs = (inputs) => {
    let dateFrom = undefined;
    let transactionPlatform = undefined;
    let transactionMadeBy = undefined;

    if (inputs?.agreementCheckBox) {
      let startDate = moment(inputs?.date_from, "YYYY-MM-DD").format(
        "DD-MMM-YYYY"
      );
      let endDate = moment(inputs?.date_to, "YYYY-MM-DD").format("DD-MMM-YYYY");
      dateFrom = `Date From (${startDate}) To (${endDate})`;
    }

    if (
      inputs?.transaction_platform &&
      inputs?.transaction_platform !== "all" &&
      inputs?.transaction_platform !== ""
    ) {
      if (inputs?.transaction_platform === "fire") {
        transactionPlatform = "bank";
      } else {
        transactionPlatform = inputs?.transaction_platform;
      }
    }

    if (
      inputs?.transaction_made_by &&
      inputs?.transaction_made_by !== "all" &&
      inputs?.transaction_made_by !== ""
    ) {
      transactionMadeBy = inputs?.transaction_made_by;
    }

    let initialData = {
      transaction_platform: transactionPlatform,
      transaction_made_by: transactionMadeBy,

      selectedStatus: inputs?.selectedStatus?.map((status) => ({
        _id: status?._id,
        chip_value: status?.chip_value,
        chip_label: status?.chip_label,
      })),

      selectedTransactionMode: inputs?.selectedTransactionMode?.map((mode) => ({
        _id: mode?._id,
        chip_value: mode?.chip_value,
        chip_label: mode?.chip_label,
      })),

      userObj: inputs?.userObj
        ? {
            chip_value: inputs?.userObj?.user_id?._id,
            chip_label: `${inputs?.userObj?.first_name} ${inputs?.userObj?.last_name} (${inputs?.userObj?.user_id?.email})`,
          }
        : undefined,

      invoiceObj: inputs?.invoiceObj
        ? {
            chip_value: inputs?.invoiceObj?._id,
            chip_label: `${inputs?.invoiceObj?.request_title} `,
          }
        : undefined,

      dateFrom: dateFrom,
    };

    Object.keys(initialData).forEach(
      (key) => initialData[key] === undefined && delete initialData[key]
    );

    return initialData;
  };

  const get_subscription_list = async (data, clear = "") => {
    let dataInput = data ? data : inputs;

    // let statusArray = dataInput?.selectedStatus.map((item) => item.chip_value);

    let statusArray = [];

    dataInput?.selectedStatus.forEach((item) => {
      if (item.chip_value === "expire") {
        statusArray.push("incomplete", "canceled"); // Push "incomplete" and "canceled" instead of "expire"
      } else {
        statusArray.push(item.chip_value); // Push the actual value for other cases
      }
    });

    let transactionModeArray = dataInput?.selectedTransactionMode.map(
      (item) => item.chip_value
    );

    let statusArrayClear = EMPTY_FILTER?.selectedStatus.map(
      (item) => item.chip_value
    );
    let transactionModeArrayClear = EMPTY_FILTER?.selectedTransactionMode.map(
      (item) => item.chip_value
    );

    let initialData = processInputs(dataInput);

    let search_keyword =
      localStorage.getItem("subscription_search_text") == null
        ? searchText
        : localStorage.getItem("subscription_search_text");
    if (search_keyword !== null) {
      setSearchText(search_keyword);
    }

    let postData = {
      subscription_mode:
        clear == "clear" ? transactionModeArrayClear : transactionModeArray,
      search: search_keyword,
      user_id: dataInput?.user_obj_id ? dataInput?.user_obj_id : "",
      invoice_id: dataInput?.invoice_obj_id ? dataInput?.invoice_obj_id : "",
      subscription_status: clear == "clear" ? statusArrayClear : statusArray,
      start_date: dataInput?.date_from,
      end_date: dataInput?.date_to,
    };

    if (!dataInput.agreementCheckBox) {
      delete postData?.start_date;
      delete postData?.end_date;
    }

    console.log("dataInput  __initialData _handleDeleteChip", dataInput);
    console.log("initialData  __initialData _handleDeleteChip", initialData);

    setIsLoading(true);
    const result = await _list_subscription_api(page, rowsPerPage, postData);
    if (result.code === 200) {
      setInputs({ ...dataInput, dateFrom: initialData?.dateFrom });
      setFilterState(initialData);

      set_to_localStorage(
        "subscription_check_box",
        dataInput?.agreementCheckBox
      );

      set_to_localStorage("subscription_status", dataInput?.selectedStatus);
      set_to_localStorage(
        "subscription_mode",
        dataInput?.selectedTransactionMode
      );
      set_to_localStorage("subscription_inputs", dataInput);
      set_to_localStorage("transaction_searchText", searchText);

      //
      let data = result.subscription.map((item) => {
        let full_name = "N/A";
        let user_email = "N/A";
        let created_At = "N/A";
        let next_invoice = "N/A";

        if (item.user_info) {
          full_name =
            item.user_info.first_name +
            " " +
            item.user_info.last_name +
            " (" +
            item.user_info.email +
            ")";
        }
        if (item.subscription_start_date) {
          created_At = moment(item.subscription_start_date).format(
            "DD-MM-YYYY"
          );
        }
        if (item.next_invoice_date) {
          next_invoice = moment(item.next_invoice_date).format("DD-MM-YYYY");
        }
        return {
          ...item,
          full_name,
          user_email,
          created_At,
          next_invoice,
        };
      });
      setUserList(data);
      setTotalCount(result.total_count);
      setTotalPages(result.total_pages);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleDeleteChip = (data) => {
    let dataInput = { ...data };

    console.log("dataInput  __dataInput  _handleDeleteChip", dataInput);
    if (dataInput?.dateFrom == undefined) {
      dataInput.agreementCheckBox = false;
      dataInput.date_from = moment(currentDate).format("YYYY-MM-DD");
      dataInput.date_to = moment(currentDate).format("YYYY-MM-DD");

      setAgreementCheckBox(false);
    }

    if (!dataInput.userObj || !dataInput.userObj == null) {
      dataInput.user_obj_id = "";
    }

    if (!dataInput.invoiceObj || !dataInput.invoiceObj == null) {
      dataInput.invoice_obj_id = "";
    }

    setSelectedStatus(data?.selectedStatus ? data?.selectedStatus : []);
    setSelectedTransactionMode(
      data?.selectedTransactionMode ? data?.selectedTransactionMode : []
    );

    setPage(0);
    setPageCount(1);
    setInputs(dataInput);
    get_subscription_list(dataInput);
  };

  const handleClearFilter = () => {
    localStorage.removeItem("subscription_inputs");
    localStorage.removeItem("subscription_status");
    localStorage.removeItem("subscription_mode");
    localStorage.removeItem("subscription_check_box");
    setAgreementCheckBox(false);
    setSelectedStatus(selected_status);
    setSelectedTransactionMode(selected_transaction_mode);
    setPage(0);
    setPageCount(1);
    setInputs(EMPTY_FILTER);
    handleCloseFilterDrawer();
    get_subscription_list(EMPTY_FILTER, "clear");
  };

  const handleSelectOther = (name, value) => {
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setPage(0);
    setPageCount(1);
    handleCloseFilterDrawer();
    get_subscription_list(inputs);
  };

  const searchFunction = () => {
    setPage(0);
    setPageCount(1);
    get_subscription_list();
  };

  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    { id: "full_name", label: "Name" },
    {
      id: "request_title",
      label: "Invoice",
      renderData: (row) => {
        return <span>{row?.payment_request_info?.request_title}</span>;
      },
    },
    {
      id: "subscription_created_by",
      label: "Created By",
      renderData: (row) => {
        return <span>{show_proper_words(row?.subscription_created_by)}</span>;
      },
    },
    {
      id: "subscription_mode",
      label: "Subscription Mode",
      renderData: (row) => {
        return <span>{show_proper_words(row?.subscription_mode)}</span>;
      },
    },
    { id: "created_At", label: "Subscription Date" },
    { id: "next_invoice", label: "Next Invoice Date" },
    {
      id: "subscription_mode",
      label: "Status",
      renderData: (row) => {
        let find_status = PAYMENT_STATUS.find(
          (status) => status.value == row?.subscription_status
        );
        return (
          <>
            {!!find_status && (
              <span className={`${find_status?.class}`}>
                {find_status?.name}
              </span>
            )}
          </>
        );
      },
    },
  ];

  useEffect(() => {
    get_subscription_list();
  }, [rowsPerPage, page]);

  useEffect(() => {
    localStorage.setItem("subscription_search_text", searchText);
    if (searchText.length < 1) {
      localStorage.setItem("subscription_search_text", "");
    } else {
      localStorage.setItem("subscription_search_text", searchText);
    }
  }, [searchText]);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      {/* <div className="page-title-box">
        <h4>Subscriptions</h4>
      </div> */}

      <div className="d-flex align-items-center justify-content-between flex-wrap">
        <div className="page-title-box me-2">
          <h4>Subscriptions</h4>
        </div>
        <div className="d-flex justify-content-end gap-3">
          <Button
            onClick={handleOpenFilterDrawer}
            variant="contained"
            startIcon={
              <FilterAltOutlinedIcon
                style={{
                  fill: "#fff",
                }}
              />
            }
          >
            Filter
          </Button>
        </div>
      </div>

      <div className="mt-2">
        <ReactFilterChips
          filterData={filterState}
          tempState={inputs}
          emptyFilter={EMPTY_FILTER}
          clearLabel="Clear All"
          filterLabel="Filter By"
          onDeleteChip={handleDeleteChip}
          onClear={handleClearFilter}
        />
      </div>

      <div className="mt-4">
        <CustomTable
          data={userList}
          TABLE_HEAD={TABLE_HEAD}
          custom_pagination={{
            total_count: totalCount,
            rows_per_page: rowsPerPage,
            page: page,
            handleChangePage: handleChangePage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          custom_search={{
            searchText: searchText,
            setSearchText: setSearchText,
            handleSubmit: searchFunction,
          }}
          pageCount={pageCount}
          totalPages={totalPages}
          handleChangePages={handleChangePages}
          pagePagination={true}
        />
      </div>

      <CustomDrawer
        isOpenDrawer={filterOpen}
        onOpenDrawer={handleOpenFilterDrawer}
        onCloseDrawer={handleCloseFilterDrawer}
        pageTitle="Filters"
        componentToPassDown={
          <>
            <SubscriptionFilter
              inputs={inputs}
              setInputs={setInputs}
              handleSearch={handleSearch}
              handleSelectOther={handleSelectOther}
              handleClearFilter={handleClearFilter}
              agreementCheckBox={agreementCheckBox}
              setAgreementCheckBox={setAgreementCheckBox}
              selectedStatus={selectedStatus}
              setSelectedStatus={setSelectedStatus}
              selectedTransactionMode={selectedTransactionMode}
              setSelectedTransactionMode={setSelectedTransactionMode}
            />
          </>
        }
      />
    </>
  );
};

export default Subscriptions;
