import React, { useEffect, useState } from "react";
import { Button, CircularProgress } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CustomTable from "../../components/customTable/CustomTable";
import CustomPopover from "../../components/CustomPopover";
import AddOrUpdateTaxes from "./AddOrUpdateTaxes";
import { makeStyles } from "@mui/styles";
import { _list_taxes_api } from "../../DAL/Taxes/Taxes";
import { useSnackbar } from "notistack";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const Taxes = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [userList, setUserList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClickOpen = () => {
    setOpen(true);
    setFormData(true);
  };

  const get_taxes_list = async () => {
    setIsLoading(true);
    const result = await _list_taxes_api(searchText, page, rowsPerPage);
    if (result.code === 200) {
      setUserList(result.taxes.taxes_list);
      setTotalCount(result.taxes.count);
      setTotalPages(result.taxes?.total_pages);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const searchFunction = () => {
    setPage(0);
    setPageCount(1);
    get_taxes_list();
  };

  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    { id: "tax_name", label: "Tax Name" },
    { id: "country", label: "Country" },
    { id: "region", label: "Region" },
    {
      id: "tax_rate",
      label: "Tax Rate(%)",
      renderData: (row) => {
        return <div>{row.tax_rate} %</div>;
      },
    },
  ];

  useEffect(() => {
    get_taxes_list();
  }, [rowsPerPage, page]);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="d-flex align-items-center justify-content-between flex-wrap ">
        <div className="page-title-box">
          <h4>Taxes</h4>
        </div>
        <Button
          onClick={handleClickOpen}
          variant="contained"
          startIcon={
            <AddIcon
              style={{
                fill: "#fff",
              }}
            />
          }
        >
          Add Taxes
        </Button>
        {/* <div className="position-relative search_input_table dashboard_input ms-auto mt-lg-0 mt-3">
          <input
            type="text"
            className="form-control ps-3"
            placeholder="Search..."
            id="search-options"
          />
          <i className="fa-solid fa-magnifying-glass search_icon"></i>
        </div> */}
      </div>
      <div className="mt-4">
        <CustomTable
          data={userList}
          TABLE_HEAD={TABLE_HEAD}
          custom_pagination={{
            total_count: totalCount,
            rows_per_page: rowsPerPage,
            page: page,
            handleChangePage: handleChangePage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          custom_search={{
            searchText: searchText,
            setSearchText: setSearchText,
            handleSubmit: searchFunction,
          }}
          pageCount={pageCount}
          totalPages={totalPages}
          handleChangePages={handleChangePages}
          pagePagination={true}
        />
      </div>
      <CustomPopover
        isOpenPop={open}
        isClosePop={setOpen}
        title={formData ? "Add Taxes" : "Edit Taxes"}
        componentToPassDown={
          <AddOrUpdateTaxes setOpen={setOpen} get_taxes_list={get_taxes_list} />
        }
      />
    </>
  );
};

export default Taxes;
