import React from "react";
import { useState } from "react";
import { Stack, Button, Box, CircularProgress } from "@mui/material";
import Iconify from "../../../components/Iconify";
import { _upload_image_s3_api } from "../../../DAL/Setting/Setting";
import { s3BaseUrl } from "../../../config/config";

const uuidv4 = require("uuid").v4;

// ----------------------------------------------------------------------

export default function CustomImageUploader({
  items,
  setItems,
  parentIndex,
  type,
  childIndex,
}) {
  const defaultImageUrl =
    items && items[parentIndex] && items[parentIndex]?.icon ? true : false;
  const [showImage, setShowImage] = useState(defaultImageUrl);
  const [previewUrl, setPreviewUrl] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const imageID = uuidv4();

  const uploadFileFromUser = async (selected_file) => {
    const data = new FormData();
    data.append("image", selected_file);

    setIsLoading(true);
    const response = await _upload_image_s3_api(data);

    if (response.code === 200) {
      const newItems = [...items];
      if (type === 0) {
        newItems[parentIndex].icon = response.path;
      } else {
        newItems[parentIndex].child_options[childIndex].icon = response.path;
      }
      setItems(newItems);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      // console.log(`Error: ${response.status}`);
    }
  };

  const fileChangedHandler = (event) => {
    const selectedFile = event.target.files[0];
    setPreviewUrl(URL.createObjectURL(selectedFile));
    setShowImage(true);
    uploadFileFromUser(selectedFile);
  };

  const imageDelete = async () => {
    const newItems = [...items];
    if (type === 0) {
      newItems[parentIndex].icon = "";
    } else {
      newItems[parentIndex].child_options[childIndex].icon = "";
    }
    setItems(newItems);
    setPreviewUrl("");
    setShowImage(false);
    setIsLoading(false);
  };

  return (
    <>
      {showImage === true ? (
        <Stack alignItems="flex-start">
          <Box position="relative" display="inline-block" mt={1.5}>
            <img
              alt="i"
              src={
                type === 0
                  ? items[parentIndex].icon !== ""
                    ? `${s3BaseUrl}${items[parentIndex].icon}`
                    : previewUrl
                  : type === 1
                  ? items[parentIndex].child_options[childIndex].icon !== ""
                    ? `${s3BaseUrl}${items[parentIndex].child_options[childIndex].icon}`
                    : previewUrl
                  : previewUrl
              }
              style={{ width: "40px", height: "40px" }}
            />

            <span
              onClick={() => imageDelete(previewUrl)}
              className="clearIcon-size"
            >
              X
            </span>
          </Box>
        </Stack>
      ) : (
        <Box position="relative" display="inline-block" mt={1.5}>
          <label htmlFor={imageID}>
            <input
              accept="image/*"
              id={imageID}
              type="file"
              name="image"
              onChange={fileChangedHandler}
              className="d-none"
            />

            <Button
              id={imageID}
              className="small-contained-button"
              color={"info"}
              component="span"
              disabled={isLoading}
              sx={{ backgroundColor: "#0288d10a" }}
            >
              {isLoading ? (
                <CircularProgress size={20} />
              ) : (
                <Iconify
                  icon="ic:baseline-cloud-upload"
                  width={24}
                  height={24}
                />
              )}
            </Button>
          </label>
        </Box>
      )}
    </>
  );
}
