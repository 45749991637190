import React, { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  TextareaAutosize,
} from "@mui/material";
import ActiveLastBreadcrumb from "../../components/ActiveLastBreadcrumb";
import { useSnackbar } from "notistack";
import { _add_transaction_api } from "../../DAL/Transaction/Transaction";
import MUIAutocomplete from "../../components/MUIAutocomplete";
import {
  _add_payment_request_api,
  _get_payment_request_api,
  _update_payment_request_api,
} from "../../DAL/Payment";
import {
  _user_list_api,
  _users_list_for_payment_request_api,
} from "../../DAL/User/User";
import { useNavigate, useParams } from "react-router-dom";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const AddOrUpdatePayment = () => {
  const classes = useStyles();
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [personName, setPersonName] = useState([]);
  const [member, setMember] = useState(null);
  const [userName, setUserName] = useState([]);
  const [user, setUser] = useState(null);
  const [installmentAmount, setInstallmentAmount] = useState("");
  const [paymentType, setPaymentType] = useState(["Credit Card"]);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [getLoading, setGetLoading] = useState(true);

  const [inputs, setInputs] = useState({
    request_title: "",
    status: true,
    currency: "GBP",
    request_type: "onetime",
    total_amount: "",
    initial_amount: "",
    no_of_installment: "",
    interval_type: "custom",
    no_of_days: "0",
    created_for: "client",
    request_description: "",
    is_basic_membership: true,
    basic_membership_days: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const get_user_list = async () => {
    let type = "customer";
    if (inputs.created_for == "business") {
      type = "business";
    }
    const result = await _users_list_for_payment_request_api(params.id, type);
    if (result.code === 200) {
      let data = result.users.map((item) => {
        let member_name = "N/A";
        if (item.first_name) {
          member_name =
            item.first_name +
            " " +
            item.last_name +
            " (" +
            item.user_id.email +
            ")";
        }
        return {
          ...item,
          member_name,
        };
      });
      setPersonName(data);
      setUserName(result.stripe_products);
      if (!isLoaded && params.id) {
        let payment_methods = [];
        if (Array.isArray(result.payment_request.payment_methods)) {
          if (result.payment_request.payment_methods.includes("fire")) {
            payment_methods.push("Bank");
          }
          if (result.payment_request.payment_methods.includes("stripe")) {
            payment_methods.push("Credit Card");
          }
        }
        setInputs(result.payment_request);
        setPaymentType(payment_methods);
      }
      let find_data = data.find(
        (user) =>
          user.user_id._id == result.payment_request?.client_info?.user_id
      );
      let find_user = result.stripe_products.find(
        (user) => user._id == result.payment_request?.stripe_product?._id
      );

      if (find_user) {
        setUser(find_user);
      }
      if (find_data) {
        setMember(find_data);
      } else {
        setMember(null);
      }
      setGetLoading(false);
      setIsLoaded(true);
    } else {
      setGetLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let payment_methods = [];
    if (Array.isArray(paymentType)) {
      if (paymentType.includes("Bank")) {
        payment_methods.push("fire");
      }
      if (paymentType.includes("Credit Card")) {
        payment_methods.push("stripe");
      }
    }
    let postData = {
      request_title: inputs.request_title,
      status: inputs.status,
      currency: inputs.currency,
      client_info: {
        first_name: member?.first_name,
        last_name: member?.last_name,
        email: member?.user_id?.email,
        user_id: member?.user_id?._id,
      },
      request_type: inputs.request_type,
      total_amount: inputs.total_amount,
      created_for: inputs.created_for,
      request_description: inputs.request_description,
    };
    if (inputs.request_type == "recurring") {
      postData.initial_amount = inputs.initial_amount;
      postData.no_of_installment = inputs.no_of_installment;
      postData.installment_amount = installmentAmount;
      postData.interval_type = inputs.interval_type;
      postData.stripe_product = user?._id;
    }
    if (inputs.interval_type == "custom") {
      postData.no_of_days = inputs.no_of_days;
    }
    if (inputs.request_type == "onetime") {
      postData.payment_methods = payment_methods;
    }

    postData.is_basic_membership = inputs.is_basic_membership;
    if (inputs.is_basic_membership) {
      postData.basic_membership_days = inputs.basic_membership_days;
    }

    console.log("postData  ___postData", postData);

    setIsLoading(true);
    const result = params.id
      ? await _update_payment_request_api(postData, params.id)
      : await _add_payment_request_api(postData);
    if (result.code === 200) {
      navigate(`/invoices`);
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChangeName = (event) => {
    const { value } = event.target;
    setPaymentType(typeof value === "string" ? value.split(",") : value);
  };

  const names = ["Bank", "Credit Card"];

  let breadCrumbMenu = [
    {
      title: "Invoices",
      navigation: `/invoices`,
      active: false,
    },
    {
      title: params.id ? "Edit Invoice" : "Add Invoice",
      active: true,
    },
  ];

  useEffect(() => {
    if (!params.id) {
      setGetLoading(false);
    }
    get_user_list();
    setMember(null);
  }, [inputs.created_for]);

  useEffect(() => {
    let totalValue = inputs.total_amount - inputs.initial_amount;
    let installment = (totalValue / inputs.no_of_installment).toFixed(1);
    setInstallmentAmount(installment);
  }, [inputs.total_amount, inputs.no_of_installment, inputs.initial_amount]);

  if (getLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap ">
        <div className="page-title-box">
          <h4>{params.id ? "Edit Invoice" : "Add Invoice"} </h4>
        </div>
        <div>
          <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
        </div>
      </div>
      <div className="card mt-3">
        <div className="card-body">
          <form className="row" onSubmit={handleSubmit}>
            <div className="col-12 col-md-6 mt-4">
              <TextField
                id="outlined-basic"
                label="Request Title"
                placeholder="Request Title"
                variant="outlined"
                fullWidth
                required
                name="request_title"
                value={inputs.request_title}
                onChange={handleChange}
              />
            </div>
            <div className="col-12 col-md-6 mt-4">
              <FormControl fullWidth required>
                <InputLabel id="demo-simple-select-label">
                  Created For
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="created_for"
                  value={inputs.created_for}
                  label="Created For"
                  onChange={handleChange}
                >
                  <MenuItem value="business">Business Clients</MenuItem>
                  <MenuItem value="client">Users</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="col-12 col-md-6 mt-4">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Currency *
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="currency"
                  value={inputs.currency}
                  label="currency"
                  onChange={handleChange}
                >
                  <MenuItem value="GBP">UK Pounds</MenuItem>
                  <MenuItem value="EUR">Euro</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="col-12 col-md-6 mt-4">
              <MUIAutocomplete
                inputLabel={
                  inputs.created_for == "customer" ? "Users" : "Clients"
                }
                required={true}
                selectedOption={member}
                setSelectedOption={setMember}
                optionsList={personName}
                name="member_name"
              />
            </div>
            <div className="col-12 col-md-6 mt-4">
              <FormControl fullWidth required>
                <InputLabel id="demo-simple-select-label">
                  Request Type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="request_type"
                  value={inputs.request_type}
                  label="Request Type"
                  onChange={handleChange}
                >
                  <MenuItem value="onetime">One Time</MenuItem>
                  <MenuItem value="recurring">Recurring</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="col-12 col-md-6 mt-4">
              <TextField
                id="outlined-basic"
                label="Total Amount"
                placeholder="Total Amount"
                variant="outlined"
                fullWidth
                type="number"
                required
                name="total_amount"
                value={inputs.total_amount}
                onChange={handleChange}
              />
            </div>
            {inputs.request_type === "recurring" && (
              <>
                <div className="col-12 col-md-6 mt-4">
                  <TextField
                    id="outlined-basic"
                    label="Initial Amount"
                    variant="outlined"
                    fullWidth
                    type="number"
                    required
                    name="initial_amount"
                    value={inputs.initial_amount}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-12 col-md-6 mt-4">
                  <TextField
                    id="outlined-basic"
                    label="No. Of Installments"
                    placeholder="No. Of Installments"
                    variant="outlined"
                    fullWidth
                    type="number"
                    required
                    name="no_of_installment"
                    value={inputs.no_of_installment}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-12 col-md-6 mt-4">
                  <TextField
                    id="outlined-basic"
                    label="Installment Amount"
                    placeholder="Installment Amount"
                    variant="outlined"
                    fullWidth
                    type="number"
                    required
                    aria-readonly
                    name="installment_amount"
                    value={installmentAmount}
                  />
                </div>
                <div className="col-12 col-md-6 mt-4">
                  <FormControl fullWidth required>
                    <InputLabel id="demo-simple-select-label1">
                      Plan Type
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label1"
                      id="demo-simple-select"
                      name="interval_type"
                      value={
                        inputs.interval_type ? inputs.interval_type : "week"
                      }
                      label="Plan Type"
                      onChange={handleChange}
                      required
                    >
                      <MenuItem value="weekly">Weekly</MenuItem>
                      <MenuItem value="monthly">Monthly</MenuItem>
                      <MenuItem value="yearly">Yearly</MenuItem>
                      <MenuItem value="custom">Custom</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="col-12 col-md-6 mt-4">
                  <MUIAutocomplete
                    inputLabel="Stripe Product"
                    required={true}
                    selectedOption={user}
                    setSelectedOption={setUser}
                    optionsList={userName}
                    name="name"
                  />
                </div>
                {inputs.interval_type == "custom" && (
                  <div className="col-12 col-md-6 mt-4">
                    <TextField
                      id="outlined-basic"
                      label="No of days"
                      placeholder="No of days"
                      variant="outlined"
                      fullWidth
                      required
                      type="number"
                      name="no_of_days"
                      value={inputs.no_of_days}
                      onChange={handleChange}
                    />
                  </div>
                )}
              </>
            )}
            <div className="col-12 col-md-6 mt-4">
              <FormControl fullWidth required>
                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="status"
                  value={inputs.status}
                  label="Status"
                  onChange={handleChange}
                >
                  <MenuItem value={true}>Active</MenuItem>
                  <MenuItem value={false}>Inactive</MenuItem>
                </Select>
              </FormControl>
            </div>
            {inputs.request_type == "onetime" && (
              <>
                <div className="col-12 col-md-6 mt-4">
                  <FormControl fullWidth required>
                    <InputLabel id="demo-multiple-name-label">
                      Payment Method Type
                    </InputLabel>
                    <Select
                      placeholder="Payment Method Type"
                      label="Payment Method Type"
                      id="demo-multiple-name"
                      multiple
                      value={paymentType}
                      onChange={handleChangeName}
                    >
                      {names.map((name) => (
                        <MenuItem key={name} value={name}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </>
            )}
            <>
              <div className="col-12 col-md-6 mt-4">
                <FormControl fullWidth required>
                  <InputLabel id="demo-simple-select-label">
                    Is Basic Membership Allowed
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="is_basic_membership"
                    value={inputs.is_basic_membership}
                    label="Is Basic Membership Allowed"
                    onChange={handleChange}
                  >
                    <MenuItem value={true}>Yes</MenuItem>
                    <MenuItem value={false}>No</MenuItem>
                  </Select>
                </FormControl>
              </div>
              {inputs?.is_basic_membership &&
                inputs.request_type === "onetime" && (
                  <div className="col-12 col-md-6 mt-4">
                    <TextField
                      id="outlined-basic"
                      label="Membership Days"
                      placeholder="Membership Days"
                      variant="outlined"
                      fullWidth
                      type="number"
                      required
                      name="basic_membership_days"
                      value={inputs.basic_membership_days}
                      onChange={handleChange}
                    />
                  </div>
                )}
            </>

            <div className="col-12 mt-3">
              <div className="mb-1 fw-14 ms-1">Invoice Note</div>
              <TextareaAutosize
                aria-label="empty textarea"
                className="textarea-autosize"
                placeholder="Invoice Note"
                name="request_description"
                value={inputs.request_description}
                onChange={handleChange}
                style={{
                  width: "100%",
                  height: "100px",
                  backgroundColor: "white",
                  color: "#000",
                  borderRadius: "5px",
                  outline: "none",
                  paddingTop: "8px",
                  paddingLeft: "10px",
                  border: "1px solid #00000038",
                }}
              />
            </div>
            <div className="text-end mt-4">
              <Button type="submit" variant="contained" disabled={isLoading}>
                {params.id
                  ? `${isLoading ? "Loading.." : "Update"}`
                  : `${isLoading ? "Loading.." : "Add Invoice"}`}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddOrUpdatePayment;
