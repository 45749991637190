import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  Checkbox,
  FormControlLabel,
  IconButton,
  TextField,
} from "@mui/material";
import { CircularProgress } from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useEffect } from "react";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { useParams } from "react-router-dom";
import {
  _get_nav_item_details_api,
  _update_nav_items_access_api,
} from "../../DAL/NavItems";
import ActiveLastBreadcrumb from "../../components/ActiveLastBreadcrumb";
import { s3BaseUrl } from "../../config/config";
import RecordNotFound from "../../components/RecordNotFound";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const ManageNavItemsAccess = ({ type, title, navigate, show_in_profile }) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [searchTerm, setSearchTerm] = useState("");
  const [userInfo, setUserInfo] = useState("");
  const [items, setItems] = useState([]);
  const [selected, setSelected] = useState([]);
  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const { user_id } = useParams();
  const [expandedParents, setExpandedParents] = useState({});

  const handleSelect = (value, child_option) => {
    let new_value = { item_id: value._id };
    if (child_option) {
      new_value.child_options = [{ item_id: child_option._id }];
    }

    let find = selected.find((item) => item.item_id == value._id);
    if (find) {
      if (!child_option && value.child_options) {
        setSelected((old) => old.filter((item) => item.item_id !== value._id));
      } else {
        if (find.child_options) {
          let find_child = find.child_options.find(
            (find_c) => find_c.item_id == child_option._id
          );
          let child_options = find.child_options;
          if (find_child) {
            child_options = find.child_options.filter(
              (remove) => remove.item_id !== child_option._id
            );
          } else {
            child_options = [...child_options, { item_id: child_option._id }];
          }
          find.child_options = child_options;
          if (find.child_options.length > 0) {
            setSelected((old) =>
              old.map((item) => {
                if (item.item_id == find.item_id) {
                  return find;
                }
                return item;
              })
            );
          } else {
            setSelected((old) =>
              old.filter((item) => item.item_id !== value._id)
            );
          }
        } else {
          setSelected((old) =>
            old.filter((item) => item.item_id !== value._id)
          );
        }
      }
    } else {
      if (!child_option && value.child_options) {
        let child_options = value.child_options.map((c_option) => {
          return {
            item_id: c_option._id,
          };
        });
        new_value.child_options = child_options;
      }
      setSelected([...selected, new_value]);
    }
  };

  const filteredItems = items.filter((item) => {
    const matchesParent = item.title
      .toLowerCase()
      .includes(searchTerm.toLowerCase());
    const matchesChild = item.child_options?.some((child) =>
      child.title.toLowerCase().includes(searchTerm.toLowerCase())
    );
    return matchesParent || matchesChild;
  });

  const handleSearchTermChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const toggleExpand = (parentId) => {
    setExpandedParents((prevExpanded) => ({
      ...prevExpanded,
      [parentId]: !prevExpanded[parentId],
    }));
  };

  const handleCheckAll = (value) => {
    let updated_array = [];
    if (value) {
      updated_array = items.map((item) => {
        let data_object = { item_id: item._id };
        if (item.child_options?.length > 0) {
          let child_options = item.child_options.map((child) => {
            return {
              item_id: child._id,
            };
          });
          data_object.child_options = child_options;
        }
        return data_object;
      });
    }
    setSelected(updated_array);
  };

  const getNavList = async () => {
    setIsLoading(true);
    const result = await _get_nav_item_details_api(type, user_id);
    if (result.code === 200) {
      setItems(result.default_nav_items);
      setSelected(result.nav_items);
      setUserInfo(
        `${result.user_info.first_name} ${result.user_info.last_name} (${result.user_info.user_id.email}) `
      );

      setIsLoading(false);
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let postData = {
      type: type,
      user_id: user_id,
      nav_items: selected,
    };

    setIsLoadingButton(true);
    const result = await _update_nav_items_access_api(postData);
    if (result.code === 200) {
      setIsLoadingButton(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setIsLoadingButton(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const checkAllItemsExist = (data1, data2) => {
    let is_selected_all = true;
    data2.forEach((item) => {
      let find_p = data1.find((p) => p.item_id == item._id);
      if (!find_p) {
        is_selected_all = false;
      } else {
        if (find_p.child_options?.length > 0) {
          let find_c = item.child_options.map((co) =>
            find_p.child_options.some((c) => co._id === c.item_id)
          );
          if (!find_c) {
            is_selected_all = false;
          }
        }
      }
    });
    return is_selected_all;
  };

  useEffect(() => {
    getNavList();
  }, []);

  let breadCrumbMenu = [
    {
      title: title,
      navigation: navigate,
      active: false,
    },
    show_in_profile && {
      title: "Client Profile",
      navigation: -1,
      active: false,
    },
    {
      title: userInfo,
      active: true,
    },
  ].filter(Boolean);

  useEffect(() => {
    const is_checked = checkAllItemsExist(selected, items);
    setIsCheckedAll(is_checked);
  }, [selected, items]);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      <div className="d-flex align-items-center justify-content-between flex-wrap mb-2">
        <div className="page-title-box">
          <h4>Manage NavItems Access</h4>
        </div>
        <div className="d-flex gap-2">
          <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
        </div>
      </div>

      <div className="d-flex align-items-center justify-content-between flex-wrap navItems_search mt-2 ">
        <div className="mt-3">
          <FormControlLabel
            control={
              <Checkbox
                checked={isCheckedAll}
                onClick={() => handleCheckAll(!isCheckedAll)}
              />
            }
            label="Select All"
          />
        </div>

        <div>
          <TextField
            label="Search Title"
            type="text"
            placeholder="Search Title"
            value={searchTerm}
            onChange={handleSearchTermChange}
            variant="outlined"
            margin="normal"
          />
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <Box>
          {filteredItems.length > 0 ? (
            <>
              {filteredItems.map((parent, parentIndex) => {
                let is_checked = selected.find(
                  (option) => option.item_id === parent._id
                );
                return (
                  <Card
                    key={parent._id}
                    sx={{
                      minWidth: 275,
                      mb: 1,
                      boxShadow: 2,
                      width: "100%",
                    }}
                  >
                    <div className="mt-3">
                      <div className="">
                        <div className="row">
                          <div className="col-2 col-lg-1 text-end check-box-access">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={Boolean(is_checked)}
                                  name="is_select"
                                  onChange={() => {
                                    handleSelect(parent);
                                  }}
                                />
                              }
                            />
                          </div>
                          <div className="col-8 col-lg-3">
                            <div className="custom-textfield">
                              <span className="label">Title</span>
                              <div className="title">{parent.title}</div>
                            </div>
                          </div>

                          <div className="col-8 col-lg-3 d-none d-lg-block">
                            {parent.child_options?.length > 0 ? null : (
                              <div className="custom-textfield">
                                <span className="label">Is Link</span>
                                <div className="title">
                                  {parent.is_link ? "Yes" : "No"}
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="col-8  col-lg-3 d-none d-lg-block">
                            {parent.child_options?.length > 0 ? null : (
                              <div className="custom-textfield">
                                <span className="label">Is Open New Tab</span>
                                <div className="title">
                                  {parent.is_open_new_tab ? "Yes" : "No"}
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="col-2 col-lg-2">
                            <div className="d-flex align-items-center justify-content-between flex-wrap">
                              <Box position="relative" display="inline-block">
                                {parent.icon !== "" ? (
                                  <img
                                    alt="previewUrl"
                                    src={`${s3BaseUrl}${parent.icon}`}
                                    style={{ width: "40px", height: "40px" }}
                                  />
                                ) : null}
                              </Box>
                              {parent.child_options?.length > 0 && (
                                <div>
                                  <IconButton
                                    onClick={() => toggleExpand(parent._id)}
                                    aria-label="expand"
                                  >
                                    {!expandedParents[parent._id] ? (
                                      <ExpandLessIcon />
                                    ) : (
                                      <ExpandMoreIcon />
                                    )}
                                  </IconButton>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      {!expandedParents[parent._id] && (
                        <div className="px-4">
                          {parent.child_options &&
                            parent.child_options.map((child, childIndex) => {
                              let is_checked_c = null;
                              if (is_checked?.child_options) {
                                is_checked_c = is_checked?.child_options.find(
                                  (c) => c.item_id === child._id
                                );
                              }

                              return (
                                <Card
                                  key={child.id}
                                  sx={{
                                    marginTop: 2,
                                    marginBottom: 2,
                                    width: "100%",
                                  }}
                                >
                                  <div className="mt-3">
                                    <div className="">
                                      <div className="row">
                                        <div className="col-2 col-lg-1 text-end check-box-access">
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                checked={is_checked_c}
                                                name="is_select"
                                                onChange={() => {
                                                  handleSelect(parent, child);
                                                }}
                                              />
                                            }
                                          />
                                        </div>
                                        <div className="col-8 col-lg-3">
                                          <div className="custom-textfield">
                                            <span className="label">Title</span>
                                            <div className="title">
                                              {child.title}
                                            </div>
                                          </div>
                                        </div>

                                        <div className="col-8 col-lg-3 d-none d-lg-block">
                                          <div className="custom-textfield">
                                            <span className="label">
                                              Is Link
                                            </span>
                                            <div className="title">
                                              {child.is_link ? "Yes" : "No"}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-8 col-lg-3 d-none d-lg-block">
                                          <div className="custom-textfield">
                                            <span className="label">
                                              Is Open New Tab
                                            </span>
                                            <div className="title">
                                              {child.is_open_new_tab
                                                ? "Yes"
                                                : "No"}
                                            </div>
                                          </div>
                                        </div>

                                        <div className="col-2 col-lg-2">
                                          <div className="d-flex align-items-center justify-content-between flex-wrap">
                                            <Box
                                              position="relative"
                                              display="inline-block"
                                            >
                                              {child.icon !== "" ? (
                                                <img
                                                  alt="previewUrl"
                                                  src={`${s3BaseUrl}${child.icon}`}
                                                  style={{
                                                    width: "40px",
                                                    height: "40px",
                                                  }}
                                                />
                                              ) : null}
                                            </Box>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Card>
                              );
                            })}
                        </div>
                      )}
                    </div>
                  </Card>
                );
              })}
            </>
          ) : (
            <RecordNotFound title="Nav Items" />
          )}
        </Box>

        <div className="fixed_button mt-4">
          <Button
            type="submit"
            variant="contained"
            disabled={isLoadingButton}
            sx={{
              // backgroundColor: isLoadingButton
              //   ? "rgba(0, 123, 255, 0.7)"
              //   : "primary.main",
              // color: isLoadingButton ? "white" : "inherit",
              "&.Mui-disabled": {
                backgroundColor: "#53b49e", // Lighter blue when disabled
                color: "white", // Text color remains visible
              },
            }}
          >
            {isLoadingButton ? "Submitting..." : "Submit"}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default ManageNavItemsAccess;
