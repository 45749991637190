import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { Button, CircularProgress, Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import CustomTable from "../../../components/customTable/CustomTable";
import DeleteConfirmation from "../../../components/DeleteConfirmation";
import { PAYMENT_STATUS } from "../../../utils/constant";
import {
  _delete_department_api,
  _list_department_api,
} from "../../../DAL/Departments";
import moment from "moment/moment";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const Departments = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [openDelete, setOpenDelete] = useState(false);
  const [delLoading, setDelLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [departmentList, setDepartmentList] = useState([]);
  const [deleteId, setDeleteId] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchText, setSearchText] = useState("");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClickAdd = () => {
    navigate(`/departments/add-department`);
  };

  const handleClickEdit = (row) => {
    navigate(`/departments/edit-department/${row._id}`);
  };

  const handleAgreeDelete = (row) => {
    setDeleteId(row._id);
    setOpenDelete(true);
  };

  const get_department_list = async () => {
    setIsLoading(true);
    let search_keyword =
      localStorage.getItem("department_search_text") == null
        ? searchText
        : localStorage.getItem("department_search_text");
    if (search_keyword !== null) {
      setSearchText(search_keyword);
    }
    const result = await _list_department_api(
      page,
      rowsPerPage,
      search_keyword
    );
    if (result.code === 200) {
      setDepartmentList(result.department);
      setTotalCount(result.total_count);
      setTotalPages(result.total_pages);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const searchFunction = () => {
    setPage(0);
    setPageCount(1);
    get_department_list();
  };

  const handleDelete = async () => {
    setDelLoading(true);
    const result = await _delete_department_api(deleteId);
    if (result.code === 200) {
      let new_list = departmentList.filter((id) => id._id !== deleteId);
      setDepartmentList(new_list);
      setOpenDelete(false);
      setDelLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setDelLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    { id: "name", label: "Name" },
    {
      id: "createdAt",
      label: "Created At",
      renderData: (row) => {
        let date = moment(row.createdAt).format("DD-MMMM-YYYY");
        return <>{date && <span>{date}</span>}</>;
      },
    },
    {
      id: "status",
      label: "Status",
      renderData: (row) => {
        let find_status = PAYMENT_STATUS.find(
          (status) => status.value == row.status
        );
        return (
          <>
            <span className={`${find_status?.class}`}>{find_status?.name}</span>
          </>
        );
      },
    },
    {
      id: "action",
      label: "Action",
      renderData: (row) => {
        return (
          <>
            <div className="d-flex gap-2">
              <Tooltip title="Edit Department" placement="top">
                <button
                  className="btn btn-soft-info"
                  onClick={() => {
                    handleClickEdit(row);
                  }}
                >
                  <i className="fa-solid fa-pen-to-square"></i>
                </button>
              </Tooltip>
              <Tooltip title="Delete Department" placement="top">
                <button
                  className="btn btn-soft-danger"
                  onClick={() => {
                    handleAgreeDelete(row);
                  }}
                >
                  <i className="fa-solid fa-trash"></i>
                </button>
              </Tooltip>
            </div>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    get_department_list();
  }, [rowsPerPage, page]);

  useEffect(() => {
    localStorage.setItem("department_search_text", searchText);
    if (searchText.length < 1) {
      localStorage.setItem("department_search_text", "");
    } else {
      localStorage.setItem("department_search_text", searchText);
    }
  }, [searchText]);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <DeleteConfirmation
        open={openDelete}
        isLoading={delLoading}
        setOpen={setOpenDelete}
        title={"Are you sure you want to remove this department ?"}
        handleAgree={handleDelete}
      />
      <div className="d-flex align-items-center justify-content-between flex-wrap ">
        <div className="page-title-box">
          <h4>Departments</h4>
        </div>
        <div className="d-flex gap-2">
          <Button
            onClick={handleClickAdd}
            variant="contained"
            startIcon={
              <AddIcon
                style={{
                  fill: "#fff",
                }}
              />
            }
          >
            Add Department
          </Button>
        </div>
      </div>
      <div className="mt-4">
        <CustomTable
          data={departmentList}
          TABLE_HEAD={TABLE_HEAD}
          custom_pagination={{
            total_count: totalCount,
            rows_per_page: rowsPerPage,
            page: page,
            handleChangePage: handleChangePage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          custom_search={{
            searchText: searchText,
            setSearchText: setSearchText,
            handleSubmit: searchFunction,
          }}
          pageCount={pageCount}
          totalPages={totalPages}
          handleChangePages={handleChangePages}
          pagePagination={true}
        />
      </div>
    </>
  );
};

export default Departments;
