import React, { useEffect, useState } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  CircularProgress,
  TextField,
  Button,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import ActiveLastBreadcrumb from "../../../components/ActiveLastBreadcrumb";
import GeneralCkeditor from "../../../components/GeneralCkeditor";
import {
  _add_autoresponder_message_api,
  _get_autoresponder_message_by_id_api,
  _updated_autoresponder_message_api,
} from "../../../DAL/Departments";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const AddOrUpdateMessage = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { mesg_id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isEditLoading, setIsEditLoading] = useState(true);

  const [inputs, setInputs] = useState({
    title: "",
    status: true,
    description: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const get_user_detail = async () => {
    setIsEditLoading(true);
    const result = await _get_autoresponder_message_by_id_api(mesg_id);
    if (result.code === 200) {
      setInputs((prev) => ({
        ...prev,
        ...result.autoresponder_message,
      }));
      setIsEditLoading(false);
    }
    setIsEditLoading(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const formData = new FormData();
    formData.append("name", inputs.name);
    formData.append("status", inputs.status);
    formData.append("description", inputs.description);
    const result = mesg_id
      ? await _updated_autoresponder_message_api(formData, mesg_id)
      : await _add_autoresponder_message_api(formData);
    if (result.code === 200) {
      navigate(`/auto-responded-message`);
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
    setIsLoading(false);
  };

  let breadCrumbMenu = [
    {
      title: "Auto Responded Message",
      navigation: `/auto-responded-message`,
      active: false,
    },
    {
      title: mesg_id ? "Edit Message" : "Add Message",
      active: true,
    },
  ];

  useEffect(() => {
    if (mesg_id) {
      get_user_detail();
    } else {
      setIsEditLoading(false);
    }
  }, [mesg_id]);

  if (isEditLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="d-flex justify-content-between align-items-center flex-wrap ">
          <div className="page-title-box">
            <h4>{mesg_id ? "Edit Message" : "Add Message"}</h4>
          </div>
          <div>
            <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
          </div>
        </div>
        <div className="col-12 col-md-6 mt-3">
          <TextField
            id="outlined-basic"
            label="Name"
            placeholder="Name"
            variant="outlined"
            fullWidth
            required
            name="name"
            value={inputs.name}
            onChange={handleChange}
          />
        </div>
        <div className="col-12 col-md-6 mt-3">
          <FormControl fullWidth required>
            <InputLabel id="demo-simple-select-label">Status</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={inputs.status}
              label="Status"
              name="status"
              required
              onChange={handleChange}
            >
              <MenuItem value={true}>Active</MenuItem>
              <MenuItem value={false}>Disable</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="col-12 mt-3">
          <h6 className="text-black">Message *</h6>
          <GeneralCkeditor
            setInputs={setInputs}
            inputs={inputs}
            name="description"
            editorHeight={320}
          />
        </div>
        <div className="d-flex justify-content-end gap-3 mt-3">
          <Button type="submit" variant="contained">
            {mesg_id
              ? `${isLoading ? "Loading.." : " Update"}`
              : `${isLoading ? "Loading.." : "Add Message"}`}
          </Button>
        </div>
      </div>
    </form>
  );
};

export default AddOrUpdateMessage;
