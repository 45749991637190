import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, TextField } from "@mui/material";
import { useAdminContext } from "../Hooks/AdminContext";
import { s3BaseUrl } from "../config/config";

const EmailForm = ({
  handleSubmitEmail,
  handleValidationCheck,
  errorMessage,
  handleAlertClose,
  setErrorMessage,
  handleMove,
  isLoading,
}) => {
  const { adminSettingList } = useAdminContext();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");

  const general_setting = adminSettingList?.general_settings;
  const reset_settings = adminSettingList?.reset_password_settings;

  const handleChange = (e) => {
    setEmail(e.target.value);
    setErrorMessage("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!email) {
      setErrorMessage("Please enter a email address");
      handleMove();
      return;
    }
    handleSubmitEmail(email);
  };

  return (
    <div className="account-pages">
      <div className="container">
        <div className="justify-content-center row">
          <div className="col-md-11">
            <div className="d-flex py-lg-2 py-5">
              <div className="w-100">
                <div className="d-flex flex-column h-100 py-0 py-xl-4">
                  {general_setting.admin_logo && (
                    <div className="text-center mb-5">
                      <img
                        src={s3BaseUrl + general_setting?.admin_logo}
                        alt=""
                        className="img-fluid "
                      />
                    </div>
                  )}
                  <div className="my-auto overflow-hidden card">
                    <div className="row g-0">
                      <div
                        className="col-lg-6 order-1 order-lg-0"
                        id="handle_move"
                      >
                        <div className="p-lg-5 p-4 card-body">
                          <div className="text-center">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: reset_settings?.forgot_password_text,
                              }}
                            ></div>
                          </div>
                          {reset_settings?.email_label && (
                            <div className="text-center my-3">
                              <p className="alert_warning p-3">
                                {reset_settings?.email_label}
                              </p>
                            </div>
                          )}
                          <form onSubmit={handleSubmit}>
                            {errorMessage && (
                              <div className="col-12">
                                <div
                                  className="alert alert_danger fade show d-flex justify-content-between align-items-center "
                                  role="alert"
                                >
                                  {errorMessage}
                                  <div onClick={handleAlertClose}>
                                    <i className="fa-solid fa-xmark"></i>
                                  </div>
                                </div>
                              </div>
                            )}
                            <div className="col-12 mt-4">
                              <TextField
                                id="outlined-basic"
                                label="Email"
                                variant="outlined"
                                fullWidth
                                required
                                name="email"
                                placeholder="Email"
                                value={email}
                                onChange={handleChange}
                                onBlur={(e) => {
                                  handleValidationCheck(e);
                                }}
                              />
                            </div>
                            <div className="mt-4">
                              <Button
                                className="w-100"
                                type="submit"
                                variant="contained"
                              >
                                {isLoading ? "Loading..." : "Send Reset Link"}
                              </Button>
                              {reset_settings?.remember_password_link_text &&
                                reset_settings?.remember_password_link_text && (
                                  <div className="mt-4 text-center">
                                    <p className="mb-0">
                                      {reset_settings?.remember_password_text}{" "}
                                      <a
                                        className="fw-medium text_primary text-decoration-underline pointer"
                                        onClick={() => navigate(`/login`)}
                                      >
                                        {
                                          reset_settings?.remember_password_link_text
                                        }
                                      </a>
                                    </p>
                                  </div>
                                )}
                            </div>
                          </form>
                        </div>
                      </div>
                      <div
                        className="col-lg-6 overlay-container"
                        style={{
                          backgroundImage: `url(${
                            s3BaseUrl + reset_settings?.slider_img_1
                          })`,
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center center",
                        }}
                      >
                        <div className="div-img-overlay"></div>
                        {reset_settings?.slider_text_1 &&
                          reset_settings?.slider_text_2 &&
                          reset_settings?.slider_text_3 && (
                            <div
                              id="carouselExampleCaptions"
                              className="carousel slide h-100"
                              data-bs-ride="carousel"
                            >
                              <div className="carousel-indicators">
                                <button
                                  type="button"
                                  data-bs-target="#carouselExampleCaptions"
                                  data-bs-slide-to={0}
                                  className="active"
                                  aria-current="true"
                                  aria-label="Slide 1"
                                />
                                <button
                                  type="button"
                                  data-bs-target="#carouselExampleCaptions"
                                  data-bs-slide-to={1}
                                  aria-label="Slide 2"
                                />
                                <button
                                  type="button"
                                  data-bs-target="#carouselExampleCaptions"
                                  data-bs-slide-to={2}
                                  aria-label="Slide 3"
                                />
                              </div>
                              <div className="carousel-inner ">
                                <div className="carousel-item active ">
                                  <div className="text-center p-5">
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: reset_settings?.slider_text_1,
                                      }}
                                    ></div>
                                  </div>
                                </div>
                                <div className="carousel-item">
                                  <div className="text-center p-5">
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: reset_settings?.slider_text_2,
                                      }}
                                    ></div>
                                  </div>
                                </div>
                                <div className="carousel-item">
                                  <div className="text-center p-5 ">
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: reset_settings?.slider_text_3,
                                      }}
                                    ></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 text-center">
                    <p className="mb-0 text-muted">
                      {general_setting?.copyright_text}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailForm;
