import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { styled } from "@mui/material/styles";
import { useSnackbar } from "notistack";
import { Button, TextareaAutosize } from "@mui/material";
import { CustomPhoneInput } from "../../components/CustomPhoneInput";
import { _edit_profile_api } from "../../DAL/Login/Login";
import { s3BaseUrl } from "../../config/config";
import { useAdminContext } from "../../Hooks/AdminContext";
import { UserIcon } from "../../assets";
import { isValidNumber } from "libphonenumber-js";
import { validateEmail } from "../../utils/constant";

const Input = styled("input")({
  display: "none",
});

function EditProfile() {
  const { userInfo, setUserInfo } = useAdminContext();

  const { enqueueSnackbar } = useSnackbar();
  const [previews, setPreviews] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [file, setProfileImage] = useState(null);

  const [phoneNumber, setPhoneNumber] = useState("+44");

  const [inputs, setInputs] = useState({
    first_name: "",
    last_name: "",
    address: "",
    contact_number: "",
    old_image: "",
    email: "",
    status: true,
    profile_image: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((prev) => ({ ...prev, [name]: value }));
  };

  const handleUpdate = async (e) => {
    e.preventDefault();

    const isValid = isValidNumber(`+${inputs?.contact_number}`);
    if (!isValid) {
      return enqueueSnackbar("Please enter valid phone number", {
        variant: "error",
      });
    }

    if (!validateEmail(inputs?.email)) {
      return enqueueSnackbar("Please enter a valid email address", {
        variant: "error",
      });
    }

    setIsLoading(true);
    const formData = new FormData();
    formData.append("first_name", inputs.first_name);
    formData.append("last_name", inputs.last_name);
    formData.append("address", inputs.address);
    formData.append("email", inputs.email);
    formData.append("contact_number", inputs.contact_number);
    formData.append("status", inputs.status);
    if (file) {
      formData.append("image", inputs.profile_image);
    }
    const result = await _edit_profile_api(formData);
    if (result.code === 200) {
      localStorage.setItem("user", JSON.stringify(result.adminUser));
      setUserInfo(result.adminUser);
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleUpload = (event) => {
    setProfileImage(event.target.files[0]);
    setInputs((prev) => ({
      ...prev,
      ["profile_image"]: event.target.files[0],
    }));

    const preview = URL.createObjectURL(event.target.files[0]);
    setPreviews(preview);
  };

  useEffect(() => {
    setInputs((prev) => ({
      ...prev,
      ["first_name"]: userInfo?.first_name,
      ["last_name"]: userInfo?.last_name,
      ["address"]: userInfo?.address,
      ["contact_number"]: userInfo?.contact_number,
      ["old_image"]: userInfo?.profile_image,
      ["email"]: userInfo?.user_id?.email,
    }));
    setPhoneNumber(userInfo?.contact_number ? userInfo?.contact_number : "+44");
  }, [userInfo]);

  return (
    <div className="container">
      <div className="d-flex justify-content-between align-items-center flex-wrap mb-3">
        <div className="page-title-box">
          <h4>Edit Profile</h4>
        </div>
      </div>

      <form onSubmit={handleUpdate}>
        <div className="mt-3 mb-4 d-flex justify-content-center">
          <div className="cards-edit p-4">
            <div className="d-flex flex-column justify-content-center align-items-center">
              <div className="edit-profile-icon">
                <img
                  src={
                    previews
                      ? previews
                      : inputs.old_image
                      ? s3BaseUrl + inputs.old_image
                      : UserIcon
                  }
                  height="100"
                  width="100"
                />
                <label htmlFor="icon-button-file">
                  <Input
                    accept="image/*"
                    id="icon-button-file"
                    type="file"
                    name="profile_image"
                    onChange={(e) => handleUpload(e)}
                  />
                  <IconButton
                    className="edit-profile-icon-position"
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                  >
                    <PhotoCamera />
                  </IconButton>
                </label>
              </div>
              <div className="row w-100 mt-3">
                <div className="col-12 col-md-6 mt-3">
                  <TextField
                    id="outlined-basic"
                    label="First Name"
                    variant="outlined"
                    fullWidth
                    required
                    placeholder="First Name"
                    name="first_name"
                    value={inputs.first_name}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-12 col-md-6 mt-3">
                  <TextField
                    id="outlined-basic"
                    label="Last Name"
                    variant="outlined"
                    fullWidth
                    required
                    placeholder="Last Name"
                    name="last_name"
                    value={inputs.last_name}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-12 col-md-6 mt-3">
                  <TextField
                    id="outlined-basic"
                    label="Email"
                    variant="outlined"
                    fullWidth
                    required
                    placeholder="Email"
                    name="email"
                    value={inputs.email}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-12 col-md-6 mt-3">
                  <CustomPhoneInput
                    required={true}
                    inputState={inputs}
                    setInputsState={setInputs}
                    phoneNumber={phoneNumber}
                    setPhoneNumber={setPhoneNumber}
                  />
                </div>
                <div className="col-12 mt-3">
                  <div className="mb-1 fw-14 ms-1">Your Address </div>
                  <TextareaAutosize
                    aria-label="empty textarea"
                    className="textarea-autosize"
                    placeholder="Address"
                    name="address"
                    value={inputs.address}
                    onChange={handleChange}
                    style={{
                      width: "100%",
                      height: "100px",
                      backgroundColor: "white",
                      color: "#000",
                      borderRadius: "5px",
                      outline: "none",
                      paddingTop: "8px",
                      paddingLeft: "10px",
                      border: "1px solid #00000038",
                    }}
                  />
                </div>
              </div>
              <div className="mt-2 ms-auto me-2">
                <Button className="mt-4" type="submit" variant="contained">
                  {isLoading ? "Loading..." : "Submit"}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default EditProfile;
