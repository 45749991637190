import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import MUIAutocomplete from "../../../components/MUIAutocomplete";
import {
  _change_department_api,
  _get_list_department_api,
} from "../../../DAL/SupportTicket";

export default function ChangeDepartment({
  ticketId,
  onClose,
  setTicketList,
  Departmentdata,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [member, setMember] = useState();
  const [member2, setMember2] = useState();
  const [departmentList, setDepartmentList] = useState([]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const postData = {
      department_id: member?._id,
      support_ticket_id: ticketId,
    };
    const result = await _change_department_api(postData);
    if (result.code === 200) {
      setTicketList((prev) => {
        return prev.map((item) => {
          if (item._id === ticketId) {
            return result.support_ticket;
          } else {
            return item;
          }
        });
      });
      onClose();
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const get_department_list = async () => {
    const result = await _get_list_department_api();
    if (result.code === 200) {
      setDepartmentList(result.department);
    }
  };

  useEffect(() => {
    get_department_list();
  }, []);

  useEffect(() => {
    console.log("member   __handleChangeDepartment", member);
  }, [member]);

  useEffect(() => {
    const target_id = Departmentdata?.department?._id; // Example target ID
    const matchedObj = departmentList.find((item) => item._id === target_id);
    setMember(matchedObj);
    setMember2(matchedObj);
    console.log("matchedObj   __handleChangeDepartment", matchedObj);
  }, [departmentList, Departmentdata]);

  return (
    <div className="container">
      <form onSubmit={handleSubmit}>
        <div className="col-12 mt-4">
          {member2 && (
            <MUIAutocomplete
              inputLabel="Departments"
              selectedOption={member}
              setSelectedOption={setMember}
              optionsList={departmentList}
              name="name"
              required
            />
          )}

          {!member2 && (
            <MUIAutocomplete
              inputLabel="Departments"
              selectedOption={member}
              setSelectedOption={setMember}
              optionsList={departmentList}
              name="name"
              required
              disabled
            />
          )}
        </div>
        <div className="d-flex justify-content-end gap-3 mt-4">
          <Button onClick={onClose} variant="outlined">
            Close
          </Button>
          <Button variant="contained" type="submit">
            {isLoading ? "Loading..." : "Submit"}
          </Button>
        </div>
      </form>
    </div>
  );
}
