import React, { useState } from "react";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useNavigate } from "react-router-dom";
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from "@mui/material";

const Signup = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [input, setInput] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
  });

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setInput((prevValues) => ({ ...prevValues, [name]: value }));
  };

  return (
    <>
      <div className="account-pages">
        <div className="container">
          <div className="justify-content-center row">
            <div className="col-md-11">
              <div className="d-flex py-lg-2 py-5">
                <div className="w-100">
                  <div className="d-flex flex-column h-100 py-0 py-xl-4">
                    <div className="text-center mb-5">
                      <a href="#">
                        <img
                          src="https://dynamite-lifestyle-dev-app-bucket.s3.amazonaws.com/default_setting/04a0b890-9ad0-4a10-bfea-cdf3660dbce1.png"
                          className="img-fluid "
                        />
                      </a>
                    </div>
                    <div className="my-auto overflow-hidden card">
                      <div className="row g-0">
                        <div className="col-lg-6">
                          <div className="p-lg-5 p-4 card-body">
                            <div className="text-center">
                              <h5>Create New Account</h5>
                              <p className="text-muted mt-2">
                                Get Your Free Dynamite Payment Account Now
                              </p>
                            </div>
                            <>
                              <div className="col-12 mt-4">
                                <TextField
                                  id="outlined-basic"
                                  label="First Name"
                                  variant="outlined"
                                  fullWidth
                                  placeholder="First Name"
                                />
                              </div>
                              <div className="col-12 mt-4">
                                <TextField
                                  id="outlined-basic"
                                  label="Last Name"
                                  variant="outlined"
                                  fullWidth
                                  placeholder="Last Name"
                                />
                              </div>
                              <div className="col-12 mt-4">
                                <TextField
                                  id="outlined-basic"
                                  label="Email"
                                  variant="outlined"
                                  fullWidth
                                  placeholder="Email"
                                />
                              </div>
                              <div className="col-12 mt-4">
                                <FormControl fullWidth variant="outlined">
                                  <InputLabel htmlFor="outlined-adornment-password">
                                    Password
                                  </InputLabel>
                                  <OutlinedInput
                                    id="outlined-adornment-password"
                                    type={showPassword ? "text" : "password"}
                                    placeholder="Password"
                                    fullWidth
                                    endAdornment={
                                      <InputAdornment position="end">
                                        <IconButton
                                          aria-label="toggle password visibility"
                                          onClick={handleClickShowPassword}
                                          onMouseDown={handleMouseDownPassword}
                                          edge="end"
                                        >
                                          {showPassword ? (
                                            <VisibilityOff
                                              style={{
                                                fontSize: "18px",
                                              }}
                                            />
                                          ) : (
                                            <Visibility
                                              style={{
                                                fontSize: "18px",
                                              }}
                                            />
                                          )}
                                        </IconButton>
                                      </InputAdornment>
                                    }
                                    label="Password"
                                  />
                                </FormControl>
                              </div>
                            </>
                            <div className="mt-2">
                              <div className="fs-16 pb-2">
                                <p className="mb-0 fs-14 text-muted fst-italic">
                                  By registering you agree{" "}
                                  <a
                                    className="text_primary text-decoration-underline fst-normal fw-medium"
                                    href="#"
                                  >
                                    Terms of Use
                                  </a>
                                </p>
                              </div>
                            </div>
                            <div className="mt-2">
                              <button className="btn btn-primary w-100">
                                Sign In
                              </button>
                            </div>
                            <div className="mt-4 text-center">
                              <p className="mb-0">
                                Don't have an account ?{" "}
                                <a
                                  className="fw-medium text_primary text-decoration-underline pointer"
                                  onClick={() => navigate(`/Login`)}
                                >
                                  Sign Up
                                </a>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-lg-6 overlay-container"
                          style={{
                            backgroundImage: `url(${"https://themes.themesbrand.com/invoika/react/v-light/static/media/bg-auth.175c59ca28e0c3e775c5.jpg"})`,
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center center",
                          }}
                        >
                          <div className="div-img-overlay"></div>
                          <div
                            id="carouselExampleCaptions"
                            className="carousel slide h-100"
                            data-bs-ride="carousel"
                          >
                            <div className="carousel-indicators">
                              <button
                                type="button"
                                data-bs-target="#carouselExampleCaptions"
                                data-bs-slide-to={0}
                                className="active"
                                aria-current="true"
                                aria-label="Slide 1"
                              />
                              <button
                                type="button"
                                data-bs-target="#carouselExampleCaptions"
                                data-bs-slide-to={1}
                                aria-label="Slide 2"
                              />
                              <button
                                type="button"
                                data-bs-target="#carouselExampleCaptions"
                                data-bs-slide-to={2}
                                aria-label="Slide 3"
                              />
                            </div>
                            <div className="carousel-inner ">
                              <div className="carousel-item active ">
                                <div className="text-center p-5">
                                  <h5 className="text-white ">
                                    “I feel confident imposing on myself”
                                  </h5>
                                  <p className="text-white">
                                    Vestibulum auctor orci in risus iaculis
                                    consequat suscipit felis rutrum aliquet
                                    iaculis augue sed tempus In elementum
                                    ullamcorper lectus vitae pretium Nullam
                                    ultricies diam eu ultrices sagittis.
                                  </p>
                                </div>
                              </div>
                              <div className="carousel-item">
                                <div className="text-center p-5">
                                  <h5 className="text-white ">
                                    “Our task must be to free widening circle”
                                  </h5>
                                  <p className="text-white">
                                    Curabitur eget nulla eget augue dignissim
                                    condintum Nunc imperdiet ligula porttitor
                                    commodo elementum Vivamus justo risus
                                    fringilla suscipit faucibus orci luctus
                                    ultrices posuere cubilia curae ultricies
                                    cursus.
                                  </p>
                                </div>
                              </div>
                              <div className="carousel-item">
                                <div className="text-center p-5 ">
                                  <h5 className="text-white ">
                                    “I've learned that people forget what you”
                                  </h5>
                                  <p className="text-white">
                                    Pellentesque lacinia scelerisque arcu in
                                    aliquam augue molestie rutrum Fusce
                                    dignissim dolor id auctor accumsan vehicula
                                    dolor vivamus feugiat odio erat sed quis
                                    Donec nec scelerisque magna
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-5 text-center">
                      <p className="mb-0 text-muted">
                        Copyright © 2024 Dynamite Payment. All Rights Reserved
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Signup;
