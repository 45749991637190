import { invokeApi } from "./invokeApi";

export const _list_subscription_api_old = async (search_text, page, limit) => {
  const requestObj = {
    path: `api/payments/list_subscription_for_admin?text=${search_text}&page=${page}&limit=${limit}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _list_subscription_api = async (page, limit, data) => {
  const requestObj = {
    path: `api/payments/list_subscription_for_admin?page=${page}&limit=${limit}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
