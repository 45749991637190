import React, { useEffect } from "react";
import { Button, CircularProgress, TextField } from "@mui/material";
import TagsInput from "react-tagsinput";
import { useState } from "react";
import GeneralCkeditor from "../../components/GeneralCkeditor";
import {
  _get_notification_info_api,
  _update_notification_info_api,
} from "../../DAL/Notification";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function EmailSetting({ onClose }) {
  const classes = useStyles();
  const { id } = useParams();
  const [selected, setSelected] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsloading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [inputs, setInputs] = useState({
    email_message: "",
    email_subject: "",
    title: "",
    main_email: "",
    cc_emails: [],
  });

  const get_notification_info = async () => {
    setIsloading(true);
    const result = await _get_notification_info_api(id);
    if (result.code === 200) {
      setInputs(result?.notification_info);
      setSelected(result.notification_info.cc_emails ?? []);
      setIsloading(false);
    } else {
      setIsloading(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let notification_info = {
      email_message: inputs.email_message,
      email_subject: inputs.email_subject,
      title: inputs.title,
      main_email: inputs.main_email,
      cc_emails: selected,
    };
    let postData = {
      notification_info: notification_info,
      _id: id,
    };
    setLoading(true);
    const result = await _update_notification_info_api(postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setLoading(false);
      onClose();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setLoading(false);
    }
  };

  useEffect(() => {
    get_notification_info();
  }, []);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div class="text-end mb-4">
            <Button variant="contained" type="submit" disabled={loading}>
              {loading ? "Loading..." : "Save"}
            </Button>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 mt-3">
            <TextField
              id="outlined-basic"
              label="Email Subject"
              placeholder="Email Subject"
              variant="outlined"
              fullWidth
              required
              name="email_subject"
              value={inputs.email_subject}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 mt-3">
            <TextField
              id="outlined-basic"
              label="Email Body Heading"
              placeholder="Email Body Heading"
              variant="outlined"
              fullWidth
              required
              name="title"
              value={inputs.title}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 mt-3">
            <TextField
              id="outlined-basic"
              label="Main Email"
              placeholder="Main Email"
              variant="outlined"
              type="email"
              fullWidth
              name="main_email"
              value={inputs.main_email}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 mt-3">
            <TagsInput
              value={selected}
              onChange={setSelected}
              inputProps={{
                placeholder: "CC Emails",
              }}
              addOnBlur={true}
              addKeys={[9, 13]}
              name="cc_emails"
              type="email"
              label="cc_emails"
            />
          </div>
          <div className="col-12 mt-3">
            <span>Email Body Message *</span>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="email_message"
              editorHeight={320}
            />
          </div>
        </div>
      </form>
    </div>
  );
}
