import { useRef, useState } from "react";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import MenuPopover from "./MenuPopover";
import CustomPopoverSectionItems from "./CustomPopoverSectionItems";

export default function CustomPopoverSection(props) {
  const anchorRef = useRef(null);
  const { menu, data } = props;

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="menu-background">
      <MoreHorizIcon ref={anchorRef} onClick={handleOpen} />
      <MenuPopover
        className="custom-popover"
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ marginLeft: 1.8, maxHeight: 300 }}
      >
        {menu.map((option, index) => (
          <CustomPopoverSectionItems
            key={index}
            item={option}
            data={data}
            setOpen={setOpen}
          />
        ))}
      </MenuPopover>
    </div>
  );
}
