import React, { useState } from "react";
import MUIAutocomplete from "../../components/MUIAutocomplete";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import ClearAllOutlinedIcon from "@mui/icons-material/ClearAllOutlined";
import { Button } from "@mui/material";

const UserFilter = ({ onClose }) => {
  const [personName, setPersonName] = useState([]);
  const [member, setMember] = useState();
  return (
    <div className="container">
      <div className="row mt-4">
        <div className="col-12">
          <MUIAutocomplete
            inputLabel="Business Clients"
            selectedOption={member}
            setSelectedOption={setMember}
            optionsList={personName}
            name="member_name"
          />
        </div>
        <div className="d-flex justify-content-end gap-3 mt-4">
          <Button
            onClick={onClose}
            variant="outlined"
            startIcon={<ClearAllOutlinedIcon />}
          >
            Clear All
          </Button>
          <Button
            onClick={onClose}
            variant="contained"
            startIcon={
              <FilterAltOutlinedIcon
                style={{
                  fill: "#fff",
                }}
              />
            }
          >
            Filter
          </Button>
        </div>
      </div>
    </div>
  );
};

export default UserFilter;
