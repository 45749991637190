import React, { useEffect, useState } from "react";
import CustomTable from "../../../components/customTable/CustomTable";
import {
  TRANSACTION_STATUS,
  convertCurrencyToSign,
  show_proper_words,
} from "../../../utils/constant";
import moment from "moment";

const Transaction = ({ tabData }) => {
  const [dataList, setDataList] = useState([]);

  const get_list = async () => {
    let data = tabData?.map((item) => {
      let created_At = "N/A";
      if (item.createdAt) {
        created_At = moment(item.createdAt).format("DD-MM-YYYY");
      }
      return {
        ...item,
        created_At,
      };
    });
    setDataList(data);
  };

  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    {
      id: "total_amount",
      label: "Amount",
      renderData: (row) => {
        let trans_info = row.payment_request_info;
        return (
          <span>{`${
            convertCurrencyToSign(trans_info?.currency) +
            trans_info?.total_amount
          }`}</span>
        );
      },
    },
    {
      id: "payment_made_in",
      label: "Payment Made In",
      renderData: (row) => {
        let fire_pay = row?.fire_payment_info;
        let trans_info = row?.payment_request_info;
        return (
          <span>
            {fire_pay
              ? convertCurrencyToSign(fire_pay?.currency) + fire_pay.amount
              : convertCurrencyToSign(trans_info?.currency) +
                trans_info?.total_amount}
          </span>
        );
      },
    },
    {
      id: "transaction_type",
      label: "Transaction Type",
      renderData: (row) => {
        let trans_info = row.payment_request_info;
        return <span>{show_proper_words(trans_info?.request_type)}</span>;
      },
    },
    {
      id: "payment_made_by",
      label: "Transaction Made By",
      renderData: (row) => {
        return <span>{show_proper_words(row.payment_made_by)}</span>;
      },
    },
    {
      id: "payment_mode",
      label: "Transaction Mode",
      renderData: (row) => {
        return <span>{show_proper_words(row.payment_mode)}</span>;
      },
    },
    {
      id: "payment_made_by_platform",
      label: "Transaction Platform",
      renderData: (row) => {
        return <span>{show_proper_words(row.payment_made_by_platform)}</span>;
      },
    },
    { id: "created_At", label: "Transaction Date" },
    {
      id: "status",
      label: "Status",
      renderData: (row) => {
        let find_status = TRANSACTION_STATUS.find(
          (status) => status.value == row.status
        );
        return (
          <span className={`${find_status?.class}`}>{find_status?.name}</span>
        );
      },
    },
  ];

  useEffect(() => {
    get_list();
  }, [tabData]);

  return <CustomTable data={dataList} TABLE_HEAD={TABLE_HEAD} is_hide={true} />;
};

export default Transaction;
