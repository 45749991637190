import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import React from "react";

function CustomConfirmation({
  handleAgree,
  title,
  open,
  setOpen,
  title_html,
  loading,
}) {
  const handleCloseDialog = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleCloseDialog}
        PaperProps={{
          style: {
            backgroundColor: "white",
            color: "black",
            boxShadow: "none",
          },
        }}
      >
        {title_html ? (
          title_html
        ) : (
          <DialogTitle>
            {title ? title : "Are you sure you want to take this action?"}
          </DialogTitle>
        )}
        <DialogActions>
          <Button variant="outlined" onClick={handleCloseDialog}>
            Cancel
          </Button>
          <Button variant="contained" autoFocus onClick={handleAgree}>
            {loading ? "Loading..." : "Agree"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default CustomConfirmation;
