import React, { useEffect, useState } from "react";
import CustomTable from "../../../components/customTable/CustomTable";
import { PAYMENT_STATUS, show_proper_words } from "../../../utils/constant";
import moment from "moment";

const Subscription = ({ tabData }) => {
  const [dataList, setDataList] = useState([]);

  const get_list = async () => {
    let data = tabData?.map((item) => {
      let created_At = "N/A";
      let next_invoice = "N/A";
      if (item.subscription_start_date) {
        created_At = moment(item.subscription_start_date).format("DD-MM-YYYY");
      }
      if (item.next_invoice_date) {
        next_invoice = moment(item.next_invoice_date).format("DD-MM-YYYY");
      }
      return {
        ...item,
        created_At,
        next_invoice,
      };
    });
    setDataList(data);
  };
  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    {
      id: "request_title",
      label: "Invoice",
      renderData: (row) => {
        return <span>{row?.payment_request_info?.request_title}</span>;
      },
    },
    {
      id: "subscription_created_by",
      label: "Created By",
      renderData: (row) => {
        return <span>{show_proper_words(row?.subscription_created_by)}</span>;
      },
    },
    {
      id: "subscription_mode",
      label: "Subscription Mode",
      renderData: (row) => {
        return <span>{show_proper_words(row?.subscription_mode)}</span>;
      },
    },
    { id: "created_At", label: "Subscription Date" },
    { id: "next_invoice", label: "Next Invoice Date" },
    {
      id: "subscription_mode",
      label: "Status",
      renderData: (row) => {
        let find_status = PAYMENT_STATUS.find(
          (status) => status.value == row?.subscription_status
        );
        return (
          <>
            {!!find_status && (
              <span className={`${find_status?.class}`}>
                {find_status?.name}
              </span>
            )}
          </>
        );
      },
    },
  ];

  useEffect(() => {
    get_list();
  }, [tabData]);

  return <CustomTable data={dataList} TABLE_HEAD={TABLE_HEAD} is_hide={true} />;
};

export default Subscription;
