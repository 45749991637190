import React, { useEffect, useState } from "react";
import {
  PAYMENT_STATUS,
  convertCurrencyToSign,
  show_proper_words,
} from "../../../utils/constant";
import moment from "moment";
import Label from "../../../components/Label";
import CopyToClipboard from "react-copy-to-clipboard";
import { CircularProgress, Tooltip } from "@mui/material";
import { Business_Portal_URL, s3BaseUrl } from "../../../config/config";
import { useSnackbar } from "notistack";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CustomPopover from "../../../components/CustomPopover";
import Payments from "./Payments";
import CustomTabs from "../../../components/CustomTabs";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "10%",
    marginBottom: "10%",
  },
}));

const Invoice = ({ tabData, isTabLoading, setTabInvoice, tabInvoice }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [invoiceId, setInvoiceId] = useState("");
  const [dataList, setDataList] = useState([]);

  const handleChange = (event, newValue) => {
    setTabInvoice(newValue);
  };

  const get_list = async () => {
    let data = tabData?.map((request) => {
      let client_name = "N/A";
      let date = "N/A";
      if (request.client_info) {
        client_name =
          request.client_info.first_name +
          " " +
          request.client_info.last_name +
          " (" +
          request.client_info.email +
          ")";
      }

      if (request.createdAt) {
        date = moment(request.createdAt).format("DD-MM-YYYY");
      }
      return {
        ...request,
        client_name,
        date,
      };
    });
    setDataList(data);
  };

  const handleCopiedUrl = () => {
    enqueueSnackbar("Invoice URL Copied to clipboard", { variant: "success" });
  };

  const handleCratePayment = (value) => {
    setOpen(true);
    setInvoiceId(s3BaseUrl + value.invoice);
  };

  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    { id: "request_title", label: "Request Title" },
    {
      id: "request_type",
      label: "Payment Type",
      renderData: (row) => {
        return <span>{show_proper_words(row.request_type)}</span>;
      },
    },
    {
      id: "created_for",
      label: "Created For",
      renderData: (row) => {
        let createdFor = "Business Clients";
        if (row.created_for == "client") {
          createdFor = "Users";
        }
        return <span>{createdFor}</span>;
      },
    },
    {
      id: "total_amount",
      label: "Total Amount",
      renderData: (row) => {
        return (
          <span>
            {convertCurrencyToSign(row.currency) + (row.total_amount ?? 0)}
          </span>
        );
      },
    },
    {
      id: "initial_amount",
      label: "Initial Amount",
      renderData: (row) => {
        return (
          <span>
            {convertCurrencyToSign(row.currency) + (row.initial_amount ?? 0)}
          </span>
        );
      },
    },
    {
      id: "installment_amount",
      label: "Installment Amount",
      renderData: (row) => {
        return (
          <span>
            {convertCurrencyToSign(row.currency) +
              (row.installment_amount ?? 0)}
          </span>
        );
      },
    },
    {
      id: "no_of_installment",
      label: "No of Installment",
      renderData: (row) => {
        return <span>{row.no_of_installment ?? 0}</span>;
      },
    },
    {
      id: "invoice",
      label: "Invoice PDF",
      renderData: (row) => {
        return (
          <>
            {row.invoice ? (
              <a
                className="pointer"
                onClick={() => {
                  handleCratePayment(row);
                }}
              >
                Preview
              </a>
            ) : (
              <span>N/A</span>
            )}
          </>
        );
      },
    },
    {
      id: "invoice",
      label: "Invoice URL",
      renderData: (row) => {
        return (
          <>
            {row.invoice ? (
              <CopyToClipboard
                className="pointer me-2"
                text={`${Business_Portal_URL}create-payment/${row._id}`}
                onCopy={() => handleCopiedUrl()}
              >
                <Tooltip title="Copy Invoice URL">
                  <ContentCopyIcon style={{ fontSize: "20px" }} />
                </Tooltip>
              </CopyToClipboard>
            ) : (
              <span>N/A</span>
            )}
          </>
        );
      },
    },
    { id: "date", label: "Created At" },
    {
      id: "is_first_paid",
      label: "First Paid",
      renderData: (row) => {
        let textRender = "Paid";
        let colorRender = "success";

        if (row?.payment_status === "canceled") {
          let canceledDate = moment(row?.canceled_date).format("DD-MM-YYYY");
          textRender = `Canceled on ${canceledDate}`;
          colorRender = "error";
        } else {
          if (!row.is_first_paid) {
            textRender = "Pending";
            colorRender = "error";
          }

          if (row?.payment_status === "paid") {
            let paidDate = moment(row?.paid_date).format("DD-MM-YYYY");
            textRender = `Paid on ${paidDate}`;
            colorRender = "success";
          }
        }

        return (
          <Label variant="ghost" color={colorRender}>
            {textRender}
          </Label>
        );
      },
    },
    {
      id: "status",
      label: "Status",
      renderData: (row) => {
        let find_status = PAYMENT_STATUS.find(
          (status) => status?.value == row?.status
        );
        return (
          <>
            {!!find_status && (
              <span className={`${find_status?.class}`}>
                {find_status?.name}
              </span>
            )}
          </>
        );
      },
    },
  ];

  const PaymentsComponent = (
    <div style={{ minHeight: "300px" }}>
      <Payments
        paymentList={dataList}
        TABLE_HEAD={TABLE_HEAD}
        isTabLoading={isTabLoading}
      />
    </div>
  );

  const TABS_OPTIONS = [
    {
      title: "All",
      component: isTabLoading ? (
        <div style={{ minHeight: "300px" }}>
          <CircularProgress className={classes.loading} color="primary" />
        </div>
      ) : (
        PaymentsComponent
      ),
    },
    {
      title: "Paid",
      component: isTabLoading ? (
        <div style={{ minHeight: "300px" }}>
          <CircularProgress className={classes.loading} color="primary" />
        </div>
      ) : (
        PaymentsComponent
      ),
    },
    {
      title: "Pending",
      component: isTabLoading ? (
        <div style={{ minHeight: "300px" }}>
          <CircularProgress className={classes.loading} color="primary" />
        </div>
      ) : (
        PaymentsComponent
      ),
    },

    {
      title: "Canceled",
      component: isTabLoading ? (
        <div style={{ minHeight: "300px" }}>
          <CircularProgress className={classes.loading} color="primary" />
        </div>
      ) : (
        PaymentsComponent
      ),
    },
  ];

  useEffect(() => {
    get_list();
  }, [tabData]);

  return (
    <>
      <div className="card">
        <CustomTabs
          data={TABS_OPTIONS}
          value={tabInvoice}
          handleChange={handleChange}
        />
        <CustomPopover
          isOpenPop={open}
          isClosePop={setOpen}
          title="Invoice PDF"
          width="600px"
          componentToPassDown={
            <>
              <iframe src={invoiceId} width="100%" height="500px" />
            </>
          }
        />
      </div>
    </>
  );
};

export default Invoice;
