import React, { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  FormHelperText,
  MenuItem,
  TextField,
} from "@mui/material";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { styled } from "@mui/material/styles";
import { s3BaseUrl } from "../../config/config";
import {
  _get_email_settings,
  _update_email_settings,
  _upload_image_s3_api,
} from "../../DAL/Setting/Setting";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { LoadingButton } from "@mui/lab";

const Input = styled("input")({
  display: "none",
});

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const EmailSetting = () => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [isGetLoading, setIsGetLoading] = useState(true);
  const [isPicLoading, setIsPicLoading] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const [inputs, setInputs] = useState({
    email: "",
    contact_link: "",
    office_address: "",
    support_name: "",
    faq_link: "",
    copyright_text: "",
    team_name: "",
    email_mode: "sandbox",
    facebook_link: "",
    twitter_link: "",
    instagram_link: "",
    linkedin_link: "",
    email_brand_logo: "",
    facebook_icon: "",
    instagram_icon: "",
    twitter_icon: "",
    linkedin_icon: "",
  });

  const [images, setImages] = useState({
    email_brand_logo: "",
    facebook_icon: "",
    instagram_icon: "",
    twitter_icon: "",
    linkedin_icon: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const fileChangedHandler = async (e) => {
    const { name, files } = e.target;
    if (files[0]) {
      setIsPicLoading(name);
      const formData = new FormData();
      formData.append("image", files[0]);
      const result = await _upload_image_s3_api(formData);
      if (result.code == 200) {
        setImages({ ...images, [name]: URL.createObjectURL(files[0]) });
        setInputs({ ...inputs, [name]: result.path });
        setIsPicLoading("");
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsPicLoading("");
      }
    }
  };

  const get_email_setting_data = async () => {
    setIsGetLoading(true);
    const result = await _get_email_settings();
    if (result.code === 200) {
      setInputs(result.email_settings);
      setIsGetLoading(false);
    } else {
      setIsGetLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let postData = {
      email: inputs.email,
      contact_link: inputs.contact_link,
      office_address: inputs.office_address,
      support_name: inputs.support_name,
      faq_link: inputs.faq_link,
      copyright_text: inputs.copyright_text,
      team_name: inputs.team_name,
      email_mode: inputs.email_mode,
      facebook_link: inputs.facebook_link,
      twitter_link: inputs.twitter_link,
      instagram_link: inputs.instagram_link,
      linkedin_link: inputs.linkedin_link,
      email_brand_logo: inputs.email_brand_logo,
      facebook_icon: inputs.facebook_icon,
      instagram_icon: inputs.instagram_icon,
      twitter_icon: inputs.twitter_icon,
      linkedin_icon: inputs.linkedin_icon,
    };

    const result = await _update_email_settings(postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    get_email_setting_data();
  }, []);

  if (isGetLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      <form onSubmit={handleSubmit}>
        <div className="row ">
          <div className="page-title-box">
            <h4>Email Settings</h4>
          </div>
          <div className="col-12 col-md-6 mt-3">
            <TextField
              id="outlined-basic"
              label="Email"
              variant="outlined"
              fullWidth
              placeholder="Email"
              name="email"
              value={inputs.email}
              onChange={handleChange}
              required
              type="email"
            />
          </div>
          <div className="col-12 col-md-6 mt-3">
            <TextField
              id="outlined-basic"
              label="Contact Link"
              variant="outlined"
              fullWidth
              placeholder="Contact Link"
              name="contact_link"
              value={inputs.contact_link}
              onChange={handleChange}
              required
            />
          </div>
          <div className="col-12 col-md-6 mt-3">
            <TextField
              id="outlined-basic"
              label="Office Address"
              variant="outlined"
              fullWidth
              placeholder="Office Address"
              name="office_address"
              value={inputs.office_address}
              onChange={handleChange}
              required
            />
          </div>
          <div className="col-12 col-md-6 mt-3">
            <TextField
              id="outlined-basic"
              label="Support Name"
              variant="outlined"
              fullWidth
              placeholder="Support Name"
              name="support_name"
              value={inputs.support_name}
              onChange={handleChange}
            />
          </div>
          <div className="col-12 col-md-6 mt-3">
            <TextField
              id="outlined-basic"
              label="FAQ Link"
              variant="outlined"
              fullWidth
              placeholder="FAQ Link"
              name="faq_link"
              value={inputs.faq_link}
              onChange={handleChange}
            />
          </div>
          <div className="col-12 col-md-6 mt-3">
            <TextField
              id="outlined-basic"
              label="Copyright Text"
              variant="outlined"
              fullWidth
              placeholder="Copyright Text"
              name="copyright_text"
              value={inputs.copyright_text}
              onChange={handleChange}
            />
          </div>
          <div className="col-12 col-md-6 mt-3">
            <TextField
              id="outlined-basic"
              label="Team Name"
              variant="outlined"
              fullWidth
              placeholder="Team Name"
              name="team_name"
              value={inputs.team_name}
              onChange={handleChange}
            />
          </div>
          <div className="col-12 col-md-6 mt-3">
            <TextField
              fullWidth
              id="outlined-basic"
              label="Email Mode"
              variant="outlined"
              select
              required
              value={inputs.email_mode}
              onChange={handleChange}
              name="email_mode"
            >
              <MenuItem value="sandbox">Sandbox</MenuItem>
              <MenuItem value="live">Live</MenuItem>
            </TextField>
          </div>
          <div className="col-12 mt-4">
            <div className="row w-100 div-style ms-0 pt-0">
              <div className="col-lg-5">
                <p className="text-black">Email Brand Logo *</p>
                <FormHelperText className="pt-0 helper_text">
                  Recommended Size 300 X 300
                </FormHelperText>
              </div>
              <div className="col-lg-2">
                {isPicLoading === "email_brand_logo" ? (
                  <CircularProgress size="1.5rem" color="primary" />
                ) : (
                  <img
                    src={
                      images.email_brand_logo
                        ? images.email_brand_logo
                        : inputs?.email_brand_logo
                        ? s3BaseUrl + inputs?.email_brand_logo
                        : ""
                    }
                    height="50"
                  />
                )}
              </div>
              <div className="col-lg-5 text-end pt-2">
                <label htmlFor="contained-button-file1">
                  <Input
                    accept="image/*"
                    id="contained-button-file1"
                    multiple
                    type="file"
                    name="email_brand_logo"
                    onChange={fileChangedHandler}
                  />

                  <Button
                    className="btn btn-primary "
                    startIcon={<FileUploadIcon />}
                    component="span"
                  >
                    Upload
                  </Button>
                </label>
              </div>
            </div>
            {inputs.email_brand_logo?.name === "" ? (
              ""
            ) : (
              <p className="text-secondary">{inputs.email_brand_logo?.name}</p>
            )}
          </div>
          <div className="page-title-box mt-3">
            <h4>Social Media</h4>
          </div>
          <div className="col-12 col-md-6 mt-3">
            <TextField
              id="outlined-basic"
              label="Facebook Link"
              variant="outlined"
              fullWidth
              placeholder="Facebook Link"
              name="facebook_link"
              value={inputs.facebook_link}
              onChange={handleChange}
            />
          </div>
          <div className="col-12 col-md-6 mt-3">
            <TextField
              id="outlined-basic"
              label="Twitter Link"
              variant="outlined"
              fullWidth
              placeholder="Twitter Link"
              name="twitter_link"
              value={inputs.twitter_link}
              onChange={handleChange}
            />
          </div>
          <div className="col-12 col-md-6 mt-3">
            <TextField
              id="outlined-basic"
              label="Instagram Link"
              variant="outlined"
              fullWidth
              placeholder="Instagram Link"
              name="instagram_link"
              value={inputs.instagram_link}
              onChange={handleChange}
            />
          </div>
          <div className="col-12 col-md-6 mt-3">
            <TextField
              id="outlined-basic"
              label="LinkedIn Link"
              variant="outlined"
              fullWidth
              placeholder="LinkedIn Link"
              name="linkedin_link"
              value={inputs.linkedin_link}
              onChange={handleChange}
            />
          </div>
          <div className="col-12 mt-4">
            <div className="row w-100 div-style ms-0 pt-0">
              <div className="col-lg-5">
                <p className="text-black">Facebook Icon</p>
                <FormHelperText className="pt-0 helper_text">
                  Recommended Size 778 X 430
                </FormHelperText>
              </div>
              <div className="col-lg-2">
                {isPicLoading === "facebook_icon" ? (
                  <CircularProgress size="1.5rem" color="primary" />
                ) : (
                  <img
                    src={
                      images.facebook_icon
                        ? images.facebook_icon
                        : inputs?.facebook_icon
                        ? s3BaseUrl + inputs?.facebook_icon
                        : ""
                    }
                    height="50"
                  />
                )}
              </div>
              <div className="col-lg-5 text-end pt-2">
                <label htmlFor="contained-button-file2">
                  <Input
                    accept="image/*"
                    id="contained-button-file2"
                    multiple
                    type="file"
                    name="facebook_icon"
                    onChange={fileChangedHandler}
                  />

                  <Button
                    className="btn btn-primary "
                    startIcon={<FileUploadIcon />}
                    component="span"
                  >
                    Upload
                  </Button>
                </label>
              </div>
            </div>
            {inputs.facebook_icon?.name === "" ? (
              ""
            ) : (
              <p className="text-secondary">{inputs.facebook_icon?.name}</p>
            )}
          </div>
          <div className="col-12 mt-4">
            <div className="row w-100 div-style ms-0 pt-0">
              <div className="col-lg-5">
                <p className="text-black">Instagram Icon</p>
                <FormHelperText className="pt-0 helper_text">
                  Recommended Size 778 X 430
                </FormHelperText>
              </div>
              <div className="col-lg-2">
                {isPicLoading === "instagram_icon" ? (
                  <CircularProgress size="1.5rem" color="primary" />
                ) : (
                  <img
                    src={
                      images.instagram_icon
                        ? images.instagram_icon
                        : inputs?.instagram_icon
                        ? s3BaseUrl + inputs?.instagram_icon
                        : ""
                    }
                    height="50"
                  />
                )}
              </div>
              <div className="col-lg-5 text-end pt-2">
                <label htmlFor="contained-button-file3">
                  <Input
                    accept="image/*"
                    id="contained-button-file3"
                    multiple
                    type="file"
                    name="instagram_icon"
                    onChange={fileChangedHandler}
                  />

                  <Button
                    className="btn btn-primary "
                    startIcon={<FileUploadIcon />}
                    component="span"
                  >
                    Upload
                  </Button>
                </label>
              </div>
            </div>
            {inputs.instagram_icon?.name === "" ? (
              ""
            ) : (
              <p className="text-secondary">{inputs.instagram_icon?.name}</p>
            )}
          </div>
          <div className="col-12 mt-4">
            <div className="row w-100 div-style ms-0 pt-0">
              <div className="col-lg-5">
                <p className="text-black">Twitter Icon</p>
                <FormHelperText className="pt-0 helper_text">
                  Recommended Size 778 X 430
                </FormHelperText>
              </div>
              <div className="col-lg-2">
                {isPicLoading === "twitter_icon" ? (
                  <CircularProgress size="1.5rem" color="primary" />
                ) : (
                  <img
                    src={
                      images.twitter_icon
                        ? images.twitter_icon
                        : inputs?.twitter_icon
                        ? s3BaseUrl + inputs?.twitter_icon
                        : ""
                    }
                    height="50"
                  />
                )}
              </div>
              <div className="col-lg-5 text-end pt-2">
                <label htmlFor="contained-button-file4">
                  <Input
                    accept="image/*"
                    id="contained-button-file4"
                    multiple
                    type="file"
                    name="twitter_icon"
                    onChange={fileChangedHandler}
                  />

                  <Button
                    className="btn btn-primary "
                    startIcon={<FileUploadIcon />}
                    component="span"
                  >
                    Upload
                  </Button>
                </label>
              </div>
            </div>
            {inputs.twitter_icon?.name === "" ? (
              ""
            ) : (
              <p className="text-secondary">{inputs.twitter_icon?.name}</p>
            )}
          </div>
          <div className="col-12 mt-4">
            <div className="row w-100 div-style ms-0 pt-0">
              <div className="col-lg-5">
                <p className="text-black">LinkedIn Icon</p>
                <FormHelperText className="pt-0 helper_text">
                  Recommended Size 778 X 430
                </FormHelperText>
              </div>
              <div className="col-lg-2">
                {isPicLoading === "linkedin_icon" ? (
                  <CircularProgress size="1.5rem" color="primary" />
                ) : (
                  <img
                    src={
                      images.linkedin_icon
                        ? images.linkedin_icon
                        : inputs?.linkedin_icon
                        ? s3BaseUrl + inputs?.linkedin_icon
                        : ""
                    }
                    height="50"
                  />
                )}
              </div>
              <div className="col-lg-5 text-end pt-2">
                <label htmlFor="contained-button-file5">
                  <Input
                    accept="image/*"
                    id="contained-button-file5"
                    multiple
                    type="file"
                    name="linkedin_icon"
                    onChange={fileChangedHandler}
                  />

                  <Button
                    className="btn btn-primary "
                    startIcon={<FileUploadIcon />}
                    component="span"
                  >
                    Upload
                  </Button>
                </label>
              </div>
            </div>
            {inputs.linkedin_icon?.name === "" ? (
              ""
            ) : (
              <p className="text-secondary">{inputs.linkedin_icon?.name}</p>
            )}
          </div>
          <div className="text-end mt-4">
            <button
              className="btn btn-primary mt-4 "
              disabled={isLoading || isPicLoading}
            >
              {isLoading ? "Updating..." : "Update"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EmailSetting;
