import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { Button, CircularProgress } from "@mui/material";
import CustomTable from "../../components/customTable/CustomTable";
import DeleteConfirmation from "../../components/DeleteConfirmation";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { s3BaseUrl } from "../../config/config";
import { useNavigate } from "react-router-dom";
import { ADMIN_USERS_STATUS } from "../../utils/constant";
import {
  _admin_users_list,
  _delete_admin_user,
} from "../../DAL/AdminUsers/AdminUsers";
import ChangePasswordUser from "../Users/ChangePasswordUser";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const AdminUsers = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [openDelete, setOpenDelete] = useState(false);
  const [delLoading, setDelLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [userList, setUserList] = useState([]);
  const [deleteId, setDeleteId] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [openPasswordModal, setopenPasswordModal] = useState(false);
  const [userId, setUserId] = useState("");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClickOpen = () => {
    navigate(`/admin-users/add-user`);
  };

  const handleClickEdit = (row) => {
    navigate(`/admin-users/edit-user/${row.user_id._id}`);
  };

  const handleClickManageNavItemsAccess = (row) => {
    navigate(`/admin-users/manage-navitems-access/${row.user_id._id}`);
  };

  const handleAgreeDelete = (row) => {
    setDeleteId(row.user_id._id);
    setOpenDelete(true);
  };

  const get_user_list = async () => {
    setIsLoading(true);
    let search_keyword =
      localStorage.getItem("admin_user_search_text") == null
        ? searchText
        : localStorage.getItem("admin_user_search_text");
    if (search_keyword !== null) {
      setSearchText(search_keyword);
    }
    const result = await _admin_users_list(search_keyword, page, rowsPerPage);
    if (result.code === 200) {
      let data = result.admin_list.map((item) => {
        let full_name = "N/A";
        let user_email = "N/A";
        if (item.first_name) {
          full_name = item.first_name + " " + item.last_name;
        }
        if (item.user_id.email) {
          user_email = item.user_id.email;
        }

        let profile_thumbnail = {
          src: s3BaseUrl + item.profile_image,
          alt: full_name,
        };

        return {
          ...item,
          full_name,
          profile_thumbnail,
          user_email,
        };
      });
      setUserList(data);
      setTotalCount(result.total_count);
      setTotalPages(result.total_pages);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChangePassWord = (row) => {
    setopenPasswordModal(true);
    setUserId(row?.user_id?._id);
  };

  const searchFunction = () => {
    localStorage.setItem("admin_user_search_text", searchText);
    setPage(0);
    setPageCount(1);
    get_user_list();
  };

  const handleDelete = async () => {
    setDelLoading(true);
    const result = await _delete_admin_user(deleteId);
    if (result.code === 200) {
      let user_list = userList.filter(
        (user_id) => user_id.user_id._id !== deleteId
      );
      setUserList(user_list);
      setOpenDelete(false);
      setDelLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setDelLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const TABLE_HEAD = [
    { id: "action", label: "Action", type: "action" },
    { id: "number", label: "#", type: "number" },
    {
      id: "profile_thumbnail",
      label: "Image",
      type: "thumbnail",
    },
    {
      id: "full_name",
      label: "Name",
    },
    { id: "user_email", label: "Email" },
    {
      id: "role",
      label: "Role",
      className: "text-capitalize",
    },
    {
      id: "status",
      label: "Status",
      renderData: (row) => {
        let find_status = ADMIN_USERS_STATUS.find(
          (status) => status.value === row.status
        );
        return (
          <>
            <span className={`${find_status?.class}`}>{find_status?.name}</span>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    get_user_list();
  }, [rowsPerPage, page]);

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleClickEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
    {
      label: "Change password",
      icon: "ant-design:lock",
      handleClick: handleChangePassWord,
    },
    {
      label: "Manage NavItems Access",
      icon: "uil:lock-access",
      handleClick: handleClickManageNavItemsAccess,
    },
  ];

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <DeleteConfirmation
        open={openDelete}
        isLoading={delLoading}
        setOpen={setOpenDelete}
        title={"Are you sure you want to remove this admin user ?"}
        handleAgree={handleDelete}
      />
      <ChangePasswordUser
        openPasswordModal={openPasswordModal}
        setopenPasswordModal={setopenPasswordModal}
        userId={userId}
      />

      <div className="d-flex align-items-center justify-content-between flex-wrap ">
        <div className="page-title-box">
          <h4>Admin Users</h4>
        </div>
        <div className="d-flex gap-2">
          <Button
            onClick={handleClickOpen}
            variant="contained"
            startIcon={
              <AddIcon
                style={{
                  fill: "#fff",
                }}
              />
            }
          >
            Add User
          </Button>
        </div>
      </div>
      <div className="mt-4">
        <CustomTable
          data={userList}
          TABLE_HEAD={TABLE_HEAD}
          MENU_OPTIONS={MENU_OPTIONS}
          custom_pagination={{
            total_count: totalCount,
            rows_per_page: rowsPerPage,
            page: page,
            handleChangePage: handleChangePage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          custom_search={{
            searchText: searchText,
            setSearchText: setSearchText,
            handleSubmit: searchFunction,
          }}
          pageCount={pageCount}
          totalPages={totalPages}
          handleChangePages={handleChangePages}
          pagePagination={true}
        />
      </div>
    </>
  );
};

export default AdminUsers;
