import React, { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { _get_email_template_by_id } from "../../DAL/Setting/Setting";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import "react-tagsinput/react-tagsinput.css";
import ActiveLastBreadcrumb from "../../components/ActiveLastBreadcrumb";
import { useParams } from "react-router-dom";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const PreviewEmailTemplate = () => {
  const classes = useStyles();
  const { id } = useParams();
  const [isGetLoading, setIsGetLoading] = useState(true);
  const [emailBody, setEmailBody] = useState("");
  const [title, setTitle] = useState("Preview");
  const { enqueueSnackbar } = useSnackbar();

  const get_email_template_by_id_data = async () => {
    setIsGetLoading(true);
    const result = await _get_email_template_by_id(id);
    if (result.code === 200) {
      setEmailBody(result.email_body);
      setTitle(result.email_template.title);
      setIsGetLoading(false);
    } else {
      setIsGetLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  let breadCrumbMenu = [
    {
      title: "Email Templates",
      navigation: `/email-templates`,
      active: false,
    },
    {
      title: title,
      active: true,
    },
  ];

  useEffect(() => {
    get_email_template_by_id_data();
  }, []);

  if (isGetLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      <div className="d-flex justify-content-between align-items-center flex-wrap ">
        <div className="page-title-box"></div>
        <div className="mb-4">
          <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
        </div>
      </div>
      <div className="preview_list">
        <div
          dangerouslySetInnerHTML={{
            __html: emailBody,
          }}
        ></div>
      </div>
    </div>
  );
};

export default PreviewEmailTemplate;
