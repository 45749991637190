import { ThemeProvider, createTheme } from "@mui/material/styles";

export default function ThemeConfig({ children }) {
  const themeOptions = {
    palette: {
      primary: {
        main: "#438a7a", // Update the primary color
      },
      secondary: {
        main: "#438a7a", // Update the secondary color
      },
      text: {
        primary: "#000",
      },
    },
  };
  const theme = createTheme(themeOptions);
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
