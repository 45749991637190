import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import _ from "lodash";
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  CircularProgress,
} from "@mui/material";
import Iconify from "../../components/Iconify";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import CustomImageUploader from "./components/CustomImageUploader";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { _get_nav_items, _update_nav_items } from "../../DAL/NavItems";
import { makeStyles } from "@mui/styles";
import RecordNotFound from "../../components/RecordNotFound";
const uuidv4 = require("uuid").v4;

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const AdminNavItems = ({ type }) => {
  const classes = useStyles();
  const theme = useTheme();
  const belowSm = useMediaQuery(theme.breakpoints.down("sm"));
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [items, setItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [expandedParents, setExpandedParents] = useState({});

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
  };

  const toggleExpand = (parentId) => {
    setExpandedParents((prevExpanded) => ({
      ...prevExpanded,
      [parentId]: !prevExpanded[parentId],
    }));
  };

  const throttledHandleDragEnd = _.throttle((result) => {
    if (!result.destination) return;

    const newItems = [...items];
    const source = result.source;
    const destination = result.destination;

    if (result.type === "PARENT") {
      const [removed] = newItems.splice(source.index, 1);
      newItems.splice(destination.index, 0, removed);
    } else {
      const sourceParentIndex = items.findIndex(
        (item) => item._id === source.droppableId
      );

      const destinationParentIndex = items.findIndex(
        (item) => item._id === destination.droppableId
      );
      const sourceParent = newItems[sourceParentIndex];
      const destinationParent = newItems[destinationParentIndex];

      const [removed] = sourceParent.child_options.splice(source.index, 1);
      destinationParent.child_options.splice(destination.index, 0, removed);
    }

    setItems(newItems);
  }, 500);

  const handleAddParent = () => {
    scrollToBottom();
    const newParent = {
      _id: uuidv4(),
      title: "",
      is_link: false,
      is_open_new_tab: false,
      icon: "",
      child_options: [],
      value: "",
      link: "",
      path: "",
    };
    setItems((prevItems) => [...prevItems, newParent]);
  };

  // Function to handle search input change
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // const filteredItems = items.filter(
  //   (parent) =>
  //     parent.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
  //     parent.child_options.some((child) =>
  //       child.title.toLowerCase().includes(searchTerm.toLowerCase())
  //     )
  // );

  // const filteredItems = items.filter((parent) => {
  //   const hasChildOptions = Array.isArray(parent.child_options);
  //   return (
  //     parent.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
  //     (hasChildOptions &&
  //       parent.child_options.some((child) =>
  //         child.title.toLowerCase().includes(searchTerm.toLowerCase())
  //       ))
  //   );
  // });

  const filteredItems = items.reduce((acc, parent) => {
    const hasChildOptions = Array.isArray(parent.child_options);

    // Check if the parent matches the search term
    const parentMatches = parent.title
      .toLowerCase()
      .includes(searchTerm.toLowerCase());

    // Filter child options if they exist
    const filteredChildren = hasChildOptions
      ? parent.child_options.filter((child) =>
          child.title.toLowerCase().includes(searchTerm.toLowerCase())
        )
      : [];

    // If parent matches or if there are matching children, add to the accumulator
    if (parentMatches || filteredChildren.length > 0) {
      acc.push({
        ...parent,
        child_options: filteredChildren, // Include only matching children
      });
    }

    return acc;
  }, []);

  const handleAddChild = (index) => {
    // scrollToBottom();
    let newItem = [...items];

    const newChild = {
      _id: uuidv4(),
      value: "",
      title: "",
      path: "",
      link: "",
      is_open_new_tab: false,
      is_link: false,
      icon: "",
      order: newItem[index]?.child_options.length + 1,
    };

    newItem[3].child_options.push(newChild);

    setItems(newItem);

    // setItems((prevItems) => [
    //   ...prevItems,
    //   prevItems[3].child_options.push(newChild),
    // ]);
  };

  const handleBaseChange = (e, parentIndex) => {
    const { name, value } = e.target;
    const newItems = [...items];
    newItems[parentIndex][name] = value;
    setItems(newItems);
  };

  const handleChildChange = (e, parentIndex, childIndex) => {
    const { name, value } = e.target;
    const newItems = [...items];
    newItems[parentIndex].child_options[childIndex][name] = value;
    setItems(newItems);
  };

  const get_nav_items_detail = async () => {
    setIsPageLoading(true);
    const result = await _get_nav_items(type);
    if (result.code === 200) {
      setItems(result.nav_items);
      setIsPageLoading(false);
    } else {
      setIsPageLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let handle_values = (item, order) => {
      return {
        icon: item.icon,
        is_link: item.is_link,
        is_open_new_tab: item.is_open_new_tab,
        link: item.link,
        order: order,
        path: item.path,
        title: item.title,
        value: item.value,
        _id: item._id,
      };
    };

    let items_array = items.map((item, parentIndex) => {
      let mappedItem = {
        ...handle_values(item, parentIndex + 1),
      };
      if (item.child_options && item.child_options.length > 0) {
        mappedItem.child_options = item.child_options.map(
          (child, childIndex) => {
            return {
              ...handle_values(child, childIndex + 1),
            };
          }
        );
      }
      return mappedItem;
    });

    setIsLoading(true);
    const result = await _update_nav_items({
      type: type,
      nav_items: items_array,
    });
    if (result.code === 200) {
      navigate(type === "admin" ? `/admin-nav-items` : `/client-nav-items`);
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  useEffect(() => {
    get_nav_items_detail();
  }, [type]);

  useEffect(() => {
    console.log("items  ___items", items);
  }, [items]);

  if (isPageLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="d-flex align-items-center justify-content-between flex-wrap ">
        <div className="page-title-box">
          <h4>{type === "admin" ? "Admin Nav Items" : "Client Nav Items"}</h4>
        </div>

        <div className="d-flex gap-3">
          <TextField
            label="Search"
            variant="outlined"
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <Button
            onClick={handleAddParent}
            variant="contained"
            startIcon={<AddIcon style={{ fill: "#fff" }} />}
          >
            Add Nav Item
          </Button>
        </div>
      </div>

      <form onSubmit={handleSubmit}>
        <Box>
          <DragDropContext onDragEnd={throttledHandleDragEnd}>
            <Droppable droppableId="characters" type="PARENT">
              {(provided) => (
                <div
                  className="characters"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {filteredItems.length > 0 ? (
                    <>
                      {filteredItems.map((parent, parentIndex) => (
                        <Draggable
                          key={parent._id}
                          draggableId={parent._id}
                          index={parentIndex}
                          type="PARENT"
                        >
                          {(provided) => (
                            <Card
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              sx={{
                                minWidth: 275,
                                mt: 2,
                                boxShadow: 2,
                                width: "100%",
                              }}
                            >
                              <CardContent
                                sx={{
                                  padding: "10px",
                                }}
                                className="mt-2"
                              >
                                <div
                                  className="container"
                                  style={{
                                    paddingLeft: belowSm ? 0 : undefined,
                                  }}
                                >
                                  <div className="row">
                                    <div
                                      className="col-12 col-md-9 mt-3 "
                                      style={{ paddingRight: 0 }}
                                    >
                                      <Stack spacing={3}>
                                        <Stack
                                          direction={{
                                            xs: "column",
                                            lg: "row",
                                          }}
                                          spacing={2}
                                        >
                                          <TextField
                                            id={`outlined-basic-title-${parentIndex}`}
                                            label="Title"
                                            variant="outlined"
                                            fullWidth
                                            required
                                            value={parent.title}
                                            placeholder="Title *"
                                            name="title"
                                            onChange={(e) =>
                                              handleBaseChange(e, parentIndex)
                                            }
                                          />

                                          {parent.child_options?.length >
                                          0 ? null : (
                                            <FormControl fullWidth required>
                                              <InputLabel
                                                id={`demo-simple-select-label-is_link-${parentIndex}`}
                                              >
                                                Is Link
                                              </InputLabel>
                                              <Select
                                                labelId={`demo-simple-select-label-is_link-${parentIndex}`}
                                                id={`demo-simple-select-is_link-${parentIndex}`}
                                                label="Is Link"
                                                value={parent.is_link}
                                                name="is_link"
                                                onChange={(e) =>
                                                  handleBaseChange(
                                                    e,
                                                    parentIndex
                                                  )
                                                }
                                              >
                                                <MenuItem value={true}>
                                                  Yes
                                                </MenuItem>
                                                <MenuItem value={false}>
                                                  No
                                                </MenuItem>
                                              </Select>
                                            </FormControl>
                                          )}

                                          {parent.child_options?.length >
                                          0 ? null : (
                                            <FormControl fullWidth required>
                                              <InputLabel
                                                id={`demo-simple-select-label-is_open_new_tab-${parentIndex}`}
                                              >
                                                Is Open New Tab
                                              </InputLabel>
                                              <Select
                                                labelId={`demo-simple-select-label-is_open_new_tab-${parentIndex}`}
                                                id={`demo-simple-select-is_open_new_tab-${parentIndex}`}
                                                label="Is Open New Tab"
                                                value={parent.is_open_new_tab}
                                                name="is_open_new_tab"
                                                onChange={(e) =>
                                                  handleBaseChange(
                                                    e,
                                                    parentIndex
                                                  )
                                                }
                                              >
                                                <MenuItem value={true}>
                                                  Yes
                                                </MenuItem>
                                                <MenuItem value={false}>
                                                  No
                                                </MenuItem>
                                              </Select>
                                            </FormControl>
                                          )}

                                          <TextField
                                            id={`outlined-basic-value-${parentIndex}`}
                                            label="Value"
                                            variant="outlined"
                                            fullWidth
                                            required
                                            value={parent.value}
                                            placeholder="Value *"
                                            name="value"
                                            onChange={(e) =>
                                              handleBaseChange(e, parentIndex)
                                            }
                                          />

                                          {parent.child_options?.length >
                                          0 ? null : (
                                            <>
                                              {parent.is_link && (
                                                <TextField
                                                  id={`outlined-basic-link-${parentIndex}`}
                                                  label="Link"
                                                  variant="outlined"
                                                  fullWidth
                                                  required
                                                  value={parent.link}
                                                  name="link"
                                                  placeholder="Link *"
                                                  onChange={(e) =>
                                                    handleBaseChange(
                                                      e,
                                                      parentIndex
                                                    )
                                                  }
                                                />
                                              )}
                                            </>
                                          )}

                                          {parent.child_options?.length >
                                          0 ? null : (
                                            <TextField
                                              id={`outlined-basic-path-${parentIndex}`}
                                              label="Path"
                                              variant="outlined"
                                              fullWidth
                                              required
                                              value={parent.path}
                                              placeholder="Path *"
                                              name="path"
                                              onChange={(e) =>
                                                handleBaseChange(e, parentIndex)
                                              }
                                            />
                                          )}
                                        </Stack>
                                      </Stack>
                                    </div>
                                    <div className="col-12 col-md-2 mt-1 ">
                                      <div className="d-flex justify-content-center justify-content-md-start">
                                        <CustomImageUploader
                                          items={items}
                                          setItems={setItems}
                                          parentIndex={parentIndex}
                                          type={0}
                                          childIndex={-100}
                                        />
                                      </div>
                                    </div>

                                    <div className="col-12 col-md-1 ">
                                      <div className="d-flex justify-content-end justify-content-md-start">
                                        <div
                                          className="col-5 col-md-5 order-2 order-md-1 d-flex justify-content-end justify-content-md-start "
                                          {...provided.dragHandleProps}
                                          style={{ marginTop: "23px" }}
                                        >
                                          <Iconify
                                            icon="akar-icons:drag-vertical"
                                            width={24}
                                            height={24}
                                          />
                                        </div>
                                        {parent.child_options?.length > 0 ? (
                                          <div className="col-7 col-md-7 order-1 order-md-2 mt-3 d-flex justify-content-end justify-content-md-start ">
                                            <IconButton
                                              onClick={() =>
                                                toggleExpand(parent._id)
                                              }
                                              aria-label="expand"
                                            >
                                              {!expandedParents[parent._id] ? (
                                                <ExpandLessIcon />
                                              ) : (
                                                <ExpandMoreIcon />
                                              )}
                                            </IconButton>
                                          </div>
                                        ) : null}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {!expandedParents[parent._id] && (
                                  <Droppable
                                    droppableId={`${parent._id}`}
                                    type="CHILD"
                                    direction="vertical"
                                  >
                                    {(provided) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                        className="px-4"
                                      >
                                        {parent.child_options &&
                                          parent.child_options.map(
                                            (child, childIndex) => (
                                              <Draggable
                                                key={child._id}
                                                draggableId={child._id}
                                                index={childIndex}
                                                type="CHILD"
                                              >
                                                {(provided) => (
                                                  <Card
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    sx={{
                                                      marginTop: 2,
                                                      width: "100%",
                                                    }}
                                                  >
                                                    <CardContent
                                                      sx={{
                                                        padding: "10px",
                                                      }}
                                                      className="mt-2"
                                                    >
                                                      <div
                                                        className="container"
                                                        style={{
                                                          paddingLeft: belowSm
                                                            ? 0
                                                            : undefined,
                                                        }}
                                                      >
                                                        <div className="row">
                                                          <div
                                                            className="col-12 col-md-11 mt-3 "
                                                            style={{
                                                              paddingRight: 0,
                                                            }}
                                                          >
                                                            <Stack spacing={3}>
                                                              <Stack
                                                                direction={{
                                                                  xs: "column",
                                                                  lg: "row",
                                                                }}
                                                                spacing={2}
                                                              >
                                                                <TextField
                                                                  id={`outlined-basic-title-${parentIndex}-${childIndex}`}
                                                                  label="Title"
                                                                  variant="outlined"
                                                                  required
                                                                  placeholder="Title *"
                                                                  fullWidth
                                                                  value={
                                                                    child.title
                                                                  }
                                                                  name="title"
                                                                  onChange={(
                                                                    e
                                                                  ) =>
                                                                    handleChildChange(
                                                                      e,
                                                                      parentIndex,
                                                                      childIndex
                                                                    )
                                                                  }
                                                                />

                                                                <FormControl
                                                                  fullWidth
                                                                  required
                                                                >
                                                                  <InputLabel
                                                                    id={`demo-simple-select-label-is_link-${parentIndex}-${childIndex}`}
                                                                  >
                                                                    Is Link
                                                                  </InputLabel>
                                                                  <Select
                                                                    labelId={`demo-simple-select-label-is_link-${parentIndex}-${childIndex}`}
                                                                    id={`demo-simple-select-is_link-${parentIndex}-${childIndex}`}
                                                                    label="Is Link"
                                                                    value={
                                                                      child.is_link
                                                                    }
                                                                    name="is_link"
                                                                    onChange={(
                                                                      e
                                                                    ) =>
                                                                      handleChildChange(
                                                                        e,
                                                                        parentIndex,
                                                                        childIndex
                                                                      )
                                                                    }
                                                                  >
                                                                    <MenuItem
                                                                      value={
                                                                        true
                                                                      }
                                                                    >
                                                                      Yes
                                                                    </MenuItem>
                                                                    <MenuItem
                                                                      value={
                                                                        false
                                                                      }
                                                                    >
                                                                      No
                                                                    </MenuItem>
                                                                  </Select>
                                                                </FormControl>

                                                                <FormControl
                                                                  fullWidth
                                                                  required
                                                                >
                                                                  <InputLabel
                                                                    id={`demo-simple-select-label-is_open_new_tab-${parentIndex}-${childIndex}`}
                                                                  >
                                                                    Is Open New
                                                                    Tab
                                                                  </InputLabel>
                                                                  <Select
                                                                    labelId={`demo-simple-select-label-is_open_new_tab-${parentIndex}-${childIndex}`}
                                                                    id={`demo-simple-select-is_open_new_tab-${parentIndex}-${childIndex}`}
                                                                    label="Is Open New Tab"
                                                                    value={
                                                                      child.is_open_new_tab
                                                                    }
                                                                    name="is_open_new_tab"
                                                                    onChange={(
                                                                      e
                                                                    ) =>
                                                                      handleChildChange(
                                                                        e,
                                                                        parentIndex,
                                                                        childIndex
                                                                      )
                                                                    }
                                                                  >
                                                                    <MenuItem
                                                                      value={
                                                                        true
                                                                      }
                                                                    >
                                                                      Yes
                                                                    </MenuItem>
                                                                    <MenuItem
                                                                      value={
                                                                        false
                                                                      }
                                                                    >
                                                                      No
                                                                    </MenuItem>
                                                                  </Select>
                                                                </FormControl>

                                                                <TextField
                                                                  id={`outlined-basic-value-${parentIndex}-${childIndex}`}
                                                                  label="Value"
                                                                  variant="outlined"
                                                                  fullWidth
                                                                  required
                                                                  value={
                                                                    child.value
                                                                  }
                                                                  placeholder="Value *"
                                                                  name="value"
                                                                  onChange={(
                                                                    e
                                                                  ) =>
                                                                    handleChildChange(
                                                                      e,
                                                                      parentIndex,
                                                                      childIndex
                                                                    )
                                                                  }
                                                                />

                                                                {child.is_link && (
                                                                  <TextField
                                                                    id={`outlined-basic-link-${parentIndex}-${childIndex}`}
                                                                    label="Link"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    required
                                                                    value={
                                                                      child.link
                                                                    }
                                                                    placeholder="Link *"
                                                                    name="link"
                                                                    onChange={(
                                                                      e
                                                                    ) =>
                                                                      handleChildChange(
                                                                        e,
                                                                        parentIndex,
                                                                        childIndex
                                                                      )
                                                                    }
                                                                  />
                                                                )}

                                                                <TextField
                                                                  id={`outlined-basic-path-${parentIndex}-${childIndex}`}
                                                                  label="Path"
                                                                  variant="outlined"
                                                                  fullWidth
                                                                  required
                                                                  value={
                                                                    child.path
                                                                  }
                                                                  placeholder="Path *"
                                                                  name="path"
                                                                  onChange={(
                                                                    e
                                                                  ) =>
                                                                    handleChildChange(
                                                                      e,
                                                                      parentIndex,
                                                                      childIndex
                                                                    )
                                                                  }
                                                                />
                                                              </Stack>
                                                            </Stack>
                                                          </div>
                                                          <div className="col-12 col-md-1">
                                                            <div className="d-flex justify-content-end justify-content-md-start">
                                                              <div
                                                                className="col-6 col-md-6"
                                                                {...provided.dragHandleProps}
                                                                style={{
                                                                  marginTop:
                                                                    "23px",
                                                                }}
                                                              >
                                                                <Iconify
                                                                  icon="akar-icons:drag-vertical"
                                                                  width={24}
                                                                  height={24}
                                                                />
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </CardContent>
                                                  </Card>
                                                )}
                                              </Draggable>
                                            )
                                          )}

                                        {provided.placeholder}
                                      </div>
                                    )}
                                  </Droppable>
                                )}
                              </CardContent>
                            </Card>
                          )}
                        </Draggable>
                      ))}
                    </>
                  ) : (
                    <div className="mt-5">
                      <RecordNotFound title="Nav Items" />
                    </div>
                  )}

                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Box>

        <div className="fixed_button mt-4">
          <Button type="submit" variant="contained">
            {isLoading ? "Submiting" : "Submit"}
          </Button>
        </div>
      </form>
    </>
  );
};

export default AdminNavItems;
