import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { Button, CircularProgress, Tooltip } from "@mui/material";
import CustomTable from "../../components/customTable/CustomTable";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { EMAIL_TEMPLATES_STATUS } from "../../utils/constant";
import {
  _delete_email_templates,
  _get_all_email_templates,
} from "../../DAL/Setting/Setting";
import DeleteConfirmation from "../../components/DeleteConfirmation";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const EmailTemplates = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [templateList, setTemplateList] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [delLoading, setDelLoading] = useState(false);
  const [deleteId, setDeleteId] = useState("");

  const handleClickOpen = () => {
    navigate(`/email-templates/add-template`);
  };

  const handleClickEdit = (row) => {
    navigate(`/email-templates/edit-template/${row._id}`);
  };

  const handleClickPreview = (row) => {
    navigate(`/email-templates/preview/${row._id}`);
  };

  const get_template_list = async () => {
    setIsLoading(true);

    const result = await _get_all_email_templates();
    if (result.code === 200) {
      setTemplateList(result.email_template);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleAgreeDelete = (row) => {
    setDeleteId(row._id);
    setOpenDelete(true);
  };

  const handleDelete = async () => {
    setDelLoading(true);
    const result = await _delete_email_templates(deleteId);
    if (result.code === 200) {
      let user_list = templateList.filter(
        (user_id) => user_id._id !== deleteId
      );
      setTemplateList(user_list);
      setOpenDelete(false);
      setDelLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setDelLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    { id: "title", label: "Template Title" },

    {
      id: "preview",
      label: "Preview",
      renderData: (row) => {
        return (
          <>
            <div className="d-flex gap-2">
              <div
                className="preview-link"
                onClick={() => {
                  handleClickPreview(row);
                }}
              >
                Preview
              </div>
            </div>
          </>
        );
      },
    },
    {
      id: "status",
      label: "Status",
      renderData: (row) => {
        let find_status = EMAIL_TEMPLATES_STATUS.find(
          (status) => status.value === row.status
        );
        return (
          <>
            <span className={`${find_status?.class}`}>{find_status?.name}</span>
          </>
        );
      },
    },
    {
      id: "action",
      label: "Action",
      renderData: (row) => {
        return (
          <>
            <div className="d-flex gap-2">
              <Tooltip title="Edit Email Templates" placement="top">
                <button
                  className="btn btn-soft-info"
                  onClick={() => {
                    handleClickEdit(row);
                  }}
                >
                  <i className="fa-solid fa-pen-to-square"></i>
                </button>
              </Tooltip>
              <Tooltip title="Delete Email Templates" placement="top">
                <button
                  className="btn btn-soft-danger"
                  onClick={() => {
                    handleAgreeDelete(row);
                  }}
                >
                  <i className="fa-solid fa-trash"></i>
                </button>
              </Tooltip>
            </div>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    get_template_list();
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <DeleteConfirmation
        open={openDelete}
        isLoading={delLoading}
        setOpen={setOpenDelete}
        title={"Are you sure you want to remove email template ?"}
        handleAgree={handleDelete}
      />
      <div className="d-flex align-items-center justify-content-between flex-wrap ">
        <div className="page-title-box">
          <h4>Email Templates</h4>
        </div>
        <div className="d-flex gap-2">
          <Button
            onClick={handleClickOpen}
            variant="contained"
            startIcon={
              <AddIcon
                style={{
                  fill: "#fff",
                }}
              />
            }
          >
            Add Template
          </Button>
        </div>
      </div>
      <div className="mt-4">
        <CustomTable
          data={templateList}
          TABLE_HEAD={TABLE_HEAD}
          pagePagination={true}
        />
      </div>
    </>
  );
};

export default EmailTemplates;
