import React, { useEffect, useState } from "react";
import CustomTable from "../../../components/customTable/CustomTable";
import moment from "moment";
import { s3BaseUrl } from "../../../config/config";
import { UserIcon } from "../../../assets";
import { Avatar } from "@mui/material";

const Users = ({ tabData }) => {
  const [dataList, setDataList] = useState([]);

  const get_list = async () => {
    let data = tabData?.map((item) => {
      let full_name = "N/A";
      let user_email = "N/A";
      let created_At = "N/A";
      if (item.first_name) {
        full_name = item.first_name + " " + item.last_name;
      }
      if (item?.user_id?.email) {
        user_email = item?.user_id?.email;
      }
      if (item.createdAt) {
        created_At = moment(item.createdAt).format("DD-MM-YYYY");
      }
      return {
        ...item,
        full_name,
        user_email,
        created_At,
      };
    });
    setDataList(data);
  };
  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    {
      id: "full_name",
      label: "Name",
      renderData: (row) => {
        return (
          <>
            <div className="d-flex align-items-center">
              <div className="me-3 table_avatar">
                <Avatar
                  src={
                    row?.profile_image
                      ? s3BaseUrl + row?.profile_image
                      : UserIcon
                  }
                />
              </div>
              <span>{row?.full_name}</span>
            </div>
          </>
        );
      },
    },
    { id: "user_email", label: "Email" },
    {
      id: "contact_number",
      label: "Phone Number",
      renderData: (row) => {
        return <span>{`+${row?.contact_number}`}</span>;
      },
    },
    { id: "created_At", label: "Created At" },
  ];

  useEffect(() => {
    get_list();
  }, [tabData]);

  return <CustomTable data={dataList} TABLE_HEAD={TABLE_HEAD} is_hide={true} />;
};

export default Users;
