import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  FormHelperText,
  TextField,
} from "@mui/material";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { styled } from "@mui/material/styles";
import { s3BaseUrl } from "../../config/config";
import {
  _get_invoice_settings,
  _update_invoice_settings,
  _upload_image_s3_api,
} from "../../DAL/Setting/Setting";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { countries } from "../../utils/country";
import { LoadingButton } from "@mui/lab";

const Input = styled("input")({
  display: "none",
});

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const InvoiceSetting = () => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [isGetLoading, setIsGetLoading] = useState(true);
  const [isPicLoading, setIsPicLoading] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const [inputs, setInputs] = useState({
    invoice_from: "",
    dynamite_lifestyle_email: "",
    dynamite_payments_email: "",
    dynamite_digital_email: "",
    pay_to_name: "",
    pay_to_address: "",
    city: "",
    state: "",
    country: "",
    invoice_logo: "",
  });

  const [images, setImages] = useState({
    invoice_logo: "",
  });

  const [country, setCountry] = useState({
    code: "GB",
    label: "United Kingdom",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const fileChangedHandler = async (e) => {
    const { name, files } = e.target;
    if (files[0]) {
      setIsPicLoading(name);
      const formData = new FormData();
      formData.append("image", files[0]);
      const result = await _upload_image_s3_api(formData);
      if (result.code === 200) {
        setImages({ ...images, [name]: URL.createObjectURL(files[0]) });
        setInputs({ ...inputs, [name]: result.path });
        setIsPicLoading("");
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsPicLoading("");
      }
    }
  };

  const get_Invoice_setting_data = async () => {
    setIsGetLoading(true);
    const result = await _get_invoice_settings();
    if (result.code === 200) {
      setInputs(result.invoice_settings);
      setCountry(result.invoice_settings.country);
      setIsGetLoading(false);
    } else {
      setIsGetLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let postData = {
      invoice_from: inputs.invoice_from,
      dynamite_payments_email: inputs.dynamite_payments_email,
      pay_to_name: inputs.pay_to_name,
      pay_to_address: inputs.pay_to_address,
      city: inputs.city,
      state: inputs.state,
      country: country,
      invoice_logo: inputs.invoice_logo,
    };
    const result = await _update_invoice_settings(postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    get_Invoice_setting_data();
  }, []);

  if (isGetLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      <form onSubmit={handleSubmit}>
        <div className="row ">
          <div className="page-title-box">
            <h4>Invoice Settings</h4>
          </div>
          <div className="col-12 mt-4">
            <div className="row w-100 div-style ms-0 pt-0">
              <div className="col-lg-5">
                <p className="text-black">Invoice Logo</p>
                <FormHelperText className="pt-0 helper_text">
                  Recommended Size 250 X 100
                </FormHelperText>
              </div>
              <div className="col-lg-2">
                {isPicLoading === "invoice_logo" ? (
                  <CircularProgress size="1.5rem" color="primary" />
                ) : (
                  <img
                    src={
                      images.invoice_logo
                        ? images.invoice_logo
                        : inputs?.invoice_logo
                        ? s3BaseUrl + inputs?.invoice_logo
                        : ""
                    }
                    height="50"
                  />
                )}
              </div>
              <div className="col-lg-5 text-end pt-2">
                <label htmlFor="contained-button-file1">
                  <Input
                    accept="image/*"
                    id="contained-button-file1"
                    multiple
                    type="file"
                    name="invoice_logo"
                    onChange={fileChangedHandler}
                  />
                  <Button
                    className="btn btn-primary "
                    startIcon={<FileUploadIcon />}
                    component="span"
                  >
                    Upload
                  </Button>
                </label>
              </div>
            </div>
            {inputs.invoice_logo?.name === "" ? (
              ""
            ) : (
              <p className="text-secondary">{inputs.invoice_logo?.name}</p>
            )}
          </div>
          <div className="col-12 col-md-6 mt-3">
            <TextField
              id="outlined-basic"
              label="Invoice From"
              variant="outlined"
              fullWidth
              placeholder="Invoice From"
              name="invoice_from"
              value={inputs.invoice_from}
              onChange={handleChange}
            />
          </div>
          <div className="col-12 col-md-6 mt-3">
            <TextField
              id="outlined-basic"
              label="Dynamite Payments Email"
              variant="outlined"
              fullWidth
              placeholder="Dynamite Payments Email"
              name="dynamite_payments_email"
              value={inputs.dynamite_payments_email}
              onChange={handleChange}
              required
              type="email"
            />
          </div>
          <div className="col-12 col-md-6 mt-3">
            <TextField
              id="outlined-basic"
              label="Pay To Name"
              variant="outlined"
              fullWidth
              placeholder="Pay To Name"
              name="pay_to_name"
              value={inputs.pay_to_name}
              onChange={handleChange}
            />
          </div>
          <div className="col-12 col-md-6 mt-3">
            <TextField
              id="outlined-basic"
              label="Pay To Address"
              variant="outlined"
              fullWidth
              placeholder="Pay To Address"
              name="pay_to_address"
              value={inputs.pay_to_address}
              onChange={handleChange}
            />
          </div>
          <div className="col-12 col-md-6 mt-3">
            <TextField
              id="outlined-basic"
              label="City"
              variant="outlined"
              fullWidth
              placeholder="City"
              name="city"
              value={inputs.city}
              onChange={handleChange}
            />
          </div>
          <div className="col-12 col-md-6 mt-3">
            <TextField
              id="outlined-basic"
              label="State"
              variant="outlined"
              fullWidth
              placeholder="State"
              name="state"
              value={inputs.state}
              onChange={handleChange}
            />
          </div>
          <div className="col-12 col-md-6 mt-3">
            <Autocomplete
              id="country-select-demo"
              options={countries}
              autoHighlight
              value={country ? country : null}
              onChange={(event, newValue) => {
                setCountry(newValue);
              }}
              getOptionLabel={(option) => option.label}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  <img
                    loading="lazy"
                    width="20"
                    src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                    srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                    alt=""
                  />
                  {option.label} ({option.code})
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Choose a country"
                  required
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password",
                  }}
                />
              )}
            />
          </div>
          <div className="text-end mt-4">
            <button
              className="btn btn-primary mt-4 "
              disabled={isLoading || isPicLoading}
            >
              {isLoading ? "Updating..." : "Update"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default InvoiceSetting;
