import { invokeApi } from "./invokeApi";

export const _get_list_department_api = async () => {
  const requestObj = {
    path: `api/department/list_department`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _get_list_business_filter_api = async () => {
  const requestObj = {
    path: `api/admin/list_business`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _mark_resolve_support_ticket_api = async (id) => {
  const requestObj = {
    path: `api/support_ticket/mark_resolve_support_ticket/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _list_comment_with_support_ticket_api = async (id) => {
  const requestObj = {
    path: `api/support_ticket_comment/list_comment_with_support_ticket/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _list_support_tickets_for_admin_api = async (
  data,
  page,
  limit,
  search
) => {
  const requestObj = {
    path: `api/support_ticket/list_support_tickets_for_admin?text=${search}&page=${page}&limit=${limit}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _add_support_tickets_for_client_api = async (data) => {
  const requestObj = {
    path: `api/support_ticket/add_support_ticket`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _add_support_ticket_comment_api = async (data) => {
  const requestObj = {
    path: `api/support_ticket_comment/add_support_ticket_comment`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _upload_image_s3_api = async (data) => {
  const requestObj = {
    path: `api/app_api/upload_image_s3`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _add_internal_note_api = async (data) => {
  const requestObj = {
    path: `api/support_ticket/add_internal_notes`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _update_support_ticket_api = async (data, id) => {
  const requestObj = {
    path: `api/support_ticket/update_support_ticket/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _change_department_api = async (data) => {
  const requestObj = {
    path: `api/support_ticket/change_department`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _update_internal_note_api = async (data) => {
  const requestObj = {
    path: `api/support_ticket/update_internal_notes`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _update_support_ticket_comment_api = async (data, id) => {
  const requestObj = {
    path: `api/support_ticket_comment/update_support_ticket_comment/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _internal_note_list_api = async (id) => {
  const requestObj = {
    path: `api/support_ticket/list_internal_notes/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _delete_internal_note_api = async (ticketId, noteId) => {
  const requestObj = {
    path: `api/support_ticket/delete_internal_notes?support_ticket_id=${ticketId}&internal_note_id=${noteId}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _delete_support_ticket_api = async (id) => {
  const requestObj = {
    path: `api/support_ticket/delete_support_ticket/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _delete_support_ticket_comment_api = async (id) => {
  const requestObj = {
    path: `api/support_ticket_comment/delete_support_ticket_comment/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
