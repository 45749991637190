import { invokeApi } from "./invokeApi";

export const _update_notification_info_api = async (data) => {
  const requestObj = {
    path: `api/payment_request/update_notification_info`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _get_notification_info_api = async (id) => {
  const requestObj = {
    path: `api/payment_request/get_notification_info/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
