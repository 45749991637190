import React, { useEffect, useState } from "react";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import ClearAllOutlinedIcon from "@mui/icons-material/ClearAllOutlined";
import { Button } from "@mui/material";
import MUIAutocomplete from "../../../components/MUIAutocomplete";
import { _get_list_business_filter_api } from "../../../DAL/SupportTicket";

const TicketFilter = ({
  inputs,
  setInputs,
  handleSelectOther,
  handleSearch,
  handleClearFilter,
}) => {
  const [departmentList, setDepartmentList] = useState([]);
  const [clientList, setClientList] = useState([]);

  const get_department_list = async () => {
    const result = await _get_list_business_filter_api();
    if (result.code === 200) {
      setDepartmentList(
        result.department_list.map((item) => {
          return {
            ...item,
            chip_label: item.name,
            chip_value: item._id,
          };
        })
      );
      setClientList(
        result.business_list.map((item) => {
          let full_name = "N/A";
          if (item.first_name && item.last_name) {
            full_name = `${item.first_name} ${item.last_name} (${item.email})`;
          }
          return {
            ...item,
            full_name,
            chip_label: full_name,
            chip_value: item._id,
          };
        })
      );
    }
  };

  const handleSearchFilter = (e) => {
    e.preventDefault();
    handleSearch();
  };

  useEffect(() => {
    console.log("inputs  ___inputs", inputs);
  }, [inputs]);

  useEffect(() => {
    get_department_list();
  }, []);

  return (
    <div className="container">
      <form onSubmit={handleSearchFilter}>
        <div className="row mt-4">
          <div className="col-12">
            <MUIAutocomplete
              inputLabel="Departments"
              selectedOption={inputs.department_id}
              setSelectedOption={(e) => {
                handleSelectOther("department_id", e);
              }}
              optionsList={departmentList}
              name="name"
            />
          </div>
          <div className="col-12 mt-3">
            <MUIAutocomplete
              inputLabel="Business Client"
              selectedOption={inputs.user_id}
              setSelectedOption={(e) => {
                handleSelectOther("user_id", e);
              }}
              optionsList={clientList}
              name="full_name"
            />
          </div>
          <div className="d-flex justify-content-end gap-3 mt-4">
            <Button
              variant="contained"
              type="submit"
              startIcon={
                <FilterAltOutlinedIcon
                  style={{
                    fill: "#fff",
                  }}
                />
              }
            >
              Filter
            </Button>
            <Button
              onClick={handleClearFilter}
              variant="outlined"
              startIcon={<ClearAllOutlinedIcon />}
            >
              Clear All
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default TicketFilter;
