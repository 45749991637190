import React, { useEffect, useState } from "react";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import ClearAllOutlinedIcon from "@mui/icons-material/ClearAllOutlined";
import {
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { _payment_request_list_for_filter_transaction } from "../../DAL/Transaction/Transaction";
import moment from "moment";
import MultipleProductsSelectChip from "../../components/MultipleProductsSelectChip";

const SubscriptionFilter = ({
  inputs,
  setInputs,
  handleSelectOther,
  handleSearch,
  handleClearFilter,
  agreementCheckBox,
  setAgreementCheckBox,
  selectedStatus,
  setSelectedStatus,
  selectedTransactionMode,
  setSelectedTransactionMode,
}) => {
  const [paymentRequestList, setPaymentRequestList] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [isLoadingInvoices, setIsLoadingInvoices] = useState(false);
  const [search, setSearch] = useState("");
  const [searchUser, setSearchUser] = useState("");
  const maxDate = moment().format("YYYY-MM-DD");

  let statusObject = [
    {
      _id: "1",
      chip_value: "active",
      chip_label: "Active",
    },
    // {
    //   _id: "2",
    //   chip_value: "incomplete",
    //   chip_label: "Incomplete",
    // },
    // {
    //   _id: "3",
    //   chip_value: "canceled",
    //   chip_label: "Canceled",
    // },
    {
      _id: "4",
      chip_value: "expire",
      chip_label: "Expire",
    },
  ];

  let transactionModeObject = [
    {
      _id: "1",
      chip_value: "sandBox",
      chip_label: "SandBox",
    },
    {
      _id: "2",
      chip_value: "live",
      chip_label: "Live",
    },
  ];

  const handleInputChangeUser = (event, newInputValue) => {
    setSearchUser(newInputValue);
  };

  const handleInputChange = (event, newInputValue) => {
    setSearch(newInputValue);
  };

  const handleChangeForUser = (event, newValue) => {
    setInputs({
      ...inputs,
      userObj: newValue ? newValue : null,
      user_obj_id: newValue ? newValue?.user_id?._id : "",
    });
  };

  const handleChangeForInvoice = (event, newValue) => {
    setInputs({
      ...inputs,
      invoiceObj: newValue ? newValue : null,
      invoice_obj_id: newValue ? newValue?._id : "",
    });
  };

  const handleCheckboxChange = (event) => {
    setAgreementCheckBox(event.target.checked);
    setInputs({
      ...inputs,
      agreementCheckBox: event.target.checked,
    });
  };

  const handleChangeDate = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setInputs((values) => ({
      ...values,
      //   date_from: newDateFrom,
      [name]: value,
    }));
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name == "user_type") {
      setSearchUser("");
      setInputs((values) => ({
        ...values,
        userObj: null,
        user_obj_id: "",
        [name]: value,
      }));
    } else {
      setInputs((values) => ({ ...values, [name]: value }));
    }
  };

  const get_transaction_filter_data = async () => {
    let userType = inputs?.user_type;

    if (inputs?.user_type !== 1 && inputs?.user_type !== 2) {
      userType = 1;
    }

    let postData = {
      user_type: userType,
      search_invoice: search,
      search_user: searchUser,
    };

    const result = await _payment_request_list_for_filter_transaction(postData);
    if (result.code === 200) {
      setPaymentRequestList(result?.payment_request_list);
      setUsersList(result?.users_list);
    }
  };

  const handleSearchFilter = (e) => {
    e.preventDefault();
    handleSearch(e);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      get_transaction_filter_data();
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [search, searchUser, inputs?.user_type]);

  useEffect(() => {
    setInputs({
      ...inputs,
      selectedStatus: selectedStatus,
    });
  }, [selectedStatus]);

  useEffect(() => {
    setInputs({
      ...inputs,
      selectedTransactionMode: selectedTransactionMode,
    });
  }, [selectedTransactionMode]);

  return (
    <div className="container">
      <div className="row mt-4">
        <div className="col-12 ">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">User Type</InputLabel>
            <Select
              fullWidth
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="user_type"
              value={inputs.user_type}
              label="User Type"
              onChange={handleChange}
            >
              <MenuItem value={0}>All</MenuItem>
              <MenuItem value={1}>Customer</MenuItem>
              <MenuItem value={2}>Business User</MenuItem>
            </Select>
          </FormControl>
        </div>

        {inputs?.user_type === 1 || inputs?.user_type === 2 ? (
          <div className="col-12 mt-3">
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={usersList}
              getOptionLabel={(option) => {
                let fullName = `${option?.first_name || ""} ${
                  option?.last_name || ""
                }`;
                let email = option?.user_id?.email
                  ? `(${option.user_id.email})`
                  : "";
                return `${fullName} ${email}`.trim();
              }}
              value={inputs.userObj}
              onChange={handleChangeForUser}
              onInputChange={handleInputChangeUser}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search User"
                  placeholder="Search User by Name"
                />
              )}
            />
          </div>
        ) : null}

        <div className="col-12  mt-3">
          <>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={
                isLoadingInvoices
                  ? [
                      {
                        _id: "",
                        request_title: "Loading...",
                      },
                    ]
                  : paymentRequestList && paymentRequestList.length === 0
                  ? [
                      {
                        _id: "empty",
                        request_title: "No Invoice available",
                      },
                    ]
                  : paymentRequestList
              }
              getOptionLabel={(option) => option.request_title || ""}
              isOptionEqualToValue={(option, value) => option._id === value._id}
              value={inputs.invoiceObj}
              onChange={handleChangeForInvoice}
              onInputChange={handleInputChange}
              className="w-100 mui-select autocomplete-mui"
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search Invoice"
                  placeholder="Search Invoice by Title"
                />
              )}
            />
          </>
        </div>

        <div className="col-12 mt-3">
          <MultipleProductsSelectChip
            productsObject={transactionModeObject}
            selectedProducts={selectedTransactionMode}
            setSelectedProducts={setSelectedTransactionMode}
            _id="_id"
            name="chip_value"
            text="chip_label"
            label="Subscription Mode"
            placeholder="Add Mode"
          />
        </div>
        <div className="col-12 mt-3">
          <MultipleProductsSelectChip
            productsObject={statusObject}
            selectedProducts={selectedStatus}
            setSelectedProducts={setSelectedStatus}
            _id="_id"
            name="chip_value"
            text="chip_label"
            label="Subscription Status"
            placeholder="Add Status"
          />
        </div>

        <div className="col-12 mt-1">
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={agreementCheckBox}
                  onChange={handleCheckboxChange}
                />
              }
              label="Search By All Date"
            />
          </FormGroup>
        </div>
        {agreementCheckBox ? (
          <>
            <div className="col-12 mb-3">
              <TextField
                sx={{ width: "100%" }}
                label="From"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  max: inputs?.date_to ? inputs?.date_to : maxDate,
                }}
                name="date_from"
                value={inputs?.date_from}
                onChange={handleChangeDate}
              />
            </div>
            <div className="col-12 mb-3">
              <TextField
                sx={{ width: "100%" }}
                label="To"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  max: maxDate,
                }}
                name="date_to"
                value={inputs?.date_to}
                onChange={handleChangeDate}
              />
            </div>
          </>
        ) : null}

        <div className="d-flex justify-content-end gap-3 mt-1 mb-3">
          <Button
            variant="contained"
            // type="submit"
            onClick={handleSearch}
            startIcon={
              <FilterAltOutlinedIcon
                style={{
                  fill: "#fff",
                }}
              />
            }
          >
            Filter
          </Button>
          <Button
            onClick={handleClearFilter}
            variant="outlined"
            startIcon={<ClearAllOutlinedIcon />}
          >
            Clear All
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionFilter;
