import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: { xs: 350, sm: 450 },
  bgcolor: "background.paper",
  boxShadow: 24,
  padding: "16px 24px",
  borderRadius: 1,
};

export default function LogoutConfirmation({
  openLogoutModal,
  setOpenLogoutModal,
  handleLogout,
  selectedOption,
  setSelectedOption,
}) {
  const handleClose = () => {
    setOpenLogoutModal(false);
  };

  const handleChangeRadioButton = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <div>
      <Modal
        disableTypography="true"
        open={openLogoutModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="modal-theme">
          <Typography id="spring-modal-title" variant="h6" component="h2">
            Are you sure you want to logout?
          </Typography>

          <div className="mt-1">
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="logout-options"
                name="logout-options"
                value={selectedOption}
                onChange={handleChangeRadioButton}
              >
                <FormControlLabel
                  value="this"
                  control={<Radio />}
                  label="Logout from this device"
                />
                <FormControlLabel
                  value="all"
                  control={<Radio />}
                  label="Logout from all devices"
                />
              </RadioGroup>
            </FormControl>
          </div>

          {/* Buttons container */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: 1,
            }}
          >
            <Button
              onClick={handleClose}
              variant="outlined"
              sx={{ marginRight: 2 }}
            >
              Cancel
            </Button>

            <Button variant="contained" color="primary" onClick={handleLogout}>
              Agree
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
