//ENV based Variables
const config = require("./config.json");
const APP_ENV = process.env.REACT_APP_ENV;
const envConfig = config[APP_ENV];

//exporting Variables
export const baseUrl = envConfig.API_BASE_URL;
// export const baseUrl = "http://192.168.1.211:4000/";
export const baseApikey = envConfig.API_BASE_KEY;
export const s3BaseUrl = envConfig.PIC_BASE_URL;
export const projectMode = envConfig.Project_Mode;
export const Business_Portal_URL =
  "https://dynamitepaymentbusiness.dynamitelifestyle.com/";
