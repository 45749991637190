import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { Button, CircularProgress, Tooltip, Typography } from "@mui/material";
import CustomTable from "../../components/customTable/CustomTable";
import {
  TRANSACTION_STATUS,
  TRANSACTION_STATUS_COLOR,
  convertCurrencyToSign,
  show_proper_words,
} from "../../utils/constant";
import DeleteConfirmation from "../../components/DeleteConfirmation";
import { useNavigate } from "react-router-dom";
import {
  _delete_transaction_api,
  _list_transaction_api,
} from "../../DAL/Transaction/Transaction";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import { UserIcon } from "../../assets";
import CustomDrawer from "../../components/CustomDrawer";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import TransactionFilter from "./TransactionFilter";
import Iconify from "../../components/Iconify";
import ReactFilterChips from "react-filter-chips";
import {
  check_from_local_storage,
  get_from_localStorage,
  set_to_localStorage,
} from "../../utils/LocalStorage";
import { projectMode } from "../../config/config";
import FilteredChip from "../../components/FilteredChip";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const currentDate = moment();

let selected_transaction_mode = [];
let selected_status = [
  {
    _id: "1",
    chip_value: "succeded",
    chip_label: "Succeeded",
  },
];

if (projectMode == "dev") {
  selected_transaction_mode = [
    {
      _id: "1",
      chip_value: "sandBox",
      chip_label: "SandBox",
    },
  ];
}

if (projectMode == "prod") {
  selected_transaction_mode = [
    {
      _id: "2",
      chip_value: "live",
      chip_label: "Live",
    },
  ];
}
const EMPTY_FILTER = {
  user_type: 0,
  date_from: moment(currentDate).format("YYYY-MM-DD"),
  date_to: moment(currentDate).format("YYYY-MM-DD"),
  userObj: null,
  user_obj_id: "",
  invoiceObj: null,
  invoice_obj_id: "",
  transaction_platform: "all",
  transaction_made_by: "all",
  transaction_mode: "",
  transaction_status: "",
  agreementCheckBox: false,
  selectedStatus: selected_status,
  selectedTransactionMode: selected_transaction_mode,
};

const TransactionList = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [openDelete, setOpenDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [userList, setUserList] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [deleteId, setDeleteId] = useState("");
  const [delLoading, setDelLoading] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);

  const [searchText, setSearchText] = useState(
    check_from_local_storage("transaction_searchText")
      ? get_from_localStorage("transaction_searchText")
      : ""
  );

  const [agreementCheckBox, setAgreementCheckBox] = useState(
    check_from_local_storage("transaction_check_box")
      ? get_from_localStorage("transaction_check_box")
      : false
  );

  const [selectedStatus, setSelectedStatus] = useState(
    check_from_local_storage("transaction_status")
      ? get_from_localStorage("transaction_status")
      : selected_status
  );

  const [selectedTransactionMode, setSelectedTransactionMode] = useState(
    check_from_local_storage("transaction_mode")
      ? get_from_localStorage("transaction_mode")
      : selected_transaction_mode
  );

  const [inputs, setInputs] = useState(
    check_from_local_storage("transaction_inputs")
      ? get_from_localStorage("transaction_inputs")
      : EMPTY_FILTER
  );

  const [chipData, setChipData] = useState(EMPTY_FILTER);

  const [filterState, setFilterState] = useState(EMPTY_FILTER);

  const images = [
    UserIcon,
    UserIcon,
    UserIcon,
    UserIcon,
    UserIcon,
    UserIcon,
    UserIcon,
    UserIcon,
    UserIcon,
    UserIcon,
    UserIcon,
    UserIcon,
  ];

  if (!inputs.date_to) {
    setInputs((prevInputs) => ({
      ...prevInputs,
      date_from: moment(currentDate).format("YYYY-MM-DD"),
      date_to: moment(currentDate).format("YYYY-MM-DD"),
    }));
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const handleOpenFilterDrawer = () => {
    setFilterOpen(true);
  };

  const handleCloseFilterDrawer = () => {
    setFilterOpen(false);
  };

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const searchFunction = () => {
    setPage(0);
    setPageCount(1);
    get_list_transaction();
  };

  const processInputs = (inputs) => {
    let dateFrom = undefined;
    let transactionPlatform = undefined;
    let transactionMadeBy = undefined;

    if (inputs?.agreementCheckBox) {
      let startDate = moment(inputs?.date_from, "YYYY-MM-DD").format(
        "DD-MMM-YYYY"
      );
      let endDate = moment(inputs?.date_to, "YYYY-MM-DD").format("DD-MMM-YYYY");
      dateFrom = `Date From (${startDate}) To (${endDate})`;
    }

    if (
      inputs?.transaction_platform &&
      inputs?.transaction_platform !== "all" &&
      inputs?.transaction_platform !== ""
    ) {
      if (inputs?.transaction_platform === "fire") {
        transactionPlatform = "bank";
      } else {
        transactionPlatform = inputs?.transaction_platform;
      }
    }

    if (
      inputs?.transaction_made_by &&
      inputs?.transaction_made_by !== "all" &&
      inputs?.transaction_made_by !== ""
    ) {
      transactionMadeBy = inputs?.transaction_made_by;
    }

    let initialData = {
      transaction_platform: transactionPlatform,
      transaction_made_by: transactionMadeBy,

      selectedStatus: inputs?.selectedStatus?.map((status) => ({
        _id: status?._id,
        chip_value: status?.chip_value,
        chip_label: status?.chip_label,
      })),

      selectedTransactionMode: inputs?.selectedTransactionMode?.map((mode) => ({
        _id: mode?._id,
        chip_value: mode?.chip_value,
        chip_label: mode?.chip_label,
      })),

      userObj: inputs?.userObj
        ? {
            chip_value: inputs?.userObj?.user_id?._id,
            chip_label: `${inputs?.userObj?.first_name} ${inputs?.userObj?.last_name} (${inputs?.userObj?.user_id?.email})`,
          }
        : undefined,

      invoiceObj: inputs?.invoiceObj
        ? {
            chip_value: inputs?.invoiceObj?._id,
            chip_label: `${inputs?.invoiceObj?.request_title} `,
          }
        : undefined,

      dateFrom: dateFrom,
    };

    Object.keys(initialData).forEach(
      (key) => initialData[key] === undefined && delete initialData[key]
    );

    return initialData;
  };

  const get_list_transaction = async (data, clear = "") => {
    setIsLoading(true);

    let dataInput = data ? data : inputs;

    let statusArray = dataInput?.selectedStatus.map((item) => item.chip_value);
    let transactionModeArray = dataInput?.selectedTransactionMode.map(
      (item) => item.chip_value
    );

    let statusArrayClear = EMPTY_FILTER?.selectedStatus.map(
      (item) => item.chip_value
    );
    let transactionModeArrayClear = EMPTY_FILTER?.selectedTransactionMode.map(
      (item) => item.chip_value
    );

    let transactionMadeBy = dataInput?.transaction_made_by
      ? dataInput?.transaction_made_by
      : "";

    let transactionPlatform = dataInput?.transaction_platform
      ? dataInput?.transaction_platform
      : "";

    let initialData = processInputs(dataInput);

    console.log("dataInput  __initialData _handleDeleteChip", dataInput);
    console.log("initialData  __initialData _handleDeleteChip", initialData);

    let postData = {
      search: searchText,
      user_id: dataInput?.user_obj_id ? dataInput?.user_obj_id : "",
      start_date: dataInput?.date_from,
      end_date: dataInput?.date_to,
      invoice_id: dataInput?.invoice_obj_id ? dataInput?.invoice_obj_id : "",
      transaction_made_by: transactionMadeBy == "all" ? "" : transactionMadeBy,
      transaction_platform:
        transactionPlatform == "all" ? "" : transactionPlatform,
      transaction_mode:
        clear == "clear" ? transactionModeArrayClear : transactionModeArray,
      transaction_status: clear == "clear" ? statusArrayClear : statusArray,
    };

    if (!dataInput.agreementCheckBox) {
      delete postData?.start_date;
      delete postData?.end_date;
    }

    const result = await _list_transaction_api(page, rowsPerPage, postData);
    if (result.code === 200) {
      setInputs({ ...dataInput, dateFrom: initialData?.dateFrom });
      setFilterState(initialData);

      set_to_localStorage(
        "transaction_check_box",
        dataInput?.agreementCheckBox
      );

      set_to_localStorage("transaction_status", dataInput?.selectedStatus);
      set_to_localStorage(
        "transaction_mode",
        dataInput?.selectedTransactionMode
      );
      set_to_localStorage("transaction_inputs", dataInput);
      set_to_localStorage("transaction_searchText", searchText);

      // initialData

      const chip_data = { ...dataInput };
      setChipData(chip_data);

      setUserList(
        result.transaction.map((item) => {
          let created_At = "N/A";
          let member_name = "N/A";
          if (item.createdAt) {
            created_At = moment(item.createdAt).format("DD-MM-YYYY");
          }

          if (item.client_info) {
            let name = item.client_info;
            member_name =
              name.first_name + " " + name.last_name + " (" + name.email + ")";
          }
          return {
            ...item,
            created_At,
            member_name,
          };
        })
      );
      setTotalCount(result.count);
      setTotalPages(result.total_pages);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleDeleteChip = (data) => {
    let dataInput = { ...data };

    console.log("dataInput  __dataInput  _handleDeleteChip", dataInput);
    if (dataInput?.dateFrom == undefined) {
      dataInput.agreementCheckBox = false;
      dataInput.date_from = moment(currentDate).format("YYYY-MM-DD");
      dataInput.date_to = moment(currentDate).format("YYYY-MM-DD");

      setAgreementCheckBox(false);
    }

    if (!dataInput.userObj || !dataInput.userObj == null) {
      dataInput.user_obj_id = "";
    }

    if (!dataInput.invoiceObj || !dataInput.invoiceObj == null) {
      dataInput.invoice_obj_id = "";
    }

    setSelectedStatus(data?.selectedStatus ? data?.selectedStatus : []);
    setSelectedTransactionMode(
      data?.selectedTransactionMode ? data?.selectedTransactionMode : []
    );

    setPage(0);
    setPageCount(1);
    setInputs(dataInput);
    setChipData(dataInput);
    get_list_transaction(dataInput);
  };

  const handleClearFilter = () => {
    localStorage.removeItem("transaction_inputs");
    localStorage.removeItem("transaction_status");
    localStorage.removeItem("transaction_mode");
    localStorage.removeItem("transaction_check_box");
    setAgreementCheckBox(false);
    setSelectedStatus(selected_status);
    setSelectedTransactionMode(selected_transaction_mode);
    setPage(0);
    setPageCount(1);
    setChipData(EMPTY_FILTER);
    setInputs(EMPTY_FILTER);
    handleCloseFilterDrawer();
    get_list_transaction(EMPTY_FILTER, "clear");
  };

  const handleSelectOther = (name, value) => {
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setPage(0);
    setPageCount(1);
    handleCloseFilterDrawer();
    get_list_transaction(inputs);
  };

  const handleClickNavigate = () => {
    navigate(`/transactions/add-transaction`);
  };

  const handleEdit = (value) => {
    navigate(`/transactions/edit-transaction/${value._id}`);
  };

  const handleAgreeDelete = (value) => {
    setOpenDelete(true);
    setDeleteId(value._id);
  };

  const handleDelete = async () => {
    setDelLoading(true);
    const result = await _delete_transaction_api(deleteId);
    if (result.code === 200) {
      let user_list = userList.filter((user_id) => user_id._id !== deleteId);
      setUserList(user_list);
      setOpenDelete(false);
      setDelLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setDelLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const TABLE_HEAD = [
    { id: "action", label: "Action", type: "action" },
    { id: "number", label: "#", type: "number" },
    {
      id: "member_name",
      label: "Member",
      renderData: (row) => {
        let find_status = TRANSACTION_STATUS_COLOR.find(
          (status) => status?.value == row?.status
        );

        return (
          <span>
            <Tooltip title={find_status?.name} placement="bottom-start">
              <Typography variant="subtitle2" noWrap>
                <Iconify
                  icon="octicon:dot-fill-24"
                  sx={{
                    marginTop: "-1px",
                    width: "20px",
                    height: "20px",
                  }}
                  className={find_status?.class}
                />
                {row?.member_name}
              </Typography>
            </Tooltip>
          </span>
        );
      },
    },
    {
      id: "request_title",
      label: "Invoice",
      renderData: (row) => {
        let trans_info = row?.payment_request_info;
        return <span>{trans_info?.request_title}</span>;
      },
    },
    {
      id: "total_amount",
      label: "Amount",
      renderData: (row) => {
        let trans_info = row.payment_request_info;
        return (
          <span>{`${
            convertCurrencyToSign(trans_info?.currency) +
            row?.transaction_amount
          }`}</span>
        );
      },
    },
    {
      id: "dp_fee_amount",
      label: "DP Fee Amount",
      renderData: (row) => {
        let trans_info = row.payment_request_info;
        return (
          <>
            <span>{`${
              convertCurrencyToSign(trans_info?.currency) +
                row?.dp_payment_fee ?? 0
            }`}</span>
          </>
        );
      },
    },
    {
      id: "transaction_type",
      label: "Transaction Type",
      renderData: (row) => {
        let trans_info = row.payment_request_info;
        return <span>{show_proper_words(trans_info?.request_type)}</span>;
      },
    },
    {
      id: "payment_made_by",
      label: "Transaction Made By",
      renderData: (row) => {
        return <span>{show_proper_words(row.payment_made_by)}</span>;
      },
    },
    {
      id: "payment_mode",
      label: "Transaction Mode",
      renderData: (row) => {
        return <span>{show_proper_words(row.payment_mode)}</span>;
      },
    },
    {
      id: "payment_made_by_platform",
      label: "Transaction Platform",
      renderData: (row) => {
        return <span>{show_proper_words(row.payment_made_by_platform)}</span>;
      },
    },
    {
      id: "payment_made_in",
      label: "Payment Made In",
      renderData: (row) => {
        let fire_pay = row.fire_payment_info;
        return (
          <span>
            {fire_pay
              ? convertCurrencyToSign(fire_pay.currency) + fire_pay.amount
              : "N/A"}
          </span>
        );
      },
    },
    { id: "created_At", label: "Transaction Date" },
    {
      id: "status",
      label: "Status",
      renderData: (row) => {
        let find_status = TRANSACTION_STATUS.find(
          (status) => status.value == row.status
        );
        return (
          <span className={`${find_status?.class}`}>{find_status?.name}</span>
        );
      },
    },
  ];

  const MENU_OPTIONS = [
    // {
    //   label: "Edit",
    //   icon: "akar-icons:edit",
    //   handleClick: handleEdit,
    // },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  useEffect(() => {
    get_list_transaction();
  }, [page, rowsPerPage]);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <DeleteConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to remove this transaction ?"}
        handleAgree={handleDelete}
        isLoading={delLoading}
      />

      <div className="d-flex align-items-center justify-content-between flex-wrap">
        <div className="page-title-box me-2">
          <h4>Transaction</h4>
        </div>
        {/* <div className="d-flex align-items-center mt-1"> */}
        <div className="d-flex justify-content-end gap-3">
          <Button
            onClick={handleOpenFilterDrawer}
            variant="contained"
            startIcon={
              <FilterAltOutlinedIcon
                style={{
                  fill: "#fff",
                }}
              />
            }
          >
            Filter
          </Button>

          <Button
            onClick={handleClickNavigate}
            variant="contained"
            startIcon={
              <AddIcon
                style={{
                  fill: "#fff",
                }}
              />
            }
          >
            Add Transaction
          </Button>
        </div>
      </div>

      <div className="mt-2">
        <ReactFilterChips
          filterData={filterState}
          tempState={inputs}
          emptyFilter={EMPTY_FILTER}
          clearLabel="Clear All"
          filterLabel="Filter By"
          onDeleteChip={handleDeleteChip}
          onClear={handleClearFilter}
        />

        {/* <FilteredChip
          data={filterState}
          tempState={inputs}
          EMPTY_FILTER={EMPTY_FILTER}
          onDeleteChip={handleDeleteChip}
          onClear={handleClearFilter}
        /> */}

        {/* <FilteredTransactionChip
          data={chipData}
          onClear={handleClearFilter}
          tempState={inputs}
          onDeleteChip={handleDeleteChip}
          selectedStatus={selectedStatus}
          setSelectedStatus={setSelectedStatus}
          setAgreementCheckBox={setAgreementCheckBox}
          selectedTransactionMode={selectedTransactionMode}
          setSelectedTransactionMode={setSelectedTransactionMode}
        /> */}
      </div>

      <div className="mt-4">
        <CustomTable
          data={userList}
          TABLE_HEAD={TABLE_HEAD}
          MENU_OPTIONS={MENU_OPTIONS}
          custom_pagination={{
            total_count: totalCount,
            rows_per_page: rowsPerPage,
            page: page,
            handleChangePage: handleChangePage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          custom_search={{
            searchText: searchText,
            setSearchText: setSearchText,
            handleSubmit: searchFunction,
          }}
          pageCount={pageCount}
          totalPages={totalPages}
          handleChangePages={handleChangePages}
          pagePagination={true}
        />
      </div>

      <CustomDrawer
        isOpenDrawer={filterOpen}
        onOpenDrawer={handleOpenFilterDrawer}
        onCloseDrawer={handleCloseFilterDrawer}
        pageTitle="Filters"
        componentToPassDown={
          <>
            <TransactionFilter
              inputs={inputs}
              setInputs={setInputs}
              handleSearch={handleSearch}
              handleSelectOther={handleSelectOther}
              handleClearFilter={handleClearFilter}
              agreementCheckBox={agreementCheckBox}
              setAgreementCheckBox={setAgreementCheckBox}
              selectedStatus={selectedStatus}
              setSelectedStatus={setSelectedStatus}
              selectedTransactionMode={selectedTransactionMode}
              setSelectedTransactionMode={setSelectedTransactionMode}
            />
          </>
        }
      />
    </>
  );
};

export default TransactionList;
