import React, { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  FormHelperText,
  TextField,
} from "@mui/material";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { styled } from "@mui/material/styles";
import { s3BaseUrl } from "../../config/config";
import GeneralCkeditor from "../../components/GeneralCkeditor";
import { makeStyles } from "@mui/styles";
import {
  _update_business_client_settings_api,
  _get_business_client_settings_api,
  _upload_image_s3_api,
} from "../../DAL/Setting/Setting";
import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";

const Input = styled("input")({
  display: "none",
});

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const BusinessForgotPasswordSetting = () => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [isGetLoading, setIsGetLoading] = useState(true);
  const [isPicLoading, setIsPicLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [inputs, setInputs] = useState({
    email_label: "",
    remember_password_text: "",
    remember_password_link_text: "",
    forgot_password_text: "",
    code_welcome_text: "",
    reset_welcome_text: "",
    slider_img_1: "",
    slider_text_1: "",
    slider_text_2: "",
    slider_text_3: "",
    code_img_1: "",
    code_text_1: "",
    code_text_2: "",
    code_text_3: "",
    reset_img_1: "",
    reset_text_1: "",
    reset_text_2: "",
    reset_text_3: "",
  });

  const [images, setImages] = useState({
    slider_img_1: "",
    code_img_1: "",
    reset_img_1: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const fileChangedHandler = async (e) => {
    const { name, files } = e.target;
    if (files[0]) {
      setIsPicLoading(name);
      const formData = new FormData();
      formData.append("image", files[0]);
      const result = await _upload_image_s3_api(formData);
      if (result.code == 200) {
        setImages({ ...images, [name]: URL.createObjectURL(files[0]) });
        setInputs({ ...inputs, [name]: result.path });
        setIsPicLoading("");
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsPicLoading("");
      }
    }
  };

  const get_setting_data = async () => {
    setIsGetLoading(true);
    const result = await _get_business_client_settings_api();
    if (result.code == 200) {
      setInputs(
        result?.data?.business_client_settings?.reset_password_settings
      );
      setIsGetLoading(false);
    } else {
      setIsGetLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let reset_password_settings = {
      email_label: inputs.email_label,
      remember_password_text: inputs.remember_password_text,
      remember_password_link_text: inputs.remember_password_link_text,
      slider_img_1: inputs.slider_img_1,
      slider_text_1: inputs.slider_text_1,
      slider_text_2: inputs.slider_text_2,
      slider_text_3: inputs.slider_text_3,
      code_img_1: inputs.code_img_1,
      code_text_1: inputs.code_text_1,
      code_text_2: inputs.code_text_2,
      code_text_3: inputs.code_text_3,
      reset_img_1: inputs.reset_img_1,
      reset_text_1: inputs.reset_text_1,
      reset_text_2: inputs.reset_text_2,
      reset_text_3: inputs.reset_text_3,
      forgot_password_text: inputs.forgot_password_text,
      code_welcome_text: inputs.code_welcome_text,
      reset_welcome_text: inputs.reset_welcome_text,
    };

    let postData = {
      type: "reset_password_settings",
      reset_password_settings: reset_password_settings,
    };

    const result = await _update_business_client_settings_api(postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    get_setting_data();
  }, []);

  if (isGetLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      <form onSubmit={handleSubmit}>
        <div className="row ">
          <div className="page-title-box">
            <h4>Business Forgot Password Settings</h4>
          </div>
          <div className="col-12 col-md-6 mt-3">
            <TextField
              id="outlined-basic"
              label="Email Label"
              variant="outlined"
              fullWidth
              placeholder="Email Label"
              name="email_label"
              value={inputs.email_label}
              onChange={handleChange}
            />
          </div>
          <div className="col-12 col-md-6 mt-3">
            <TextField
              id="outlined-basic"
              label="Remember Password Text"
              variant="outlined"
              fullWidth
              placeholder="Remember Password Text"
              name="remember_password_text"
              value={inputs.remember_password_text}
              onChange={handleChange}
            />
          </div>
          <div className="col-12 col-md-6 mt-3">
            <TextField
              id="outlined-basic"
              label="Remember Password Link Text"
              variant="outlined"
              fullWidth
              placeholder="Remember Password Link Text"
              name="remember_password_link_text"
              value={inputs.remember_password_link_text}
              onChange={handleChange}
            />
          </div>
          <div className="col-12 mt-4">
            <div className="row w-100 div-style ms-0 pt-0">
              <div className="col-lg-5">
                <p className="text-black">Slide Background Image</p>
                <FormHelperText className="pt-0 helper_text">
                  Recommended Size 2000 X 1330
                </FormHelperText>
              </div>
              <div className="col-lg-2">
                {isPicLoading === "slider_img_1" ? (
                  <CircularProgress size="1.5rem" color="primary" />
                ) : (
                  <img
                    src={
                      images.slider_img_1
                        ? images.slider_img_1
                        : inputs?.slider_img_1
                        ? s3BaseUrl + inputs?.slider_img_1
                        : ""
                    }
                    height="50"
                  />
                )}
              </div>
              <div className="col-lg-5 text-end pt-2">
                <label htmlFor="contained-button-file">
                  <Input
                    accept="image/*"
                    id="contained-button-file"
                    multiple
                    type="file"
                    name="slider_img_1"
                    onChange={fileChangedHandler}
                  />

                  <Button
                    className="btn btn-primary "
                    startIcon={<FileUploadIcon />}
                    component="span"
                  >
                    Upload
                  </Button>
                </label>
              </div>
            </div>
            {inputs.slider_img_1?.name == "" ? (
              ""
            ) : (
              <p className="text-secondary">{inputs.slider_img_1?.name}</p>
            )}
          </div>
          <div className="col-12 mt-3">
            <h6 className="text-black">Forgot Password Welcome Text</h6>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="forgot_password_text"
              editorHeight={320}
            />
          </div>
          <div className="col-12 mt-3">
            <h6 className="text-black">Slide Text 1</h6>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="slider_text_1"
              editorHeight={320}
            />
          </div>
          <div className="col-12 mt-3">
            <h6 className="text-black">Slide Text 2</h6>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="slider_text_2"
              editorHeight={320}
            />
          </div>
          <div className="col-12 mt-3">
            <h6 className="text-black">Slide Text 3</h6>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="slider_text_3"
              editorHeight={320}
            />
          </div>
        </div>
        <div className="row mt-3">
          <div className="page-title-box">
            <h4>Code Verification Settings</h4>
          </div>
          <div className="col-12 mt-4">
            <div className="row w-100 div-style ms-0 pt-0">
              <div className="col-lg-5">
                <p className="text-black">Code Verification Background Image</p>
                <FormHelperText className="pt-0 helper_text">
                  Recommended Size 2000 X 1330
                </FormHelperText>
              </div>
              <div className="col-lg-2">
                {isPicLoading === "code_img_1" ? (
                  <CircularProgress size="1.5rem" color="primary" />
                ) : (
                  <img
                    src={
                      images.code_img_1
                        ? images.code_img_1
                        : inputs?.code_img_1
                        ? s3BaseUrl + inputs?.code_img_1
                        : ""
                    }
                    height="50"
                  />
                )}
              </div>
              <div className="col-lg-5 text-end pt-2">
                <label htmlFor="contained-button-file3">
                  <Input
                    accept="image/*"
                    id="contained-button-file3"
                    multiple
                    type="file"
                    name="code_img_1"
                    onChange={fileChangedHandler}
                  />

                  <Button
                    className="btn btn-primary "
                    startIcon={<FileUploadIcon />}
                    component="span"
                  >
                    Upload
                  </Button>
                </label>
              </div>
            </div>
            {inputs.code_img_1?.name == "" ? (
              ""
            ) : (
              <p className="text-secondary">{inputs.code_img_1?.name}</p>
            )}
          </div>
          <div className="col-12 mt-3">
            <h6 className="text-black">Code Verification Welcome Text</h6>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="code_welcome_text"
              editorHeight={320}
            />
          </div>
          <div className="col-12 mt-3">
            <h6 className="text-black">Code Verification Slide Text 1</h6>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="code_text_1"
              editorHeight={320}
            />
          </div>
          <div className="col-12 mt-3">
            <h6 className="text-black">Code Verification Slide Text 2</h6>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="code_text_2"
              editorHeight={320}
            />
          </div>
          <div className="col-12 mt-3">
            <h6 className="text-black">Code Verification Slide Text 3</h6>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="code_text_3"
              editorHeight={320}
            />
          </div>
        </div>
        <div className="row mt-3">
          <div className="page-title-box">
            <h4>Reset Passowrd Settings</h4>
          </div>
          <div className="col-12 mt-4">
            <div className="row w-100 div-style ms-0 pt-0">
              <div className="col-lg-5">
                <p className="text-black">Reset Passowrd Background Image </p>
                <FormHelperText className="pt-0 helper_text">
                  Recommended Size 2000 X 1330
                </FormHelperText>
              </div>
              <div className="col-lg-2">
                {isPicLoading === "reset_img_1" ? (
                  <CircularProgress size="1.5rem" color="primary" />
                ) : (
                  <img
                    src={
                      images.reset_img_1
                        ? images.reset_img_1
                        : inputs?.reset_img_1
                        ? s3BaseUrl + inputs?.reset_img_1
                        : ""
                    }
                    height="50"
                  />
                )}
              </div>
              <div className="col-lg-5 text-end pt-2">
                <label htmlFor="contained-button-file6">
                  <Input
                    accept="image/*"
                    id="contained-button-file6"
                    multiple
                    type="file"
                    name="reset_img_1"
                    onChange={fileChangedHandler}
                  />

                  <Button
                    className="btn btn-primary "
                    startIcon={<FileUploadIcon />}
                    component="span"
                  >
                    Upload
                  </Button>
                </label>
              </div>
            </div>
            {inputs.reset_img_1?.name == "" ? (
              ""
            ) : (
              <p className="text-secondary">{inputs.reset_img_1?.name}</p>
            )}
          </div>
          <div className="col-12 mt-3">
            <h6 className="text-black">Reset Password Welcome Text</h6>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="reset_welcome_text"
              editorHeight={320}
            />
          </div>
          <div className="col-12 mt-3">
            <h6 className="text-black">Reset Password Slide Text 1</h6>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="reset_text_1"
              editorHeight={320}
            />
          </div>
          <div className="col-12 mt-3">
            <h6 className="text-black">Reset Password Slide Text 2</h6>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="reset_text_2"
              editorHeight={320}
            />
          </div>
          <div className="col-12 mt-3">
            <h6 className="text-black">Reset Password Slide Text 3</h6>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="reset_text_3"
              editorHeight={320}
            />
          </div>
        </div>
        <div className="fixed_button mt-4">
          <button
            className="btn btn-primary mt-4 "
            disabled={isLoading || isPicLoading}
          >
            {isLoading ? "Updating..." : "Update"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default BusinessForgotPasswordSetting;
