import PropTypes from "prop-types";
import { Paper, Typography } from "@mui/material";

SearchNotFound.propTypes = {
  searchQuery: PropTypes.string,
};

export default function SearchNotFound({ searchQuery = "", ...other }) {
  return (
    <Paper {...other}>
      <div className="p-3">
        <Typography variant="h5" align="center">
          No Data Exists
        </Typography>
      </div>
    </Paper>
  );
}
