import React, { useEffect } from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import {
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import {
  _get_fire_settings_api,
  _update_fire_settings_api,
} from "../../DAL/Setting/Setting";
import { LoadingButton } from "@mui/lab";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function FireSetting() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingForm, setIsLoadingForm] = useState(true);

  const [inputs, setInputs] = useState({
    fire_refresh_token: "",
    fire_client_id: "",
    fire_account_no: "",
    fire_client_key: "",
    fire_euro_account_no: "",
    fire_payment_request_url: "https://api-preprod.fire.com/",
    fire_redirect_url: "https://payments-preprod.fire.com/",
    live_fire_refresh_token: "",
    live_fire_client_id: "",
    live_fire_account_no: "",
    live_fire_client_key: "",
    live_fire_euro_account_no: "",
    live_fire_payment_request_url: "https://api.fire.com/",
    live_fire_redirect_url: "https://payments.fire.com/",
    fire_mode: "sandBox",
    fire_payment_fee_percentage: "",
  });

  const get_fire_settings = async () => {
    setIsLoadingForm(true);
    const result = await _get_fire_settings_api();
    if (result.code == 200) {
      let new_obj = {
        ...result.setting?.website_setting,
        fire_payment_request_url: "https://api-preprod.fire.com/",
        fire_redirect_url: "https://payments-preprod.fire.com/",
        live_fire_payment_request_url: "https://api.fire.com/",
        live_fire_redirect_url: "https://payments.fire.com/",
      };
      setInputs(new_obj);
      setIsLoadingForm(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingForm(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let postData = {
      fire_refresh_token: inputs.fire_refresh_token,
      fire_client_id: inputs.fire_client_id,
      fire_account_no: inputs.fire_account_no,
      fire_client_key: inputs.fire_client_key,
      fire_euro_account_no: inputs.fire_euro_account_no,
      fire_payment_request_url: inputs.fire_payment_request_url,
      fire_redirect_url: inputs.fire_redirect_url,
      live_fire_refresh_token: inputs.live_fire_refresh_token,
      live_fire_client_id: inputs.live_fire_client_id,
      live_fire_account_no: inputs.live_fire_account_no,
      live_fire_client_key: inputs.live_fire_client_key,
      live_fire_euro_account_no: inputs.live_fire_euro_account_no,
      live_fire_payment_request_url: inputs.live_fire_payment_request_url,
      live_fire_redirect_url: inputs.live_fire_redirect_url,
      fire_mode: inputs.fire_mode,
      fire_payment_fee_percentage: inputs.fire_payment_fee_percentage,
    };

    const result = await _update_fire_settings_api(postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleChangefloat = (event) => {
    const inputValue = event.target.value;
    const isValidInput = /^\d*\.?\d*$/.test(inputValue);
    if (isValidInput || inputValue === "") {
      setInputs({
        ...inputs,
        fire_payment_fee_percentage: inputValue,
      });
    }
  };

  useEffect(() => {
    get_fire_settings();
  }, []);

  if (isLoadingForm == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-12">
            <div className="page-title-box mb-2">
              <h4>Fire Settings</h4>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">Fire Mode</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="fire_mode"
                value={inputs.fire_mode}
                label="Fire Mode"
                onChange={handleChange}
              >
                <MenuItem value="sandBox">SandBox</MenuItem>
                <MenuItem value="live">Live</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-12 col-md-6 mt-3">
            <TextField
              id="outlined-basic"
              label="Dynamite Fire Payment Fee Percentage"
              placeholder="Dynamite Fire Payment Fee Percentage"
              variant="outlined"
              fullWidth
              type="text"
              InputProps={{
                endAdornment: <span>%</span>,
                inputProps: { min: 0, max: 100 },
              }}
              name="fire_payment_fee_percentage"
              value={inputs.fire_payment_fee_percentage}
              onChange={handleChangefloat}
            />
          </div>
          <div className="page-title-box mt-3">
            <h4>Fire Settings for SandBox</h4>
          </div>
          <div className="col-12 col-md-6 mt-3">
            <TextField
              id="outlined-basic"
              label="Fire Client Key"
              variant="outlined"
              fullWidth
              placeholder="Fire Client Key"
              required
              name="fire_client_key"
              value={inputs.fire_client_key}
              onChange={handleChange}
            />
          </div>
          <div className="col-12 col-md-6 mt-3">
            <TextField
              id="outlined-basic"
              label="Fire Refresh Token"
              variant="outlined"
              fullWidth
              placeholder="Fire Refresh Token"
              required
              name="fire_refresh_token"
              value={inputs.fire_refresh_token}
              onChange={handleChange}
            />
          </div>
          <div className="col-12 col-md-6 mt-3">
            <TextField
              id="outlined-basic"
              label="Fire Client Id"
              placeholder="Fire Client Id"
              variant="outlined"
              fullWidth
              required
              name="fire_client_id"
              value={inputs.fire_client_id}
              onChange={handleChange}
            />
          </div>
          <div className="col-12 col-md-6 mt-3">
            <TextField
              id="outlined-basic"
              label="Sterling Account No(GBP)"
              placeholder="Sterling Account No(GBP)"
              variant="outlined"
              fullWidth
              required
              name="fire_account_no"
              value={inputs.fire_account_no}
              onChange={handleChange}
            />
          </div>
          <div className="col-12 col-md-6 mt-3">
            <TextField
              id="outlined-basic"
              label="Euro Account No (EUR)"
              placeholder="Euro Account No (EUR)"
              variant="outlined"
              fullWidth
              required
              name="fire_euro_account_no"
              value={inputs.fire_euro_account_no}
              onChange={handleChange}
            />
          </div>
          <div className="col-12 col-md-6 mt-3">
            <TextField
              id="outlined-basic"
              label="Fire Payment Request Url"
              placeholder="Fire Payment Request Url"
              variant="outlined"
              fullWidth
              type="url"
              required
              name="fire_payment_request_url"
              value={inputs.fire_payment_request_url}
              disabled
              onChange={handleChange}
            />
          </div>
          <div className="col-12 col-md-6 mt-3">
            <TextField
              id="outlined-basic"
              label="Fire Redirect Url"
              placeholder="Fire Redirect Url"
              variant="outlined"
              fullWidth
              required
              type="url"
              name="fire_redirect_url"
              disabled
              value={inputs.fire_redirect_url}
              onChange={handleChange}
            />
          </div>
          <div className="page-title-box mt-3">
            <h4>Fire Settings for Live</h4>
          </div>
          <div className="col-12 col-md-6 mt-3">
            <TextField
              id="outlined-basic"
              label="Fire Client Key"
              placeholder="Fire Client Key"
              variant="outlined"
              fullWidth
              required
              name="live_fire_client_key"
              value={inputs.live_fire_client_key}
              onChange={handleChange}
            />
          </div>
          <div className="col-12 col-md-6 mt-3">
            <TextField
              id="outlined-basic"
              label="Fire Refresh Token"
              placeholder="Fire Refresh Token"
              variant="outlined"
              fullWidth
              required
              name="live_fire_refresh_token"
              value={inputs.live_fire_refresh_token}
              onChange={handleChange}
            />
          </div>
          <div className="col-12 col-md-6 mt-3">
            <TextField
              id="outlined-basic"
              label="Fire Client Id"
              placeholder="Fire Client Id"
              variant="outlined"
              fullWidth
              required
              name="live_fire_client_id"
              value={inputs.live_fire_client_id}
              onChange={handleChange}
            />
          </div>
          <div className="col-12 col-md-6 mt-3">
            <TextField
              id="outlined-basic"
              label="Sterling Account No(GBP)"
              placeholder="Sterling Account No(GBP)"
              variant="outlined"
              fullWidth
              required
              name="live_fire_account_no"
              value={inputs.live_fire_account_no}
              onChange={handleChange}
            />
          </div>{" "}
          <div className="col-12 col-md-6 mt-3">
            <TextField
              id="outlined-basic"
              label="Euro Account No (EUR)"
              placeholder="Euro Account No (EUR)"
              variant="outlined"
              fullWidth
              required
              name="live_fire_euro_account_no"
              value={inputs.live_fire_euro_account_no}
              onChange={handleChange}
            />
          </div>
          <div className="col-12 col-md-6 mt-3">
            <TextField
              id="outlined-basic"
              label="Fire Payment Request Url"
              placeholder="Fire Payment Request Url"
              variant="outlined"
              fullWidth
              type="url"
              required
              disabled
              name="live_fire_payment_request_url"
              value={inputs.live_fire_payment_request_url}
              onChange={handleChange}
            />
          </div>
          <div className="col-12 col-md-6 mt-3">
            <TextField
              id="outlined-basic"
              label="Fire Redirect Url"
              placeholder="Fire Redirect Url"
              variant="outlined"
              fullWidth
              required
              type="url"
              name="live_fire_redirect_url"
              disabled
              value={inputs.live_fire_redirect_url}
              onChange={handleChange}
            />
          </div>
          <div className="text-end mt-3">
            <button className="btn btn-primary mt-4 " disabled={isLoading}>
              {isLoading ? "Updating..." : "Update"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
