import * as React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { useNavigate } from "react-router-dom";

export default function ActiveLastBreadcrumb({ breadCrumbMenu }) {
  const navigate = useNavigate();
  return (
    <div role="presentation">
      <Breadcrumbs aria-label="breadcrumb">
        {breadCrumbMenu.map((item, index) => {
          return (
            <Link
              key={index}
              underline="hover"
              onClick={() => navigate(item?.navigation, { state: item })}
            >
              <span
                className="pointer"
                style={{ color: item.active ? "#438a7a" : "#0f0f12" }}
              >
                {item?.title}
              </span>
            </Link>
          );
        })}
      </Breadcrumbs>
    </div>
  );
}
