import React, { useEffect, useState } from "react";
import ActiveLastBreadcrumb from "../../components/ActiveLastBreadcrumb";
import { useNavigate, useParams } from "react-router-dom";
import { UserIcon } from "../../assets";
import CustomTabs from "../../components/CustomTabs";
import { CircularProgress } from "@mui/material";
import CustomPopoverSection from "../../components/menuOption/CustomPopoverSection";
import {
  _delete_user_api,
  _detail_client_api,
  _member_login_api,
} from "../../DAL/User/User";
import Transaction from "./ProfileTabs/Transaction";
import { useSnackbar } from "notistack";
import Subscription from "./ProfileTabs/Subscription";
import { makeStyles } from "@mui/styles";
import Invoice from "./ProfileTabs/Invoice";
import Users from "./ProfileTabs/Users";
import moment from "moment";
import DeleteConfirmation from "../../components/DeleteConfirmation";
import { Business_Portal_URL, s3BaseUrl } from "../../config/config";
import { fShortenNumber } from "../../utils/constant";
import ChangePasswordUser from "../Users/ChangePasswordUser";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const status_object = ["invoice", "transection", "subscription", "customer"];
const status_invoice_obj = ["all", "paid", "pending", "canceled"];

const ClientProfile = () => {
  const classes = useStyles();
  const params = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [tabValue, setTabValue] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isTabLoading, setIsTabLoading] = useState(false);
  const [tabData, setTabData] = useState([]);
  const [userList, setUserList] = useState({});
  const [deleteId, setDeleteId] = useState("");
  const [userAmount, setUserAmount] = useState({});
  const [openDelete, setOpenDelete] = useState(false);
  const [delLoading, setDelLoading] = useState(false);
  const [tabInvoice, setTabInvoice] = useState(0);
  const [openPasswordModal, setopenPasswordModal] = useState(false);
  const [userId, setUserId] = useState("");

  const handleChange = (event, newValue) => {
    setIsTabLoading(true);
    setTabValue(newValue);
  };

  const get_profile_detail = async (type) => {
    setIsTabLoading(true);
    let tab_name = status_object[tabValue];
    let invoice_type = status_invoice_obj[tabInvoice];

    if (tab_name == "invoice") {
      tab_name = "";
    }
    let postData = {
      business_id: params.user_id,
      tab: tab_name,
      invoice_type: invoice_type,
    };
    const result = await _detail_client_api(postData);
    if (result.code == 200) {
      if (!tab_name || type) {
        setUserList(result.client?.business);
        setUserAmount(result.client);
      }
      setTabData(result.client?.tab_data);
      setIsLoading(false);
      setIsTabLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
      setIsTabLoading(false);
    }
  };

  const TABS_OPTIONS = [
    {
      title: "Invoices",
      component: (
        <Invoice
          tabData={tabData}
          isTabLoading={isTabLoading}
          tabInvoice={tabInvoice}
          setTabInvoice={setTabInvoice}
        />
      ),
    },
    {
      title: "Transactions",
      component: isTabLoading ? (
        <div style={{ minHeight: "300px" }}>
          <CircularProgress className={classes.loading} color="primary" />
        </div>
      ) : (
        <>
          <div style={{ minHeight: "300px" }}>
            <Transaction tabData={tabData} />
          </div>
        </>
      ),
    },
    {
      title: "Subscriptions",
      component: isTabLoading ? (
        <div style={{ minHeight: "300px" }}>
          <CircularProgress className={classes.loading} color="primary" />
        </div>
      ) : (
        <div style={{ minHeight: "300px" }}>
          <Subscription tabData={tabData} />
        </div>
      ),
    },
    {
      title: "Users",
      component: isTabLoading ? (
        <div style={{ minHeight: "300px" }}>
          <CircularProgress className={classes.loading} color="primary" />
        </div>
      ) : (
        <div style={{ minHeight: "300px" }}>
          <Users tabData={tabData} />
        </div>
      ),
    },
  ];

  let breadCrumbMenu = [
    {
      title: "Business Clients",
      navigation: -1,
      active: false,
    },
    {
      title:
        userList?.first_name +
        " " +
        userList?.last_name +
        " (" +
        userList?.email +
        ")",
      active: true,
    },
  ];

  const handleClickEdit = (row) => {
    navigate(`/busines-clients/edit-client/${row._id}`);
  };

  const handleAgreeDelete = (row) => {
    setDeleteId(row._id);
    setOpenDelete(true);
  };

  const handleDelete = async () => {
    setDelLoading(true);
    const result = await _delete_user_api(deleteId);
    if (result.code === 200) {
      navigate(`/busines-clients`);
      setOpenDelete(false);
      setDelLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setDelLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const LoginAsMember = async (value) => {
    setIsLoading(true);
    const data = {
      email: value.email,
      type: 2,
    };
    const result = await _member_login_api(data);
    if (result.code === 200) {
      window.open(`${Business_Portal_URL}login-by-admin/${result.token}`);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChangePassWord = (row) => {
    setopenPasswordModal(true);
    setUserId(row.user_id);
  };

  const handleChangeFireSetting = async (row) => {
    navigate(`/busines-clients/client-profile/fire-setting/${row.user_id}`);
  };

  const handleClickManageNavItemsAccess = (row) => {
    navigate(
      `/busines-clients/client-profile/manage-navitems-access/${row.user_id}`
    );
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleClickEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
    {
      label: "Login As Client",
      icon: "mdi:login",
      handleClick: LoginAsMember,
    },
    {
      label: "Fire Settings",
      icon: "mdi:payment-settings",
      handleClick: handleChangeFireSetting,
    },
    {
      label: "Change password",
      icon: "ant-design:lock",
      handleClick: handleChangePassWord,
    },
    {
      label: "Manage NavItems Access",
      icon: "uil:lock-access",
      handleClick: handleClickManageNavItemsAccess,
    },
  ];

  useEffect(() => {
    get_profile_detail();
  }, [tabValue, tabInvoice]);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <DeleteConfirmation
        open={openDelete}
        isLoading={delLoading}
        setOpen={setOpenDelete}
        title={"Are you sure you want to remove this client ?"}
        handleAgree={handleDelete}
      />
      <ChangePasswordUser
        openPasswordModal={openPasswordModal}
        setopenPasswordModal={setopenPasswordModal}
        userId={userId}
      />
      <div className="row user_profile">
        <div className="d-flex justify-content-between align-items-center flex-wrap ">
          <div className="page-title-box">
            <h4>Client Profile</h4>
          </div>
          <div>
            <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
          </div>
        </div>
        <div className="col-12 col-md-6 mt-3">
          <div className="card text-center profile-card">
            <div className="card-body">
              <h3>£{fShortenNumber(userAmount?.total_amount)}</h3>
              <h6 className="text-muted">Total Amount</h6>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6 mt-3">
          <div className="card text-center profile-card">
            <div className="card-body">
              <h3>£{fShortenNumber(userAmount?.total_dp_fee)}</h3>
              <h6 className="text-muted">Total DP Fee</h6>
            </div>
          </div>
        </div>
        <div className="col-12 mt-3 user-profile-detail">
          <div className="card">
            <div className="card-body">
              <div className="row client_profile">
                <div className="col-12 col-md-7 d-flex align-items-center">
                  <img
                    className="rounded-circle"
                    height="75px"
                    width="75px"
                    src={userList.image ? s3BaseUrl + userList.image : UserIcon}
                  />
                  <div className="ms-3">
                    <p className="m-0 fw-bold ">
                      {userList?.first_name + " " + userList?.last_name}
                    </p>
                    <div className="text-muted fw-14">{userList?.email}</div>
                  </div>
                </div>
                <div className="col-12 col-md-5 d-flex justify-content-end">
                  <div className="d-flex me-1 text-muted fw-14 m_top">
                    <div>Created At:&nbsp;</div>
                    {moment(userList?.createdAt).format("DD-MM-YYYY")}
                  </div>
                  <CustomPopoverSection menu={MENU_OPTIONS} data={userList} />
                </div>
              </div>
              <div className="row mt-4 client_profile">
                {userList?.srtripe_connect_account_id && (
                  <>
                    <div className="col-5 col-md-6 col-lg-3 mb-3">
                      <h6>Srtripe Connect Account:</h6>
                    </div>
                    <div className="col-7 col-md-6 col-lg-3 text_muted">
                      {userList?.srtripe_connect_account_id}
                    </div>
                  </>
                )}
                {userList?.fire_payee_id && (
                  <>
                    <div className="col-5 col-md-6 col-lg-3 mb-3">
                      <h6>Fire Payee Account :</h6>
                    </div>
                    <div className="col-7 col-md-6 col-lg-3 text_muted">
                      {userList?.fire_payee_id}
                    </div>
                  </>
                )}
                <div className="col-5 col-md-6 col-lg-3 mb-3">
                  <h6>Phone Number:</h6>
                </div>
                <div className="col-7 col-md-6 col-lg-3 text_muted">
                  {`+${userList?.contact_number}`}
                </div>
                <div className="col-5 col-md-6 col-lg-3 mb-3">
                  <h6>Status:</h6>
                </div>
                <div className="col-7 col-md-6 col-lg-3 text_muted">
                  {userList.status == true ? (
                    <span className="badge bg-success-subtle text-success p-2">
                      Active
                    </span>
                  ) : (
                    <span className="badge bg-danger-subtle text-danger p-2">
                      Disabled
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-3">
          <CustomTabs
            data={TABS_OPTIONS}
            value={tabValue}
            handleChange={handleChange}
          />
        </div>
      </div>
    </>
  );
};

export default ClientProfile;
