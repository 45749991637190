import { invokeApi } from "../invokeApi";

export const _add_taxes_api = async (data) => {
  const requestObj = {
    path: `api/taxes/add_taxes`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _list_taxes_api = async (search_text, page, limit) => {
  const requestObj = {
    path: `api/taxes/list_taxes?text=${search_text}&page=${page}&limit=${limit}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
