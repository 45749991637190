import React, { useState } from "react";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { useAdminContext } from "../Hooks/AdminContext";
import { s3BaseUrl } from "../config/config";

const ConfirmPassword = ({
  handleSubmitNewPassword,
  isLoading,
  errorMessage,
  handleAlertClose,
  setErrorMessage,
  handleMove,
}) => {
  const { adminSettingList } = useAdminContext();
  const [showPassword, setShowPassword] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const general_setting = adminSettingList?.general_settings;
  const reset_settings = adminSettingList?.reset_password_settings;

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!newPassword) {
      setErrorMessage("Please enter your password");
      handleMove();
      return;
    }
    if (!confirmPassword) {
      setErrorMessage("Please enter your confirm password");
      handleMove();
      return;
    }
    if (newPassword !== confirmPassword) {
      setErrorMessage("Password and Confirm Password does not match");
      return;
    }
    handleSubmitNewPassword(newPassword, confirmPassword);
  };

  return (
    <>
      <div className="account-pages">
        <div className="container">
          <div className="justify-content-center row">
            <div className="col-md-11">
              <div className="d-flex py-lg-2 py-5">
                <div className="w-100">
                  <div className="d-flex flex-column h-100 py-0 py-xl-4">
                    {general_setting.admin_logo && (
                      <div className="text-center mb-5">
                        <img
                          src={s3BaseUrl + general_setting?.admin_logo}
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                    )}
                    <div className="my-auto overflow-hidden card">
                      <div className="row g-0">
                        <div
                          className="col-lg-6  order-1 order-lg-0"
                          id="handle_move"
                        >
                          <div className="p-lg-5 p-4 card-body">
                            <div className="text-center">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: reset_settings?.reset_welcome_text,
                                }}
                              ></div>
                            </div>
                            <form onSubmit={handleSubmit}>
                              {errorMessage && (
                                <div className="col-12">
                                  <div
                                    className="alert alert_danger fade show d-flex justify-content-between align-items-center "
                                    role="alert"
                                  >
                                    {errorMessage}
                                    <div onClick={handleAlertClose}>
                                      <i className="fa-solid fa-xmark"></i>
                                    </div>
                                  </div>
                                </div>
                              )}
                              <div className="col-12 mt-4">
                                <FormControl fullWidth variant="outlined">
                                  <InputLabel htmlFor="outlined-adornment-password">
                                    Password *
                                  </InputLabel>
                                  <OutlinedInput
                                    id="outlined-adornment-password"
                                    type={showPassword ? "text" : "password"}
                                    placeholder="Password *"
                                    fullWidth
                                    onChange={(e) =>
                                      setNewPassword(e.target.value)
                                    }
                                    name="password"
                                    endAdornment={
                                      <InputAdornment position="end">
                                        <IconButton
                                          aria-label="toggle password visibility"
                                          onClick={handleClickShowPassword}
                                          edge="end"
                                        >
                                          {showPassword ? (
                                            <VisibilityOff
                                              style={{
                                                fontSize: "18px",
                                              }}
                                            />
                                          ) : (
                                            <Visibility
                                              style={{
                                                fontSize: "18px",
                                              }}
                                            />
                                          )}
                                        </IconButton>
                                      </InputAdornment>
                                    }
                                    label="Password"
                                  />
                                </FormControl>
                              </div>
                              <div className="col-12 mt-4">
                                <FormControl fullWidth variant="outlined">
                                  <InputLabel htmlFor="outlined-adornment-password">
                                    Confirm Password *
                                  </InputLabel>
                                  <OutlinedInput
                                    id="outlined-adornment-password"
                                    type={showPassword ? "text" : "password"}
                                    placeholder="Confirm Password *"
                                    fullWidth
                                    onChange={(e) =>
                                      setConfirmPassword(e.target.value)
                                    }
                                    name="confirm_password"
                                    endAdornment={
                                      <InputAdornment position="end">
                                        <IconButton
                                          aria-label="toggle password visibility"
                                          onClick={handleClickShowPassword}
                                          edge="end"
                                        >
                                          {showPassword ? (
                                            <VisibilityOff
                                              style={{
                                                fontSize: "18px",
                                              }}
                                            />
                                          ) : (
                                            <Visibility
                                              style={{
                                                fontSize: "18px",
                                              }}
                                            />
                                          )}
                                        </IconButton>
                                      </InputAdornment>
                                    }
                                    label="Confirm Password"
                                  />
                                </FormControl>
                              </div>
                              <div className="mt-4">
                                <div className="mt-2">
                                  <Button
                                    className="w-100"
                                    type="submit"
                                    variant="contained"
                                  >
                                    {isLoading ? "Loading..." : "Submit"}
                                  </Button>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                        <div
                          className="col-lg-6 overlay-container"
                          style={{
                            backgroundImage: `url(${
                              s3BaseUrl + reset_settings?.reset_img_1
                            })`,
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center center",
                          }}
                        >
                          <div className="div-img-overlay"></div>
                          {reset_settings?.reset_text_1 &&
                            reset_settings?.reset_text_2 &&
                            reset_settings?.reset_text_3 && (
                              <div
                                id="carouselExampleCaptions"
                                className="carousel slide h-100"
                                data-bs-ride="carousel"
                              >
                                <div className="carousel-indicators">
                                  <button
                                    type="button"
                                    data-bs-target="#carouselExampleCaptions"
                                    data-bs-slide-to={0}
                                    className="active"
                                    aria-current="true"
                                    aria-label="Slide 1"
                                  />
                                  <button
                                    type="button"
                                    data-bs-target="#carouselExampleCaptions"
                                    data-bs-slide-to={1}
                                    aria-label="Slide 2"
                                  />
                                  <button
                                    type="button"
                                    data-bs-target="#carouselExampleCaptions"
                                    data-bs-slide-to={2}
                                    aria-label="Slide 3"
                                  />
                                </div>
                                <div className="carousel-inner ">
                                  <div className="carousel-item active">
                                    <div className="text-center p-5">
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: reset_settings?.reset_text_1,
                                        }}
                                      ></div>
                                    </div>
                                  </div>
                                  <div className="carousel-item">
                                    <div className="text-center p-5">
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: reset_settings?.reset_text_2,
                                        }}
                                      ></div>
                                    </div>
                                  </div>
                                  <div className="carousel-item">
                                    <div className="text-center p-5 ">
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: reset_settings?.reset_text_3,
                                        }}
                                      ></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                    <div className="mt-5 text-center">
                      <p className="mb-0 text-muted">
                        {general_setting?.copyright_text}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmPassword;
