import React from "react";
import { Button, Dialog, DialogTitle } from "@mui/material";

function DeleteConfirmation({ open, isLoading, setOpen, handleAgree, title }) {
  const handleCloseDialog = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleCloseDialog}
        PaperProps={{
          style: {
            backgroundColor: "white",
            color: "black",
            boxShadow: "none",
          },
        }}
      >
        <DialogTitle>
          <div className="text-center p-4">
            <i className="fa-solid fa-trash display-5 text-danger"></i>
            <div className="mt-4">
              <h4>Are you sure ?</h4>
              <p className="text-muted mx-4 mb-0">
                {title ? title : "Are you sure you want to take this action?"}
              </p>
            </div>
            <div className="d-flex justify-content-center gap-3 mt-5">
              <Button variant="outlined" onClick={handleCloseDialog}>
                Close
              </Button>
              <Button
                variant="contained"
                onClick={handleAgree}
                style={{
                  backgroundColor: isLoading ? "gray" : "#dc3545",
                  color: "white",
                }}
              >
                {isLoading ? "Loading..." : "Yes, Delete It!"}
              </Button>
            </div>
          </div>
        </DialogTitle>
      </Dialog>
    </>
  );
}

export default DeleteConfirmation;
