import React, { useState } from "react";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from "@mui/material";
import {
  _set_token_in_localStorage,
  _set_user_in_localStorage,
} from "../local_storage/local_storage";
import { _admin_login_api } from "../DAL/Login/Login";
import { useSnackbar } from "notistack";
import { handleFormValidation } from "../validation/validation";
import { useAdminContext } from "../Hooks/AdminContext";
import { s3BaseUrl } from "../config/config";

const Login = () => {
  const navigate = useNavigate();
  const { setUserInfo, adminSettingList } = useAdminContext();
  const { enqueueSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const general_setting = adminSettingList?.general_settings;
  const login_settings = adminSettingList?.login_page_settings;

  const [inputs, setInputs] = useState({
    email: "",
    password: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((values) => ({ ...values, [name]: value }));
    setErrorMessage("");
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleValidationCheck = async (e, length) => {
    let errorMes = await handleFormValidation(e, length);
    if (errorMes) {
      setErrorMessage(errorMes);
      handleMove();
    }
  };

  const handleMove = () => {
    const handle_move = document.getElementById("handle_move");
    if (handle_move) {
      handle_move.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const handleAlertClose = () => {
    setErrorMessage("");
  };

  const handleSubmitLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let postData = {
      email: inputs.email,
      password: inputs.password,
      type: 0,
    };
    const result = await _admin_login_api(postData);
    if (result.code === 200) {
      setUserInfo(result.user);
      _set_token_in_localStorage(result.token);
      _set_user_in_localStorage(result.user);
      enqueueSnackbar(result.message, { variant: "success" });
      navigate(`/dashboard`, { replace: true });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="account-pages">
        <div className="container">
          <div className="justify-content-center row">
            <div className="col-md-11">
              <div className="d-flex py-lg-2 py-5">
                <div className="w-100">
                  <div className="d-flex flex-column h-100 py-0 py-xl-4">
                    {general_setting.admin_logo && (
                      <div className="text-center mb-5">
                        <img
                          src={s3BaseUrl + general_setting?.admin_logo}
                          className="img-fluid "
                        />
                      </div>
                    )}
                    <div className="my-auto overflow-hidden card">
                      <div className="row g-0">
                        <div
                          className="col-lg-6 order-1 order-lg-0"
                          id="handle_move"
                        >
                          <div className="p-lg-5 p-4 card-body">
                            <div className="text-center">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: login_settings?.welcome_text,
                                }}
                              ></div>
                            </div>
                            <form onSubmit={handleSubmitLogin}>
                              {errorMessage && (
                                <div className="col-12">
                                  <div
                                    className="alert alert_danger fade show d-flex justify-content-between align-items-center "
                                    role="alert"
                                  >
                                    {errorMessage}
                                    <div onClick={handleAlertClose}>
                                      <i className="fa-solid fa-xmark"></i>
                                    </div>
                                  </div>
                                </div>
                              )}
                              <div className="col-12 mt-4">
                                <TextField
                                  id="outlined-basic"
                                  label="Email"
                                  name="email"
                                  variant="outlined"
                                  fullWidth
                                  required
                                  placeholder="Email"
                                  value={inputs.email}
                                  onChange={handleChange}
                                  onBlur={(e) => {
                                    handleValidationCheck(e);
                                  }}
                                />
                              </div>
                              <div className="col-12 mt-3">
                                <FormControl fullWidth variant="outlined">
                                  <InputLabel htmlFor="outlined-adornment-password">
                                    Password *
                                  </InputLabel>
                                  <OutlinedInput
                                    id="outlined-adornment-password"
                                    type={showPassword ? "text" : "password"}
                                    placeholder="Password *"
                                    fullWidth
                                    required
                                    name="password"
                                    endAdornment={
                                      <InputAdornment position="end">
                                        <IconButton
                                          aria-label="toggle password visibility"
                                          onClick={handleClickShowPassword}
                                          onMouseDown={handleMouseDownPassword}
                                          edge="end"
                                        >
                                          {!showPassword ? (
                                            <VisibilityOff
                                              style={{
                                                fontSize: "18px",
                                              }}
                                            />
                                          ) : (
                                            <Visibility
                                              style={{
                                                fontSize: "18px",
                                              }}
                                            />
                                          )}
                                        </IconButton>
                                      </InputAdornment>
                                    }
                                    label="Password"
                                    value={inputs.password}
                                    onChange={handleChange}
                                  />
                                </FormControl>

                                {login_settings?.forgot_password_text && (
                                  <div className="position-relative">
                                    <div
                                      className="float-end pt-2 pointer"
                                      onClick={() =>
                                        navigate(`/forgot-password`)
                                      }
                                    >
                                      <a className="text-muted">
                                        {login_settings?.forgot_password_text}
                                      </a>
                                    </div>
                                  </div>
                                )}
                              </div>
                              <div className="mt-2">
                                <FormControlLabel
                                  control={<Checkbox defaultChecked />}
                                  label={login_settings?.check_box_text}
                                />
                                <div className="mt-2">
                                  <Button
                                    type="submit"
                                    variant="contained"
                                    className="w-100"
                                  >
                                    {isLoading ? "Loading..." : "Sign In"}
                                  </Button>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                        <div
                          className="col-lg-6 overlay-container"
                          style={{
                            backgroundImage: `url(${
                              s3BaseUrl + login_settings?.slider_bg_img
                            })`,
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center center",
                          }}
                        >
                          <div className="div-img-overlay"></div>
                          {login_settings?.slider_text_1 &&
                            login_settings?.slider_text_2 &&
                            login_settings?.slider_text_3 && (
                              <div
                                id="carouselExampleCaptions"
                                className="carousel slide h-100"
                                data-bs-ride="carousel"
                              >
                                <div className="carousel-indicators">
                                  <button
                                    type="button"
                                    data-bs-target="#carouselExampleCaptions"
                                    data-bs-slide-to={0}
                                    className="active"
                                    aria-current="true"
                                    aria-label="Slide 1"
                                  />
                                  <button
                                    type="button"
                                    data-bs-target="#carouselExampleCaptions"
                                    data-bs-slide-to={1}
                                    aria-label="Slide 2"
                                  />
                                  <button
                                    type="button"
                                    data-bs-target="#carouselExampleCaptions"
                                    data-bs-slide-to={2}
                                    aria-label="Slide 3"
                                  />
                                </div>
                                <div className="carousel-inner ">
                                  <div className="carousel-item active ">
                                    <div className="text-center p-5">
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: login_settings?.slider_text_1,
                                        }}
                                      ></div>
                                    </div>
                                  </div>
                                  <div className="carousel-item">
                                    <div className="text-center p-5">
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: login_settings?.slider_text_2,
                                        }}
                                      ></div>
                                    </div>
                                  </div>
                                  <div className="carousel-item">
                                    <div className="text-center p-5 ">
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: login_settings?.slider_text_3,
                                        }}
                                      ></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                    <div className="mt-5 text-center">
                      <p className="mb-0 text-muted">
                        {general_setting?.copyright_text}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
