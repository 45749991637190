import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { CustomPhoneInput } from "../../components/CustomPhoneInput";
import { useSnackbar } from "notistack";
import {
  _add_user_api,
  _bussiness_detail_for_admin_api,
  _edit_user_api,
} from "../../DAL/User/User";
import { s3BaseUrl } from "../../config/config";
import { isValidNumber } from "libphonenumber-js";
import ActiveLastBreadcrumb from "../../components/ActiveLastBreadcrumb";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { useNavigate, useParams } from "react-router-dom";
import { countries } from "../../utils/country";
import { makeStyles } from "@mui/styles";
import { validateEmail } from "../../utils/constant";
import CustomPasswordField from "../../components/CustomPasswordField";

const Input = styled("input")({
  display: "none",
});

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const AddOrUpdateClient = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { member_id } = useParams();
  const [file, setProfileImage] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("+44");
  const [isLoading, setIsLoading] = useState(false);
  const [isEditLoading, setIsEditLoading] = useState(true);
  const [country, setCountry] = useState({
    code: "GB",
    label: "United Kingdom",
  });
  const [inputs, setInputs] = useState({
    first_name: "",
    last_name: "",
    email: "",
    contact_number: "",
    password: "",
    image: {},
    oldimage: "",
    status: true,
    street: "",
    address: "",
    city: "",
    zip_code: "",
    state: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleChangefloat = (event) => {
    const inputValue = event.target.value;
    const isValidInput = /^\d*\.?\d*$/.test(inputValue);
    if (isValidInput || inputValue === "") {
      setInputs({
        ...inputs,
        fire_payment_fee_percenatage: inputValue,
      });
    }
  };

  const fileChangedHandler = (e) => {
    setProfileImage(URL.createObjectURL(e.target.files[0]));
    setInputs({
      ...inputs,
      ["image"]: e.target.files[0],
    });
  };

  const get_bussiness_detail = async () => {
    setIsEditLoading(true);
    const result = await _bussiness_detail_for_admin_api(member_id);
    if (result.code === 200) {
      setInputs((prevInputs) => ({
        ...prevInputs,
        ...result.business,
        oldimage: result.business?.image,
      }));
      let find_country = countries.find(
        (item) => item.code === result.business.country
      );
      if (find_country) {
        setCountry(find_country);
      }

      setPhoneNumber(result.business.contact_number);
      setIsEditLoading(false);
    }
    setIsEditLoading(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const isValid = isValidNumber(`+${inputs?.contact_number}`);
    if (!isValid) {
      return enqueueSnackbar("Please enter valid phone number", {
        variant: "error",
      });
    }

    if (!validateEmail(inputs?.email)) {
      return enqueueSnackbar("Please enter a valid email address", {
        variant: "error",
      });
    }

    setIsLoading(true);
    const formData = new FormData();
    formData.append("first_name", inputs.first_name);
    formData.append("last_name", inputs.last_name);
    formData.append("email", inputs.email);
    formData.append("contact_number", inputs.contact_number);
    formData.append("status", inputs.status);
    if (file) {
      formData.append("image", inputs.image);
    }
    if (!member_id) {
      formData.append("password", inputs.password);
    }
    formData.append("country", country ? country.code : "");
    formData.append("city", inputs.city);
    formData.append("state", inputs.state);
    formData.append("street", inputs.street);
    formData.append("address", inputs.address);
    formData.append("zip_code", inputs.zip_code);
    const result = member_id
      ? await _edit_user_api(formData, member_id)
      : await _add_user_api(formData);
    if (result.code === 200) {
      navigate(-1);
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  let breadCrumbMenu = [
    {
      title: "Business Clients",
      navigation: -1,
      active: false,
    },
    {
      title: member_id ? "Edit Client" : "Add Client",
      active: true,
    },
  ];

  useEffect(() => {
    if (member_id) {
      get_bussiness_detail();
    } else {
      setIsEditLoading(false);
    }
  }, [member_id]);

  if (isEditLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="row ">
        <div className="d-flex justify-content-between align-items-center flex-wrap mb-3">
          <div className="page-title-box">
            <h4>
              {member_id ? "Edit Business Client" : "Add Business Client"}
            </h4>
          </div>
          <div>
            <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
          </div>
        </div>
        <div className="col-12 col-md-6 mt-3">
          <TextField
            id="outlined-basic"
            label="First Name"
            variant="outlined"
            fullWidth
            required
            name="first_name"
            value={inputs.first_name}
            placeholder="First Name "
            onChange={handleChange}
          />
        </div>
        <div className="col-12 col-md-6 mt-3">
          <TextField
            id="outlined-basic"
            label="Last Name"
            variant="outlined"
            fullWidth
            required
            name="last_name"
            value={inputs.last_name}
            placeholder="Last Name "
            onChange={handleChange}
          />
        </div>
        <div className="col-12 col-md-6 mt-3">
          <TextField
            id="outlined-basic"
            label="Email"
            variant="outlined"
            required
            fullWidth
            name="email"
            value={inputs.email}
            placeholder="Email "
            onChange={handleChange}
          />
        </div>
        {!member_id && (
          <div className="col-12 col-md-6 mt-3">
            {/* <TextField
              id="outlined-basic"
              label="Password"
              variant="outlined"
              required
              fullWidth
              name="password"
              value={inputs.password}
              placeholder="Password "
              onChange={handleChange}
            /> */}
            <CustomPasswordField
              id="outlined-basic"
              label="Password"
              name="password"
              Required={true}
              onChange={handleChange}
              value={inputs.password}
            />
          </div>
        )}
        <div className="col-12 col-md-6 mt-3">
          <CustomPhoneInput
            required={true}
            inputState={inputs}
            setInputsState={setInputs}
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
          />
        </div>
        <div className="col-12 col-md-6 mt-3">
          <FormControl fullWidth required>
            <InputLabel id="demo-simple-select-label">Status</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={inputs.status}
              label="Status"
              name="status"
              required
              onChange={handleChange}
            >
              <MenuItem value={true}>Active</MenuItem>
              <MenuItem value={false}>Disable</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="col-12 mt-4">
          <div className="row w-100 div-style ms-0 pt-0">
            <div className="col-lg-5">
              <p className="text-black">Upload Image </p>
              <FormHelperText className="pt-0 helper_text">
                Image Size(1000 X 670) ("JPG", "JPEG", "PNG","WEBP")
              </FormHelperText>
            </div>
            <div className="col-lg-2">
              <img
                src={
                  file
                    ? file
                    : inputs.oldimage
                    ? s3BaseUrl + inputs.oldimage
                    : ""
                }
                height="50"
              />
            </div>
            <div className="col-lg-5 text-end pt-2">
              <label htmlFor="contained-button-file">
                <Input
                  accept="image/*"
                  id="contained-button-file"
                  multiple
                  type="file"
                  name="slider_bg_img"
                  onChange={fileChangedHandler}
                />

                <Button
                  className="btn btn-primary "
                  startIcon={<FileUploadIcon />}
                  component="span"
                >
                  Upload
                </Button>
              </label>
            </div>
          </div>
        </div>
        <div className="col-12 mt-3 fw-bold ">Address Details</div>
        <div className="col-12 col-md-6 mt-3">
          <Autocomplete
            id="country-select-demo"
            options={countries}
            autoHighlight
            value={country ? country : null}
            onChange={(event, newValue) => {
              setCountry(newValue);
            }}
            getOptionLabel={(option) => option.label}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                <img
                  loading="lazy"
                  width="20"
                  src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                  srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                  alt=""
                />
                {option.label} ({option.code})
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                required
                label="Choose a country"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password",
                }}
              />
            )}
          />
        </div>
        <div className="col-12 col-md-6 mt-3">
          <TextField
            id="outlined-basic6"
            label="City"
            placeholder="City"
            variant="outlined"
            fullWidth
            name="city"
            value={inputs.city}
            onChange={handleChange}
          />
        </div>
        <div className="col-12 col-md-6 mt-3">
          <TextField
            id="outlined-basic7"
            label="State"
            placeholder="State"
            variant="outlined"
            fullWidth
            name="state"
            value={inputs.state}
            onChange={handleChange}
          />
        </div>
        <div className="col-12 col-md-6 mt-3">
          <TextField
            id="outlined-basic8"
            label="Street Address 1"
            placeholder="Street Address 1"
            variant="outlined"
            fullWidth
            name="street"
            value={inputs.street}
            onChange={handleChange}
          />
        </div>
        <div className="col-12 col-md-6 mt-3">
          <TextField
            id="outlined-basic8"
            label="Street Address 2"
            placeholder="Street Address 2"
            variant="outlined"
            fullWidth
            name="address"
            value={inputs.address}
            onChange={handleChange}
          />
        </div>
        <div className="col-12 col-md-6 mt-3">
          <TextField
            id="outlined-basic10"
            label="Zip Code"
            placeholder="Zip Code"
            variant="outlined"
            fullWidth
            type="text"
            name="zip_code"
            value={inputs.zip_code}
            onChange={handleChange}
          />
        </div>
        <div className="d-flex justify-content-end gap-3 mt-4">
          <Button variant="contained" type="submit">
            {member_id
              ? `${isLoading ? "Loading.." : "Update"}`
              : `${isLoading ? "Loading.." : "Add Client"}`}
          </Button>
        </div>
      </div>
    </form>
  );
};

export default AddOrUpdateClient;
