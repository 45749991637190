import React, { useState, useEffect, useRef } from "react";
import { Button, CircularProgress, Avatar, IconButton } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import GeneralCkeditor from "../../../../components/GeneralCkeditor";
import CustomPopoverSection from "../../../../components/menuOption/CustomPopoverSection";
import DeleteConfirmation from "../../../../components/DeleteConfirmation";
import {
  _add_internal_note_api,
  _delete_internal_note_api,
  _internal_note_list_api,
  _update_internal_note_api,
} from "../../../../DAL/SupportTicket";
import RecordNotFound from "../../../../components/RecordNotFound";
import moment from "moment";
import { s3BaseUrl } from "../../../../config/config";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function InternalNote({
  ticketId,
  handleClosePopup,
  title = "",
}) {
  const ref = useRef(null);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [editValue, setEditValue] = useState("");
  const [isLoadingCard, setisLoadingCard] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [FormType, setFormType] = useState("add");
  const [isOpenForm, setIsOpenForm] = useState(false);
  const [delLoading, setDelLoading] = useState(false);
  const [resetData, setResetData] = useState(true);
  const [InternalNoteData, setInternalNoteData] = useState([]);
  const [inputs, setInputs] = useState({
    note: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setResetData(true);
    setIsLoading(true);
    const postData = {
      note: inputs.note,
    };
    if (FormType == "update") {
      postData.internal_note_id = editValue._id;
      postData.support_ticket_id = ticketId._id;
    } else if (FormType == "add") {
      postData.support_ticket_id = ticketId._id;
    }
    const result =
      FormType == "update"
        ? await _update_internal_note_api(postData)
        : await _add_internal_note_api(postData);
    if (result.code === 200) {
      setInputs({ note: "" });
      setFormType("add");
      setInternalNoteData(result.internal_notes);
      localStorage.setItem(
        "internal_notes_count",
        result.internal_notes.length
      );
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const getInternalNote = async () => {
    setisLoadingCard(true);
    const result = await _internal_note_list_api(ticketId._id);
    if (result.code === 200) {
      setInternalNoteData(result?.internal_notes_list);
      setisLoadingCard(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setisLoadingCard(false);
    }
  };

  const handleClose = () => {
    handleClosePopup();
  };

  const handleClick = () => {
    setResetData(true);
    setIsOpenForm(true);
    setTimeout(() => {
      ref.current?.scrollIntoView({ behavior: "smooth" });
    }, 100);
  };

  const handleEdit = (value) => {
    setResetData(true);
    setEditValue(value);
    setInputs({ note: value.note });
    setFormType("update");
    setIsOpenForm(true);
    setTimeout(() => {
      ref.current?.scrollIntoView({ behavior: "smooth" });
    }, 100);
  };
  const handleAgreeDelete = (value) => {
    setEditValue(value);
    setOpenDelete(true);
  };
  const handleCancelAdd = () => {
    setIsOpenForm(false);
    setInputs({ note: "" });
    setResetData(true);
  };
  const handleDelete = async () => {
    setDelLoading(true);
    const result = await _delete_internal_note_api(ticketId._id, editValue._id);
    if (result.code === 200) {
      let data_list = InternalNoteData.filter((id) => id._id !== editValue._id);
      setInternalNoteData(data_list);
      localStorage.setItem("internal_notes_count", data_list.length);

      if (data_list.length == 0) {
        setResetData(true);
        setIsOpenForm(false);
      }

      setOpenDelete(false);
      setDelLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setDelLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },

    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  useEffect(() => {
    getInternalNote();
  }, []);

  if (isLoadingCard === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container new-memories">
      <div className="row">
        <div className="d-flex justify-content-between align-items-center pt-3 ps-3 mb-3">
          <div className="d-flex align-items-center">
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
            <h5 className="anchor-style ms-2" style={{ marginTop: "11px" }}>
              {title ? title : ""}
            </h5>
          </div>
          <Button
            variant="contained"
            className="text-nowrap ms-2"
            onClick={handleClick}
          >
            Add Note
          </Button>
        </div>
      </div>

      <div className="row">
        {InternalNoteData?.length > 0 ? (
          <>
            {InternalNoteData.map((item, index) => {
              return (
                <div className="col-12 mb-2">
                  <div className="ticket_card">
                    <div className="d-flex justify-content-between ">
                      <div className="d-flex align-items-center ">
                        <Avatar
                          alt="Remy Sharp"
                          src={s3BaseUrl + item.user_info?.profile_image}
                        />
                        <div className="ms-2">
                          <span className="mb-0 fw-bold">
                            {item.user_info?.first_name +
                              " " +
                              item.user_info?.last_name}
                          </span>
                          {/* <p className="fw-14 mb-0">{item.user_info?.email}</p> */}
                        </div>
                      </div>
                      <div className="d-flex align-items-center ">
                        <span className="me-3">
                          <span className="fw-bold">Created at:</span>{" "}
                          {moment(item.note_date).format(
                            // "DD-MMMM-YYYY hh:mm A"
                            "DD-MM-YYYY hh:mm A"
                          )}
                        </span>
                        <CustomPopoverSection menu={MENU_OPTIONS} data={item} />
                      </div>
                    </div>
                    <div className="mt-3 ticket_card_img">
                      <div dangerouslySetInnerHTML={{ __html: item.note }} />
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        ) : (
          <RecordNotFound title=" Internal Notes " />
        )}
        {isOpenForm && (
          <form ref={ref} onSubmit={handleSubmit}>
            <div className="col-12 ">
              <span>Internal Note *</span>
              <GeneralCkeditor
                setInputs={setInputs}
                inputs={inputs}
                name="note"
                editorHeight={320}
                resetData={resetData}
                setResetData={setResetData}
              />
            </div>
            <div className="my-3">
              <div className="d-flex justify-content-end gap-3 mt-4">
                <Button variant="outlined" onClick={handleCancelAdd}>
                  Close
                </Button>
                <Button variant="contained" type="submit">
                  {isLoading
                    ? FormType === "update"
                      ? "Saving..."
                      : "Saving..."
                    : FormType === "ADD"
                    ? "Update"
                    : "Save"}
                </Button>
              </div>
            </div>
          </form>
        )}
      </div>
      <DeleteConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        isLoading={delLoading}
        title={"Are you sure you want to delete ?"}
        handleAgree={handleDelete}
      />
    </div>
  );
}
