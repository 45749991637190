import React, { useEffect, useState } from "react";
import { Button, CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { useNavigate, useParams } from "react-router-dom";
import Label from "../../../components/Label";
import TicketFilter from "./TicketFilter";
import CustomDrawer from "../../../components/CustomDrawer";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import CustomTabs from "../../../components/CustomTabs";
import AllTicket from "./TabsData/AllTicket";
import ChangeDepartment from "./ChangeDepartment";
import {
  _list_support_tickets_for_admin_api,
  _mark_resolve_support_ticket_api,
} from "../../../DAL/SupportTicket";
import FullPagePopupForTitle from "../../../components/FullPagePopupForTitle";
import InternalNote from "./TabsData/InternalNote";
import moment from "moment";
import CustomConfirmation from "../../../components/CustomConfirmation";
import FilteredChip from "../../../components/FilteredChip";
import ReactFilterChips from "react-filter-chips";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "15%",
    marginBottom: "15%",
  },
}));

let tab_value = ["all", "waiting", "answered", "closed", "trash"];

const Tickets = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { type } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [filterOpen, setFilterOpen] = useState(false);
  const [tabValue, setTabValue] = useState(1);
  const [changeDepartment, setChangeDepartment] = useState(false);
  const [Departmentdata, setDepartmentdata] = useState(null);
  const [ticketList, setTicketList] = useState([]);
  const [totalCountData, setTotalCountData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [drawerInternalNote, setdrawerInternalNote] = useState(false);
  const [isFirstLoading, setIsFirstLoading] = useState(true);
  const [internalId, setInternalId] = useState("");
  const [open, setOpen] = useState(false);
  const [markLoading, setMarkLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const EMPTY_FILTER = {
    department_id: null,
    user_id: null,
  };
  const [inputs, setInputs] = useState(EMPTY_FILTER);
  const [chipData, setChipData] = useState(EMPTY_FILTER);

  const handleChange = (event, newValue) => {
    localStorage.removeItem("support_tickets_filter");
    localStorage.removeItem("ticket_search_text");
    navigate(`/support-tickets/${tab_value[newValue]}`);
    setTabValue(newValue);
    setPage(0);
    setPageCount(1);
    setInputs(EMPTY_FILTER);
    setChipData(EMPTY_FILTER);
    setSearchText("");
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpenNoteDrawer = (value) => {
    setdrawerInternalNote(true);
    setInternalId(value);
  };

  const handleCloseNoteDrawer = () => {
    setdrawerInternalNote(false);
  };

  const handleClickMark = (value) => {
    setOpen(true);
    setInternalId(value._id);
  };

  const handleChangeDepartment = (value) => {
    console.log("value  __handleChangeDepartment", value);
    setChangeDepartment(true);
    setInternalId(value._id);
    setDepartmentdata(value);
  };
  const handleCloseChangeDepartment = () => {
    setChangeDepartment(false);
  };

  const get_support_tickets_list = async (value, filter_data) => {
    setIsLoading(true);
    let postData = {
      status: value,
      department_id: filter_data?.department_id?._id,
      user_id: filter_data?.user_id?.user_id,
    };

    console.log("inputs __get_support_tickets_list ", inputs);
    console.log("filter_data __get_support_tickets_list ", filter_data);
    console.log("postData __get_support_tickets_list ", postData);
    let search_keyword =
      localStorage.getItem("ticket_search_text") == null
        ? searchText
        : localStorage.getItem("ticket_search_text");
    if (search_keyword !== null) {
      setSearchText(search_keyword);
    }
    handleCloseFilterDrawer();
    const result = await _list_support_tickets_for_admin_api(
      postData,
      page,
      rowsPerPage,
      search_keyword
    );
    if (result.code === 200) {
      const chip_data = { ...filter_data };
      setChipData(chip_data);
      const data = result.support_tickets.map((item) => {
        return {
          ...item,
        };
      });
      setTicketList(data);
      console.log("data __get_support_tickets_list ", data);
      setTotalCountData(result);
      setTotalCount(result.total_count);
      setTotalPages(result.total_pages);
      setIsLoading(false);
      setIsFirstLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
      setIsFirstLoading(false);
    }
  };

  const handleSubmitMark = async () => {
    setMarkLoading(true);
    const result = await _mark_resolve_support_ticket_api(internalId);
    if (result.code === 200) {
      get_support_tickets_list(type ?? "waiting", inputs);
      setOpen(false);
      setMarkLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setMarkLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleOpenFilterDrawer = () => {
    setFilterOpen(true);
  };

  const handleCloseFilterDrawer = () => {
    setFilterOpen(false);
  };

  const handleClickDetail = (row) => {
    navigate(
      `/support-tickets/detail-ticket/${tab_value[tabValue]}/${row._id}`
    );
  };

  const handleSelectOther = (name, value) => {
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSearch = () => {
    setPage(0);
    setPageCount(1);
    get_support_tickets_list(type ?? "waiting", inputs);
    localStorage.setItem("support_tickets_filter", JSON.stringify(inputs));
  };

  const searchFunction = () => {
    setPage(0);
    setPageCount(1);
    get_support_tickets_list(type ?? "waiting", inputs);
  };

  const handleDeleteChip = (data) => {
    setPage(0);
    setPageCount(1);
    setInputs(data);
    setChipData(data);
    get_support_tickets_list(type ?? "waiting", data);
    localStorage.setItem("support_tickets_filter", JSON.stringify(data));
  };

  const handleClearFilter = () => {
    setPage(0);
    setPageCount(1);
    setChipData(EMPTY_FILTER);
    setInputs(EMPTY_FILTER);
    get_support_tickets_list(type ?? "waiting", EMPTY_FILTER);
    localStorage.removeItem("support_tickets_filter");
  };

  const handleMenu = (row) => {
    let MENU_OPTIONS = null;
    if (row.ticket_status === 1 || row.ticket_status == 2) {
      MENU_OPTIONS = [
        {
          label: "Detail",
          icon: "gg:details-more",
          handleClick: handleClickDetail,
        },
      ];
    } else {
      MENU_OPTIONS = [
        {
          label: "Detail",
          icon: "gg:details-more",
          handleClick: handleClickDetail,
        },
        {
          label: "Internal Notes",
          icon: "gg:details-more",
          handleClick: handleOpenNoteDrawer,
        },
        {
          label: "Mark Resolve",
          icon: "emojione-monotone:heavy-check-mark",
          handleClick: handleClickMark,
        },
        {
          label: "Change Department",
          icon: "fe:loop",
          handleClick: handleChangeDepartment,
        },
      ];
    }
    return MENU_OPTIONS;
  };

  const TABLE_HEAD = [
    {
      id: "action",
      label: "Action",
      type: "action",
      MENU_OPTIONS: "MENU_OPTIONS",
    },
    { id: "number", label: "#", type: "number" },
    {
      id: "user_name",
      label: "Client",
      renderData: (row) => {
        return (
          <>
            <span
              className="pointer"
              onClick={() => {
                handleClickDetail(row);
              }}
            >
              {row.user_name}
            </span>
          </>
        );
      },
    },
    { id: "subject", label: "Subject" },
    {
      id: "department",
      label: "Department",
      renderData: (row) => {
        return (
          <>
            <span>{row.department ? row.department?.name : "N/A"}</span>
          </>
        );
      },
    },
    {
      id: "createdAt",
      label: "Created",
      renderData: (row) => {
        return (
          <span>
            {row.support_ticket_date
              ? moment(row.support_ticket_date).fromNow()
              : "N/A"}
          </span>
        );
      },
    },
    {
      id: "responded",
      label: "Responded",
      renderData: (row) => {
        return (
          <span>
            {row.last_action_date
              ? moment(row.last_action_date).fromNow()
              : "N/A"}
          </span>
        );
      },
    },
    {
      id: "status",
      label: "Status",
      renderData: (row) => {
        let ticket_status = row?.ticket_status;
        let response_status = row?.response_status;
        return (
          <>
            <Label
              variant="ghost"
              className={
                ticket_status === 0 && response_status == 1
                  ? "answer-ticket"
                  : ticket_status == 1
                  ? "solved-ticket"
                  : ticket_status == 2
                  ? "trash-ticket"
                  : "pending-ticket"
              }
            >
              {ticket_status === 0 && response_status == 1
                ? "Answer"
                : ticket_status == 1
                ? " Solved"
                : ticket_status == 2
                ? "Trash"
                : "Waiting"}
            </Label>
          </>
        );
      },
    },
  ];

  const commonProps = {
    ticketList,
    TABLE_HEAD,
    totalCount,
    rowsPerPage,
    page,
    pageCount,
    totalPages,
    handleChangePage,
    handleChangeRowsPerPage,
    handleChangePages,
    handleMenu,
    searchText,
    setSearchText,
    searchFunction,
  };

  const TABS_OPTIONS = [
    {
      title: `All (${totalCountData.all_count ?? 0})`,
      component: isLoading ? (
        <div style={{ minHeight: "400px" }}>
          <CircularProgress className={classes.loading} color="primary" />
        </div>
      ) : (
        <div style={{ minHeight: "400px" }}>
          <AllTicket {...commonProps} />
        </div>
      ),
    },
    {
      title: `Waiting (${totalCountData.waiting_count ?? 0})`,
      component: isLoading ? (
        <div style={{ minHeight: "400px" }}>
          <CircularProgress className={classes.loading} color="primary" />
        </div>
      ) : (
        <div style={{ minHeight: "400px" }}>
          <AllTicket {...commonProps} />
        </div>
      ),
    },
    {
      title: `Answered (${totalCountData.answered_count ?? 0})`,
      component: isLoading ? (
        <div style={{ minHeight: "400px" }}>
          <CircularProgress className={classes.loading} color="primary" />
        </div>
      ) : (
        <div style={{ minHeight: "400px" }}>
          <AllTicket {...commonProps} />
        </div>
      ),
    },
    {
      title: `Close (${totalCountData.close_count ?? 0})`,
      component: isLoading ? (
        <div style={{ minHeight: "400px" }}>
          <CircularProgress className={classes.loading} color="primary" />
        </div>
      ) : (
        <div style={{ minHeight: "400px" }}>
          <AllTicket {...commonProps} />
        </div>
      ),
    },
    {
      title: `Trash (${totalCountData.trash_count ?? 0})`,
      component: isLoading ? (
        <div style={{ minHeight: "400px" }}>
          <CircularProgress className={classes.loading} color="primary" />
        </div>
      ) : (
        <div style={{ minHeight: "400px" }}>
          <AllTicket {...commonProps} />
        </div>
      ),
    },
  ];

  useEffect(() => {
    let tab_type = "waiting";
    if (type) {
      tab_type = type;
      let find_index = tab_value.findIndex((item) => item == type);
      setTabValue(find_index);
    } else {
      setTabValue(1);
    }
    let filter_data = EMPTY_FILTER;
    let postFilterData = inputs;
    let find_filter = localStorage.getItem("support_tickets_filter");
    if (find_filter) {
      filter_data = JSON.parse(find_filter);
      setInputs(filter_data);
      // postFilterData = filter_data;
    }

    console.log("postFilterData  __get_support_tickets_list", postFilterData);
    setChipData(filter_data);
    // get_support_tickets_list(tab_type, filter_data);
    get_support_tickets_list(tab_type, postFilterData);
  }, [type, rowsPerPage, page]);

  useEffect(() => {
    localStorage.setItem("ticket_search_text", searchText);
    if (searchText.length < 1) {
      localStorage.setItem("ticket_search_text", "");
    } else {
      localStorage.setItem("ticket_search_text", searchText);
    }
  }, [searchText]);

  if (isFirstLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="d-flex align-items-center justify-content-between flex-wrap ">
        <div className="page-title-box">
          <h4>Support Tickets</h4>
        </div>
        <div>
          <Button
            onClick={handleOpenFilterDrawer}
            variant="contained"
            startIcon={
              <FilterAltOutlinedIcon
                style={{
                  fill: "#fff",
                }}
              />
            }
          >
            Filter
          </Button>
        </div>
      </div>
      <div className="mt-2">
        {/* <FilteredChip
          data={chipData}
          tempState={inputs}
          EMPTY_FILTER={EMPTY_FILTER}
          onDeleteChip={handleDeleteChip}
          onClear={handleClearFilter}
        /> */}
        <ReactFilterChips
          filterData={chipData}
          tempState={inputs}
          emptyFilter={EMPTY_FILTER}
          clearLabel="Clear All"
          filterLabel="Filter By"
          onDeleteChip={handleDeleteChip}
          onClear={handleClearFilter}
        />
      </div>
      <div className="mt-3 card">
        <CustomTabs
          data={TABS_OPTIONS}
          value={tabValue}
          handleChange={handleChange}
        />
      </div>
      <CustomDrawer
        isOpenDrawer={filterOpen}
        onOpenDrawer={handleOpenFilterDrawer}
        onCloseDrawer={handleCloseFilterDrawer}
        pageTitle="Filters"
        componentToPassDown={
          <TicketFilter
            inputs={inputs}
            setInputs={setInputs}
            handleSearch={handleSearch}
            handleSelectOther={handleSelectOther}
            handleClearFilter={handleClearFilter}
          />
        }
      />
      <CustomDrawer
        isOpenDrawer={changeDepartment}
        onOpenDrawer={handleChangeDepartment}
        onCloseDrawer={handleCloseChangeDepartment}
        pageTitle="Change Department"
        componentToPassDown={
          <ChangeDepartment
            setTicketList={setTicketList}
            ticketId={internalId}
            onClose={handleCloseChangeDepartment}
            Departmentdata={Departmentdata}
          />
        }
      />
      <FullPagePopupForTitle
        open={drawerInternalNote}
        handleClosePopup={handleCloseNoteDrawer}
        title={`${internalId.user_name} | ${internalId.subject}`}
        disableTitle={true}
        componentToPassDown={
          <InternalNote
            handleClosePopup={handleCloseNoteDrawer}
            title={`${internalId.user_name} | ${internalId.subject}`}
            ticketId={internalId}
          />
        }
      />
      <CustomConfirmation
        open={open}
        setOpen={setOpen}
        loading={markLoading}
        title={"Are you sure you want to mark this ticket as resolved ?"}
        handleAgree={handleSubmitMark}
      />
    </>
  );
};

export default Tickets;
