import { invokeApi } from "../invokeApi";

export const _add_transaction_api = async (data) => {
  const requestObj = {
    path: `api/transaction/add_transaction`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _add_transaction_v1 = async (data) => {
  const requestObj = {
    path: `api/transaction/add_transaction_v1`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _payment_request_list_for_filter_transaction = async (data) => {
  const requestObj = {
    path: `api/payment_request/payment_request_list_for_filter_transaction`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _list_transaction_api = async (page, limit, data) => {
  const requestObj = {
    path: `api/transaction/list_transaction?page=${page}&limit=${limit}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _delete_transaction_api = async (id) => {
  const requestObj = {
    path: `api/transaction/delete_transaction/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
