import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  FormHelperText,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { CustomPhoneInput } from "../../components/CustomPhoneInput";
import { useSnackbar } from "notistack";
import {
  _add_customer_api,
  _detail_customer_for_admin_api,
  _edit_customer_by_admin_api,
} from "../../DAL/User/User";
import { isValidNumber } from "libphonenumber-js";
import { s3BaseUrl } from "../../config/config";
import ActiveLastBreadcrumb from "../../components/ActiveLastBreadcrumb";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { useNavigate, useParams } from "react-router-dom";
import { countries } from "../../utils/country";
import { makeStyles } from "@mui/styles";

const Input = styled("input")({
  display: "none",
});

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const AddOrUpdateUser = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { user_id } = useParams();
  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState("+44");
  const [file, setProfileImage] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isEditLoading, setIsEditLoading] = useState(true);
  const [country, setCountry] = useState({
    code: "GB",
    label: "United Kingdom",
  });
  const [inputs, setInputs] = useState({
    first_name: "",
    last_name: "",
    email: "",
    contact_number: "",
    image: "",
    oldimage: "",
    password: "",
    street: "",
    address: "",
    city: "",
    zip_code: "",
    state: "",
    vat_number: "",
    business_name: "",
    business_address: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const fileChangedHandler = (e) => {
    setProfileImage(URL.createObjectURL(e.target.files[0]));
    setInputs({
      ...inputs,
      ["image"]: e.target.files[0],
    });
  };

  const get_user_detail = async () => {
    setIsEditLoading(true);
    const result = await _detail_customer_for_admin_api(user_id);
    if (result.code === 200) {
      setInputs(() => ({
        ...result.customer,
        oldimage: result.customer?.profile_image,
        email: result.customer?.user_id?.email,
      }));
      let find_country = countries.find(
        (item) => item.code === result.customer.country
      );
      if (find_country) {
        setCountry(find_country);
      }
      setPhoneNumber(result.customer.contact_number);
      setIsEditLoading(false);
    }
    setIsEditLoading(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const isValid = isValidNumber(`+${inputs.contact_number}`);
    if (!isValid) {
      return enqueueSnackbar("Please enter valid phone number", {
        variant: "error",
      });
    }
    setIsLoading(true);
    const formData = new FormData();
    formData.append("first_name", inputs.first_name);
    formData.append("last_name", inputs.last_name);
    formData.append("email", inputs.email);
    formData.append("contact_number", inputs.contact_number);
    formData.append("country", country ? country.code : "");
    formData.append("city", inputs.city);
    formData.append("state", inputs.state);
    formData.append("street", inputs.street);
    formData.append("address", inputs.address);
    formData.append("zip_code", inputs.zip_code);
    formData.append("vat_number", inputs.vat_number);
    formData.append("business_name", inputs.business_name);
    formData.append("business_address", inputs.business_address);
    if (file) {
      formData.append("image", inputs.image);
    }
    if (!user_id) {
      formData.append("password", inputs.password);
    }
    const result = user_id
      ? await _edit_customer_by_admin_api(formData, user_id)
      : await _add_customer_api(formData);
    if (result.code === 200) {
      navigate(`/users`);
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
    setIsLoading(false);
  };

  let breadCrumbMenu = [
    {
      title: "Users",
      navigation: `/users`,
      active: false,
    },
    {
      title: user_id ? "Edit User" : "Add User",
      active: true,
    },
  ];

  useEffect(() => {
    if (user_id) {
      get_user_detail();
    } else {
      setIsEditLoading(false);
    }
  }, [user_id]);

  if (isEditLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="d-flex justify-content-between align-items-center flex-wrap ">
          <div className="page-title-box">
            <h4>{user_id ? "Edit User" : "Add User"}</h4>
          </div>
          <div>
            <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
          </div>
        </div>
        <div className="col-12 col-md-6 mt-3">
          <TextField
            id="outlined-basic"
            label="First Name"
            variant="outlined"
            fullWidth
            required
            name="first_name"
            value={inputs.first_name}
            placeholder="First Name *"
            onChange={handleChange}
          />
        </div>
        <div className="col-12 col-md-6 mt-3">
          <TextField
            id="outlined-basic"
            label="Last Name"
            variant="outlined"
            fullWidth
            required
            name="last_name"
            value={inputs.last_name}
            placeholder="Last Name *"
            onChange={handleChange}
          />
        </div>
        <div className="col-12 col-md-6 mt-3">
          <TextField
            id="outlined-basic"
            label="Email"
            variant="outlined"
            required
            fullWidth
            name="email"
            value={inputs.email}
            placeholder="Email *"
            onChange={handleChange}
          />
        </div>
        {!user_id && (
          <div className="col-12 col-md-6 mt-3">
            <TextField
              id="outlined-basic"
              label="Password"
              variant="outlined"
              required
              fullWidth
              name="password"
              value={inputs.password}
              placeholder="Password *"
              onChange={handleChange}
            />
          </div>
        )}
        <div className="col-12 col-md-6 mt-3">
          <CustomPhoneInput
            required={true}
            inputState={inputs}
            setInputsState={setInputs}
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
          />
        </div>
        <div className="col-12 col-md-6 mt-3">
          <TextField
            id="outlined-basic"
            label="Business Name"
            variant="outlined"
            fullWidth
            name="business_name"
            value={inputs.business_name}
            placeholder="Business Name"
            onChange={handleChange}
          />
        </div>
        <div className="col-12 col-md-6 mt-3">
          <TextField
            id="outlined-basic"
            label="Business Address"
            variant="outlined"
            fullWidth
            name="business_address"
            value={inputs.business_address}
            placeholder="Business Address"
            onChange={handleChange}
          />
        </div>
        <div className="col-12 mt-4">
          <div className="row w-100 div-style ms-0 pt-0">
            <div className="col-lg-5">
              <p className="text-black">Upload Image </p>
              <FormHelperText className="pt-0 helper_text">
                Image Size(1000 X 670) ("JPG", "JPEG", "PNG","WEBP")
              </FormHelperText>
            </div>
            <div className="col-lg-2">
              <img
                src={
                  file
                    ? file
                    : inputs.oldimage
                    ? s3BaseUrl + inputs.oldimage
                    : ""
                }
                height="50"
              />
            </div>
            <div className="col-lg-5 text-end pt-2">
              <label htmlFor="contained-button-file">
                <Input
                  accept="image/*"
                  id="contained-button-file"
                  multiple
                  type="file"
                  name="slider_bg_img"
                  onChange={fileChangedHandler}
                />

                <Button
                  className="btn btn-primary "
                  startIcon={<FileUploadIcon />}
                  component="span"
                >
                  Upload
                </Button>
              </label>
            </div>
          </div>
        </div>
        <div className="col-12 mt-3 fw-bold ">Address Details</div>
        <div className="col-12 col-md-6 mt-3">
          <Autocomplete
            id="country-select-demo"
            options={countries}
            autoHighlight
            value={country ? country : null}
            onChange={(event, newValue) => {
              setCountry(newValue);
            }}
            getOptionLabel={(option) => option.label}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                <img
                  loading="lazy"
                  width="20"
                  src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                  srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                  alt=""
                />
                {option.label} ({option.code})
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Choose a country"
                required
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password",
                }}
              />
            )}
          />
        </div>
        <div className="col-12 col-md-6 mt-3">
          <TextField
            id="outlined-basic6"
            label="City"
            placeholder="City"
            variant="outlined"
            fullWidth
            name="city"
            value={inputs.city}
            onChange={handleChange}
          />
        </div>
        <div className="col-12 col-md-6 mt-3">
          <TextField
            id="outlined-basic7"
            label="State"
            placeholder="State"
            variant="outlined"
            fullWidth
            name="state"
            value={inputs.state}
            onChange={handleChange}
          />
        </div>
        <div className="col-12 col-md-6 mt-3">
          <TextField
            id="outlined-basic8"
            label="Street Address 1"
            placeholder="Street Address 1"
            variant="outlined"
            fullWidth
            name="street"
            value={inputs.street}
            onChange={handleChange}
          />
        </div>
        <div className="col-12 col-md-6 mt-3">
          <TextField
            id="outlined-basic8"
            label="Street Address 2"
            placeholder="Street Address 2"
            variant="outlined"
            fullWidth
            name="address"
            value={inputs.address}
            onChange={handleChange}
          />
        </div>
        <div className="col-12 col-md-6 mt-3">
          <TextField
            id="outlined-basic10"
            label="Zip Code"
            placeholder="Zip Code"
            variant="outlined"
            fullWidth
            type="text"
            name="zip_code"
            value={inputs.zip_code}
            onChange={handleChange}
          />
        </div>
        <div className="col-12 col-md-6 mt-3">
          <TextField
            id="outlined-basic10"
            label="Vat Number"
            placeholder="Vat Number"
            variant="outlined"
            fullWidth
            type="text"
            name="vat_number"
            value={inputs.vat_number}
            onChange={handleChange}
          />
        </div>
        <div className="d-flex justify-content-end gap-3 mt-3">
          <Button type="submit" variant="contained">
            {user_id
              ? `${isLoading ? "Loading.." : " Update"}`
              : `${isLoading ? "Loading.." : "Add User"}`}
          </Button>
        </div>
      </div>
    </form>
  );
};

export default AddOrUpdateUser;
