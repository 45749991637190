import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import PinCodeForm from "./PinCodeForm";
import ConfrimPassword from "./ConfirmPassword";
import EmailForm from "./EmailForm";
import { useSnackbar } from "notistack";
import {
  _code_verification_api,
  _email_verification_api,
  _reset_password_api,
} from "../DAL/Login/Login";
import { handleFormValidation } from "../validation/validation";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [adminEmail, setAdminEmail] = useState("");
  const [formState, setFormState] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleValidationCheck = async (e, length) => {
    let errorMes = await handleFormValidation(e, length);
    if (errorMes) {
      setErrorMessage(errorMes);
      handleMove();
    }
  };

  const handleMove = () => {
    const handle_move = document.getElementById("handle_move");
    if (handle_move) {
      handle_move.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const handleAlertClose = () => {
    setErrorMessage("");
  };

  const handleSubmitEmail = async (_email) => {
    setIsLoading(true);
    setAdminEmail(_email);
    setErrorMessage("");
    let postData = {
      email: _email,
      type: 0,
    };
    const result = await _email_verification_api(postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setFormState(1);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleSubmitPinCode = async (pinCode) => {
    setIsLoading(true);
    setErrorMessage("");
    let postData = {
      email: adminEmail,
      verification_code: pinCode,
      type: 0,
    };
    const result = await _code_verification_api(postData);
    if (result.code === 200) {
      setFormState(2);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleSubmitNewPassword = async (newPassword, confirmPassword) => {
    setIsLoading(true);
    setErrorMessage("");
    let postData = {
      email: adminEmail,
      password: newPassword,
      confirm_password: confirmPassword,
      type: 0,
    };
    const result = await _reset_password_api(postData);
    if (result.code === 200) {
      setFormState(0);
      navigate("/login");
      setIsLoading(false);
      enqueueSnackbar("Password Changed Successfully", { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  return (
    <>
      {formState === 0 ? (
        <EmailForm
          handleSubmitEmail={handleSubmitEmail}
          handleValidationCheck={handleValidationCheck}
          errorMessage={errorMessage}
          handleAlertClose={handleAlertClose}
          setErrorMessage={setErrorMessage}
          handleMove={handleMove}
          isLoading={isLoading}
        />
      ) : formState === 1 ? (
        <PinCodeForm
          handleSubmitPinCode={handleSubmitPinCode}
          isLoading={isLoading}
          errorMessage={errorMessage}
          handleAlertClose={handleAlertClose}
          setErrorMessage={setErrorMessage}
          handleMove={handleMove}
        />
      ) : (
        <ConfrimPassword
          handleSubmitNewPassword={handleSubmitNewPassword}
          isLoading={isLoading}
          errorMessage={errorMessage}
          handleAlertClose={handleAlertClose}
          setErrorMessage={setErrorMessage}
          handleMove={handleMove}
        />
      )}
    </>
  );
};

export default ForgotPassword;
