import React, { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  FormHelperText,
  TextField,
} from "@mui/material";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { styled } from "@mui/material/styles";
import { s3BaseUrl } from "../../config/config";
import GeneralCkeditor from "../../components/GeneralCkeditor";
import { useSnackbar } from "notistack";
import {
  _get_business_client_settings_api,
  _update_business_client_settings_api,
  _upload_image_s3_api,
} from "../../DAL/Setting/Setting";
import { makeStyles } from "@mui/styles";
import { LoadingButton } from "@mui/lab";

const Input = styled("input")({
  display: "none",
});

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const BusinessLoginSetting = () => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [isGetLoading, setIsGetLoading] = useState(true);
  const [isPicLoading, setIsPicLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [inputs, setInputs] = useState({
    check_box_text: "",
    forgot_password_text: "",
    have_an_account_text: "",
    have_an_account_link_text: "",
    slider_bg_img: "",
    slider_text_1: "",
    slider_text_2: "",
    slider_text_3: "",
    welcome_text: "",
  });

  const [images, setImages] = useState({
    slider_bg_img: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const fileChangedHandler = async (e) => {
    const { name, files } = e.target;
    if (files[0]) {
      setIsPicLoading(name);
      const formData = new FormData();
      formData.append("image", files[0]);
      const result = await _upload_image_s3_api(formData);
      if (result.code == 200) {
        setImages({ ...images, [name]: URL.createObjectURL(files[0]) });
        setInputs({ ...inputs, [name]: result.path });
        setIsPicLoading("");
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsPicLoading("");
      }
    }
  };

  const get_setting_data = async () => {
    setIsGetLoading(true);
    const result = await _get_business_client_settings_api();
    if (result.code == 200) {
      setInputs(result?.data?.business_client_settings?.login_page_settings);
      setIsGetLoading(false);
    } else {
      setIsGetLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let login_page_settings = {
      check_box_text: inputs.check_box_text,
      forgot_password_text: inputs.forgot_password_text,
      have_an_account_text: inputs.have_an_account_text,
      have_an_account_link_text: inputs.have_an_account_link_text,
      slider_bg_img: inputs.slider_bg_img,
      slider_text_1: inputs.slider_text_1,
      slider_text_2: inputs.slider_text_2,
      slider_text_3: inputs.slider_text_3,
      welcome_text: inputs.welcome_text,
    };

    let postData = {
      type: "login_page_settings",
      login_page_settings: login_page_settings,
    };

    const result = await _update_business_client_settings_api(postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    get_setting_data();
  }, []);

  if (isGetLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      <form onSubmit={handleSubmit}>
        <div className="row ">
          <div className="page-title-box">
            <h4>Business Login Settings</h4>
          </div>
          <div className="col-12 col-md-6 mt-3">
            <TextField
              id="outlined-basic"
              label="Check Box Text"
              variant="outlined"
              fullWidth
              placeholder="Check Box Text"
              name="check_box_text"
              value={inputs.check_box_text}
              onChange={handleChange}
            />
          </div>
          <div className="col-12 col-md-6 mt-3">
            <TextField
              id="outlined-basic"
              label="Forgot Password Text"
              variant="outlined"
              fullWidth
              placeholder="Forgot Password Text"
              name="forgot_password_text"
              value={inputs.forgot_password_text}
              onChange={handleChange}
            />
          </div>
          <div className="col-12 col-md-6 mt-3">
            <TextField
              id="outlined-basic"
              label="Have An Account Text"
              variant="outlined"
              fullWidth
              placeholder="Have An Account Text"
              name="have_an_account_text"
              value={inputs.have_an_account_text}
              onChange={handleChange}
            />
          </div>
          <div className="col-12 col-md-6 mt-3">
            <TextField
              id="outlined-basic"
              label="Have An Account Link Text"
              variant="outlined"
              fullWidth
              placeholder="Have An Account Link Text"
              name="have_an_account_link_text"
              value={inputs.have_an_account_link_text}
              onChange={handleChange}
            />
          </div>
          <div className="col-12 mt-4">
            <div className="row w-100 div-style ms-0 pt-0">
              <div className="col-lg-5">
                <p className="text-black">Slide Background Image</p>
                <FormHelperText className="pt-0 helper_text">
                  Recommended Size 2000 X 1330
                </FormHelperText>
              </div>
              <div className="col-lg-2">
                {isPicLoading === "slider_bg_img" ? (
                  <CircularProgress size="1.5rem" color="primary" />
                ) : (
                  <img
                    src={
                      images.slider_bg_img
                        ? images.slider_bg_img
                        : inputs?.slider_bg_img
                        ? s3BaseUrl + inputs?.slider_bg_img
                        : ""
                    }
                    height="50"
                  />
                )}
              </div>
              <div className="col-lg-5 text-end pt-2">
                <label htmlFor="contained-button-file">
                  <Input
                    accept="image/*"
                    id="contained-button-file"
                    multiple
                    type="file"
                    name="slider_bg_img"
                    onChange={fileChangedHandler}
                  />
                  <Button
                    className="btn btn-primary "
                    startIcon={<FileUploadIcon />}
                    component="span"
                  >
                    Upload
                  </Button>
                </label>
              </div>
            </div>
            {inputs.slider_img_1?.name == "" ? (
              ""
            ) : (
              <p className="text-secondary">{inputs.slider_img_1?.name}</p>
            )}
          </div>
          <div className="col-12 mt-3">
            <h6 className="text-black">Welcome Text</h6>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="welcome_text"
              editorHeight={320}
            />
          </div>
          <div className="col-12 mt-3">
            <h6 className="text-black">Slide Text 1</h6>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="slider_text_1"
              editorHeight={320}
            />
          </div>
          <div className="col-12 mt-3">
            <h6 className="text-black">Slide Text 2</h6>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="slider_text_2"
              editorHeight={320}
            />
          </div>
          <div className="col-12 mt-3">
            <h6 className="text-black">Slide Text 3</h6>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="slider_text_3"
              editorHeight={320}
            />
          </div>
        </div>
        <div className="fixed_button mt-4">
          <button
            className="btn btn-primary mt-4 "
            disabled={isLoading || isPicLoading}
          >
            {isLoading ? "Updating..." : "Update"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default BusinessLoginSetting;
