import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { Avatar, Button, CircularProgress, Tooltip } from "@mui/material";
import CustomTable from "../../components/customTable/CustomTable";
import { PAYMENT_STATUS } from "../../utils/constant";
import DeleteConfirmation from "../../components/DeleteConfirmation";
import {
  _delete_user_api,
  _member_login_api,
  _user_list_api,
} from "../../DAL/User/User";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import { Business_Portal_URL, s3BaseUrl } from "../../config/config";
import { UserIcon } from "../../assets";
import { useNavigate } from "react-router-dom";
import ChangePasswordUser from "../Users/ChangePasswordUser";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const BusinessClients = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [openDelete, setOpenDelete] = useState(false);
  const [delLoading, setDelLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [userList, setUserList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [openPasswordModal, setopenPasswordModal] = useState(false);
  const [userId, setUserId] = useState("");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleProfile = (row) => {
    navigate(`/busines-clients/client-profile/${row._id}`);
  };

  const handleClickOpen = () => {
    navigate(`/busines-clients/add-client`);
  };

  const handleClickEdit = (row) => {
    navigate(`/busines-clients/edit-client/${row._id}`);
  };

  const handleAgreeDelete = (row) => {
    setOpenDelete(true);
    setDeleteId(row._id);
  };

  const handleClickManageNavItemsAccess = (row) => {
    navigate(`/busines-clients/manage-navitems-access/${row.user_id}`);
  };

  const get_user_list = async () => {
    setIsLoading(true);
    let search_keyword =
      localStorage.getItem("client_search_text") == null
        ? searchText
        : localStorage.getItem("client_search_text");
    if (search_keyword !== null) {
      setSearchText(search_keyword);
    }
    const result = await _user_list_api(search_keyword, page, rowsPerPage);
    if (result.code === 200) {
      let data = result.business.business_list.map((item) => {
        let full_name = "N/A";
        let registrationDate = "N/A";
        if (item.first_name) {
          full_name = item.first_name + " " + item.last_name;
        }

        if (item.createdAt) {
          registrationDate = moment(item.createdAt).format("DD-MM-YYYY");
        }
        return {
          ...item,
          full_name,
          registrationDate,
        };
      });
      setUserList(data);
      setTotalCount(result.business.count);
      setTotalPages(result.business.total_pages);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChangePassWord = (row) => {
    setopenPasswordModal(true);
    setUserId(row.user_id);
  };

  const searchFunction = () => {
    setPage(0);
    setPageCount(1);
    get_user_list();
  };

  const handleDelete = async () => {
    setDelLoading(true);
    const result = await _delete_user_api(deleteId);
    if (result.code === 200) {
      let user_list = userList.filter((user_id) => user_id._id !== deleteId);
      setUserList(user_list);
      setOpenDelete(false);
      setDelLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setDelLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const TABLE_HEAD = [
    { id: "action", label: "Action", type: "action" },
    { id: "number", label: "#", type: "number" },
    {
      id: "full_name",
      label: "Name",
      renderData: (row) => {
        return (
          <>
            <div
              className="d-flex align-items-center pointer"
              onClick={() => {
                handleProfile(row);
              }}
            >
              <div className="me-3 table_avatar">
                <Avatar src={row?.image ? s3BaseUrl + row?.image : UserIcon} />
              </div>
              <span>{row.full_name}</span>
            </div>
          </>
        );
      },
    },
    { id: "email", label: "Email" },
    {
      id: "contact_number",
      label: "Phone Number",
      renderData: (row) => {
        return <span>{`+${row.contact_number}`}</span>;
      },
    },
    { id: "registrationDate", label: "Registration date" },
    {
      id: "status",
      label: "Status",
      renderData: (row) => {
        let find_status = PAYMENT_STATUS.find(
          (status) => status.value == row.status
        );
        return (
          <>
            <span className={`${find_status?.class}`}>{find_status?.name}</span>
          </>
        );
      },
    },
  ];

  const LoginAsMember = async (value) => {
    setIsLoading(true);
    const data = {
      email: value.email,
      type: 2,
    };
    const result = await _member_login_api(data);
    if (result.code === 200) {
      window.open(`${Business_Portal_URL}login-by-admin/${result.token}`);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChangeFireSetting = async (row) => {
    navigate(`/busines-clients/fire-setting/${row.user_id}`);
  };

  useEffect(() => {
    get_user_list();
  }, [rowsPerPage, page]);

  useEffect(() => {
    localStorage.setItem("client_search_text", searchText);
    if (searchText.length < 1) {
      localStorage.setItem("client_search_text", "");
    } else {
      localStorage.setItem("client_search_text", searchText);
    }
  }, [searchText]);

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleClickEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
    {
      label: "Login As Client",
      icon: "mdi:login",
      handleClick: LoginAsMember,
    },
    {
      label: "View Profile",
      icon: "iconamoon:profile",
      handleClick: handleProfile,
    },
    {
      label: "Fire Settings",
      icon: "mdi:payment-settings",
      handleClick: handleChangeFireSetting,
    },
    {
      label: "Change password",
      icon: "ant-design:lock",
      handleClick: handleChangePassWord,
    },
    {
      label: "Manage NavItems Access",
      icon: "uil:lock-access",
      handleClick: handleClickManageNavItemsAccess,
    },
  ];

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <DeleteConfirmation
        open={openDelete}
        isLoading={delLoading}
        setOpen={setOpenDelete}
        title={"Are you sure you want to remove this client ?"}
        handleAgree={handleDelete}
      />
      <ChangePasswordUser
        openPasswordModal={openPasswordModal}
        setopenPasswordModal={setopenPasswordModal}
        userId={userId}
      />

      <div className="d-flex align-items-center justify-content-between flex-wrap ">
        <div className="page-title-box">
          <h4>Business Clients</h4>
        </div>
        <div>
          <Button
            onClick={handleClickOpen}
            variant="contained"
            startIcon={
              <AddIcon
                style={{
                  fill: "#fff",
                }}
              />
            }
          >
            Add Client
          </Button>
        </div>
      </div>
      <div className="mt-4">
        <CustomTable
          data={userList}
          TABLE_HEAD={TABLE_HEAD}
          MENU_OPTIONS={MENU_OPTIONS}
          custom_pagination={{
            total_count: totalCount,
            rows_per_page: rowsPerPage,
            page: page,
            handleChangePage: handleChangePage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          custom_search={{
            searchText: searchText,
            setSearchText: setSearchText,
            handleSubmit: searchFunction,
          }}
          pageCount={pageCount}
          totalPages={totalPages}
          handleChangePages={handleChangePages}
          pagePagination={true}
        />
      </div>
    </>
  );
};

export default BusinessClients;
