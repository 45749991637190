import ReactEditor from "react-text-editor-kit";

import { _upload_image_s3_api } from "../DAL/Setting/Setting";
import { s3BaseUrl } from "../config/config";

function ReactTextEditorKit({ value, onChange, get_editor_ref }) {
  const image_handler = async (e) => {
    let formData = new FormData();
    formData.append("image", e.image);
    const result = await _upload_image_s3_api(formData);
    if (result.code === 200) {
      return s3BaseUrl + result.path;
    } else {
      return "";
    }
  };

  return (
    <div className="App">
      <ReactEditor
        value={value}
        onChange={onChange}
        getEditorRef={get_editor_ref}
        placeholder="Write your text here"
        image_handler={image_handler} // if you want to upload image on your server
      />
    </div>
  );
}

export default ReactTextEditorKit;
