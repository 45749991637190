import React, { useEffect, useState } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  TextareaAutosize,
  CircularProgress,
  Button,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import ActiveLastBreadcrumb from "../../../components/ActiveLastBreadcrumb";
import {
  _add_department_api,
  _get_department_by_id_api,
  _update_department_api,
} from "../../../DAL/Departments";
import { useSnackbar } from "notistack";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const AddOrUpdateDepartments = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [isEditLoading, setIsEditLoading] = useState(true);

  const [inputs, setInputs] = useState({
    name: "",
    status: true,
    description: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const get_department_detail = async () => {
    setIsEditLoading(true);
    const result = await _get_department_by_id_api(id);
    if (result.code === 200) {
      setInputs(result?.department);
      setIsEditLoading(false);
    }
    setIsEditLoading(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const formData = new FormData();
    formData.append("name", inputs.name);
    formData.append("status", inputs.status);
    formData.append("description", inputs.description);
    const result = id
      ? await _update_department_api(formData, id)
      : await _add_department_api(formData);
    if (result.code === 200) {
      navigate(`/departments`);
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  let breadCrumbMenu = [
    {
      title: "Departments",
      navigation: -1,
      active: false,
    },
    {
      title: id ? "Edit Department" : "Add Department",
      active: true,
    },
  ];

  useEffect(() => {
    if (id) {
      get_department_detail();
    } else {
      setIsEditLoading(false);
    }
  }, [id]);

  if (isEditLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="row ">
        <div className="d-flex justify-content-between align-items-center flex-wrap ">
          <div className="page-title-box">
            <h4>{id ? "Edit Department" : "Add Department"}</h4>
          </div>
          <div>
            <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
          </div>
        </div>
        <div className="col-12 col-md-6 mt-3">
          <TextField
            id="outlined-basic"
            label="Name"
            variant="outlined"
            fullWidth
            required
            name="name"
            value={inputs.name}
            placeholder="Name"
            onChange={handleChange}
          />
        </div>
        <div className="col-12 col-md-6 mt-3">
          <FormControl fullWidth required>
            <InputLabel id="demo-simple-select-label">Status</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={inputs.status}
              label="Status"
              name="status"
              required
              onChange={handleChange}
            >
              <MenuItem value={true}>Active</MenuItem>
              <MenuItem value={false}>Disable</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="col-12 mt-3">
          <TextareaAutosize
            aria-label="empty textarea"
            className="textarea-autosize"
            placeholder="Description"
            value={inputs.description}
            name="description"
            onChange={handleChange}
            style={{
              width: "100%",
              height: "100px",
              backgroundColor: "white",
              color: "#000",
              borderRadius: "5px",
              outline: "none",
              paddingTop: "8px",
              paddingLeft: "10px",
              border: "1px solid #00000038",
            }}
          />
        </div>
        <div className="d-flex justify-content-end gap-3 mt-4">
          <Button variant="contained" type="submit">
            {id
              ? `${isLoading ? "Loading.." : "Update"}`
              : `${isLoading ? "Loading.." : "Add Departments"}`}
          </Button>
        </div>
      </div>
    </form>
  );
};

export default AddOrUpdateDepartments;
