import { invokeApi } from "./invokeApi";

export const _add_payment_request_api = async (data) => {
  const requestObj = {
    path: `api/payment_request/add_payment_request`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _send_invoice_reminder_api = async (id) => {
  const requestObj = {
    path: `api/payment_request/send_invoice_reminder/${id}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _payment_request_paid_or_canceled = async (data, id) => {
  const requestObj = {
    path: `api/payment_request/payment_request_paid_or_canceled/${id}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _update_payment_request_api = async (data, id) => {
  const requestObj = {
    path: `api/payment_request/update_payment_request/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _list_payment_request_api = async (
  search_text,
  page,
  limit,
  type
) => {
  const requestObj = {
    path: `api/payment_request/list_payment_request?text=${search_text}&page=${page}&limit=${limit}&status=${type}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _get_payment_request_api = async (id) => {
  const requestObj = {
    path: `api/payment_request/get_payment_request/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _delete_payment_request_api = async (id) => {
  const requestObj = {
    path: `api/payment_request/delete_payment_request/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
