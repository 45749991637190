import { TextField, IconButton, InputAdornment } from "@mui/material";
import { useState } from "react";
import { Icon } from "@iconify/react";
// ----------------------------------------------------------------------

export default function CustomPasswordField({
  Required,
  show = false,
  onChange,
  label,
  value,
  name,
  id,
}) {
  const [showPassword, setShowPassword] = useState(show ? show : false);

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <>
      <TextField
        fullWidth
        id={id}
        label={label}
        value={value}
        onChange={(e) => onChange(e)}
        name={name}
        required={Required}
        autoComplete="new-password"
        type={showPassword ? "text" : "password"}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleShowPassword} edge="end">
                <Icon
                  icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </>
  );
}
