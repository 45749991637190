import { invokeApi } from "../invokeApi";

export const _admin_login_api = async (data) => {
  const requestObj = {
    path: `api/app_api/login`,
    method: "POST",
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _email_verification_api = async (data) => {
  const requestObj = {
    path: `api/app_api/email_verification`,
    method: "POST",
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _code_verification_api = async (data) => {
  const requestObj = {
    path: `api/app_api/code_verification`,
    method: "POST",
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _reset_password_api = async (data) => {
  const requestObj = {
    path: `api/app_api/reset_password`,
    method: "POST",
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _edit_profile_api = async (data) => {
  const requestObj = {
    path: `api/admin/edit_admin`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _change_password_api = async (data) => {
  const requestObj = {
    path: `api/app_api/change_password`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _logout_api = async () => {
  const requestObj = {
    path: `api/app_api/logout`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _logout_from_all_this_devices = async (data) => {
  const requestObj = {
    path: `api/app_api/logout_from_all_this_devices`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _change_password_user_api = async (id, data) => {
  const requestObj = {
    path: `api/admin/change_password_by_admin/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
