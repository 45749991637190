import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { DashboardLayout, LoginLayout } from "../layouts";
import {
  AddOrUpdateDepartments,
  AddOrUpdateMessage,
  AddOrUpdatePayment,
  AddOrUpdateProducts,
  AddorUpdatedInvoice,
  AddorUpdatedTransaction,
  BusinesPortalSetting,
  BusinessClients,
  BusinessForgotPasswordSetting,
  BusinessLoginSetting,
  BusinessSignupSetting,
  ClientProfile,
  Dashboard,
  Departments,
  EditProfile,
  ExpensesReport,
  FireSetting,
  ForgotPassword,
  ForgotPasswordSetting,
  Invoice,
  InvoiceDetails,
  Invoices,
  Login,
  LoginSetting,
  PaymentDetail,
  PaymentSummary,
  Payments,
  RespondedMessage,
  SaleReport,
  Setting,
  Signup,
  StripeProducts,
  StripeSetting,
  Subscriptions,
  Taxes,
  ThankYouSetting,
  Tickets,
  TicketsDetail,
  TransactionList,
  UserProfile,
  Users,
} from "../pages";
import AdminUsers from "../pages/AdminUsers/AdminUsers";
import AddOrUpdateAdminUser from "../pages/AdminUsers/AddOrUpdateAdminUser";
import AdminNavItems from "../pages/NavItems/AdminNavItems";
import AddOrUpdateClient from "../pages/BusinessClients/AddOrUpdateClient";
import AddOrUpdateUser from "../pages/Users/AddOrUpdateUser";
import EmailSetting from "../pages/Setting/EmailSetting";
import InvoiceSetting from "../pages/Setting/InvoiceSetting";
import EmailTemplates from "../pages/EmailTemplates/EmailTemplates";
import AddEmailTemplates from "../pages/EmailTemplates/AddEmailTemplates";
import PreviewEmailTemplate from "../pages/EmailTemplates/PreviewEmailTemplate";
import ManageNavItemsAccess from "../pages/NavItems/ManageNavItemsAccess";
import FireSettingClient from "../pages/BusinessClients/FireSettingClient";
import FireSettingClientProfile from "../pages/BusinessClients/FireSettingClientProfile";

const Authentication = () => {
  if (localStorage.getItem("token")) {
    return <Navigate to="/dashboard"> </Navigate>;
  } else {
    return <Navigate to="/login"> </Navigate>;
  }
};

export default function Routers() {
  return (
    <Routes>
      <Route element={<DashboardLayout />}>
        <Route path="/" element={<Authentication />} />
        <Route path="/dashboard" element={<Dashboard />} />
        {/* <Route path="/invoice" element={<Invoice />} />
        <Route path="/invoice/add-invoice" element={<AddorUpdatedInvoice />} />
        <Route path="/invoice/invoice-details" element={<InvoiceDetails />} />
        <Route
          path="/invoice/edit-invoice/:id"
          element={<AddorUpdatedInvoice value="edit" />}
        /> */}
        <Route path="/invoices" element={<Invoices />} />
        <Route
          path="/invoices/invoice-detail/:id"
          element={<PaymentDetail />}
        />
        <Route path="/invoices/add-invoice" element={<AddOrUpdatePayment />} />
        <Route
          path="/invoices/edit-invoice/:id"
          element={<AddOrUpdatePayment />}
        />
        <Route path="/taxes" element={<Taxes />} />
        <Route path="/payment-summary" element={<PaymentSummary />} />
        <Route path="/sale-report" element={<SaleReport />} />
        <Route path="/expenses-report" element={<ExpensesReport />} />
        <Route path="/transactions" element={<TransactionList />} />
        <Route
          path="/transactions/add-transaction"
          element={<AddorUpdatedTransaction />}
        />
        <Route
          path="/transactions/edit-transaction/:id"
          element={<AddorUpdatedTransaction value="edit" />}
        />
        <Route path="/busines-clients" element={<BusinessClients />} />
        <Route
          path="/busines-clients/add-client"
          element={<AddOrUpdateClient />}
        />
        <Route
          path="/busines-clients/fire-setting/:member_id"
          element={<FireSettingClient />}
        />
        <Route
          path="/busines-clients/client-profile/fire-setting/:member_id"
          element={<FireSettingClientProfile />}
        />
        <Route
          path="/busines-clients/edit-client/:member_id"
          element={<AddOrUpdateClient />}
        />
        <Route
          path="/busines-clients/client-profile/:user_id"
          element={<ClientProfile />}
        />
        <Route
          path="/busines-clients/client-profile/manage-navitems-access/:user_id"
          element={
            <ManageNavItemsAccess
              type="business"
              title="Business Clients"
              navigate="/busines-clients"
              show_in_profile={true}
            />
          }
        />
        <Route
          path="/busines-clients/manage-navitems-access/:user_id"
          element={
            <ManageNavItemsAccess
              type="business"
              title="Business Clients"
              navigate="/busines-clients"
            />
          }
        />
        <Route path="/users" element={<Users />} />
        <Route path="/users/add-user" element={<AddOrUpdateUser />} />
        <Route path="/users/edit-user/:user_id" element={<AddOrUpdateUser />} />
        <Route path="/users/user-profile/:user_id" element={<UserProfile />} />
        <Route path="/admin-users" element={<AdminUsers />} />
        <Route
          path="/admin-users/add-user"
          element={<AddOrUpdateAdminUser />}
        />
        <Route
          path="/admin-users/edit-user/:user_id"
          element={<AddOrUpdateAdminUser />}
        />
        <Route
          path="/admin-users/manage-navitems-access/:user_id"
          element={
            <ManageNavItemsAccess
              type="admin"
              title="Admin Users"
              navigate="/admin-users"
            />
          }
        />
        <Route
          path="/admin-nav-items"
          element={<AdminNavItems type="admin" />}
        />
        <Route
          path="/client-nav-items"
          element={<AdminNavItems type="client" />}
        />
        <Route path="/edit-profile" element={<EditProfile />} />
        <Route path="/general-setting" element={<Setting />} />
        <Route path="/fire-setting" element={<FireSetting />} />
        <Route path="/login-setting" element={<LoginSetting />} />
        <Route path="/email-setting" element={<EmailSetting />} />
        <Route path="/invoice-setting" element={<InvoiceSetting />} />
        <Route path="/email-templates" element={<EmailTemplates />} />
        <Route
          path="/email-templates/add-template"
          element={<AddEmailTemplates />}
        />
        <Route
          path="/email-templates/edit-template/:id"
          element={<AddEmailTemplates />}
        />
        <Route
          path="/email-templates/preview/:id"
          element={<PreviewEmailTemplate />}
        />
        <Route
          path="/forgot-password-setting"
          element={<ForgotPasswordSetting />}
        />
        <Route
          path="/business-login-setting"
          element={<BusinessLoginSetting />}
        />
        <Route
          path="/business-signup-setting"
          element={<BusinessSignupSetting />}
        />
        <Route
          path="/business-forgot-password-setting"
          element={<BusinessForgotPasswordSetting />}
        />
        <Route
          path="/busines-portal-setting"
          element={<BusinesPortalSetting />}
        />
        <Route path="/stripe-setting" element={<StripeSetting />} />
        <Route path="/thank-you" element={<ThankYouSetting />} />
        <Route path="/stripe-products" element={<StripeProducts />} />
        <Route
          path="/stripe-products/add-products"
          element={<AddOrUpdateProducts />}
        />
        <Route
          path="/stripe-products/edit-products/:id"
          element={<AddOrUpdateProducts />}
        />
        <Route path="/subscriptions" element={<Subscriptions />} />
        <Route path="/departments" element={<Departments />} />
        <Route
          path="/departments/add-department"
          element={<AddOrUpdateDepartments />}
        />
        <Route
          path="/departments/edit-department/:id"
          element={<AddOrUpdateDepartments />}
        />
        <Route path="/support-tickets" element={<Tickets />} />
        <Route path="/support-tickets/:type" element={<Tickets />} />
        <Route
          path="/support-tickets/detail-ticket/:type/:detail_id"
          element={<TicketsDetail />}
        />
        <Route path="/auto-responded-message" element={<RespondedMessage />} />
        <Route
          path="/auto-responded-message/add-message"
          element={<AddOrUpdateMessage />}
        />
        <Route
          path="/auto-responded-message/edit-message/:mesg_id"
          element={<AddOrUpdateMessage />}
        />
        <Route path="*" element={<Navigate to="/dashboard" />} />
      </Route>

      <Route element={<LoginLayout />}>
        <Route path="/" element={<Authentication />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
      </Route>
    </Routes>
  );
}
