import React, { useEffect, useState } from "react";
import CustomTable from "../../components/customTable/CustomTable";
import { Button, CircularProgress, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteConfirmation from "../../components/DeleteConfirmation";
import { useNavigate } from "react-router-dom";
import {
  _delete_payment_request_api,
  _list_payment_request_api,
} from "../../DAL/Payment";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import {
  _delete_stripe_products_api,
  _list_stripe_products_api,
} from "../../DAL/StripeProducts";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const StripeProducts = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [delLoading, setDelLoading] = useState(false);
  const [stripeList, setStripeList] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchText, setSearchText] = useState("");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const get_payment_list = async () => {
    setIsLoading(true);
    let search_keyword =
      localStorage.getItem("stripe_search_text") == null
        ? searchText
        : localStorage.getItem("stripe_search_text");
    if (search_keyword !== null) {
      setSearchText(search_keyword);
    }
    const result = await _list_stripe_products_api(
      search_keyword,
      page,
      rowsPerPage
    );
    if (result.code === 200) {
      let data = result?.stripe_products_list.map((item) => {
        let date = "N/A";
        if (item.createdAt) {
          date = moment(item.createdAt).format("DD-MM-YYYY");
        }
        return {
          ...item,
          date,
        };
      });
      setStripeList(data);
      setTotalCount(result?.total_count);
      setTotalPages(result?.total_pages);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const searchFunction = () => {
    setPage(0);
    setPageCount(1);
    get_payment_list();
  };

  const handleClickAdd = () => {
    navigate(`/stripe-products/add-products`);
  };

  const handleEdit = (value) => {
    navigate(`/stripe-products/edit-products/${value._id}`, {
      state: value,
    });
  };

  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    { id: "name", label: "Name" },
    { id: "stripe_product_id", label: "Stripe Product Id" },
    { id: "date", label: "Created At" },
    {
      id: "action",
      label: "Action",
      renderData: (row) => {
        return (
          <>
            <div className="d-flex gap-2">
              <Tooltip title="Edit Product" placement="top">
                <button
                  className="btn btn-soft-info"
                  onClick={() => {
                    handleEdit(row);
                  }}
                >
                  <i className="fa-solid fa-pen-to-square"></i>
                </button>
              </Tooltip>
              <Tooltip title="Delete Product" placement="top">
                <button
                  className="btn btn-soft-danger"
                  onClick={() => {
                    handleAgreeDelete(row);
                  }}
                >
                  <i className="fa-solid fa-trash"></i>
                </button>
              </Tooltip>
            </div>
          </>
        );
      },
    },
  ];

  const handleAgreeDelete = (value) => {
    setOpenDelete(true);
    setDeleteId(value._id);
  };

  const handleDelete = async () => {
    setDelLoading(true);
    const result = await _delete_stripe_products_api(deleteId);
    if (result.code == 200) {
      let user_list = stripeList.filter((user_id) => user_id._id !== deleteId);
      setStripeList(user_list);
      setOpenDelete(false);
      setDelLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setDelLoading(false);
    }
  };

  useEffect(() => {
    get_payment_list();
  }, [rowsPerPage, page]);

  useEffect(() => {
    localStorage.setItem("stripe_search_text", searchText);
    if (searchText.length < 1) {
      localStorage.setItem("stripe_search_text", "");
    } else {
      localStorage.setItem("stripe_search_text", searchText);
    }
  }, [searchText]);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <DeleteConfirmation
        open={openDelete}
        isLoading={delLoading}
        setOpen={setOpenDelete}
        title={"Are you sure you want to remove this product ?"}
        handleAgree={handleDelete}
      />
      <div className="d-lg-flex justify-content-between flex-wrap align-items-center">
        <div className="page-title-box">
          <h4>Stripe Products</h4>
        </div>
        <Button
          onClick={handleClickAdd}
          variant="contained"
          startIcon={
            <AddIcon
              style={{
                fill: "#fff",
              }}
            />
          }
        >
          Add Product
        </Button>
      </div>
      <div className="mt-4">
        <CustomTable
          data={stripeList}
          TABLE_HEAD={TABLE_HEAD}
          custom_pagination={{
            total_count: totalCount,
            rows_per_page: rowsPerPage,
            page: page,
            handleChangePage: handleChangePage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          custom_search={{
            searchText: searchText,
            setSearchText: setSearchText,
            handleSubmit: searchFunction,
          }}
          pageCount={pageCount}
          totalPages={totalPages}
          handleChangePages={handleChangePages}
          pagePagination={true}
        />
      </div>
    </>
  );
};

export default StripeProducts;
