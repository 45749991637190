import React, { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  FormHelperText,
  TextField,
  Tooltip,
} from "@mui/material";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { styled } from "@mui/material/styles";
import { s3BaseUrl } from "../../config/config";
import {
  _edit_gernal_settings_api,
  _get_gernal_settings_api,
  _upload_image_s3_api,
} from "../../DAL/Setting/Setting";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { useAdminContext } from "../../Hooks/AdminContext";
import { LoadingButton } from "@mui/lab";

const Input = styled("input")({
  display: "none",
});

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const Setting = () => {
  const classes = useStyles();
  const { handleSettingList } = useAdminContext();
  const [isLoading, setIsLoading] = useState(false);
  const [isGetLoading, setIsGetLoading] = useState(true);
  const [isPicLoading, setIsPicLoading] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const [inputs, setInputs] = useState({
    meta_title: "",
    meta_discription: "",
    meta_keyword: "",
    brand_name: "",
    copyright_text: "",
    admin_logo: "",
    admin_favicon: "",
  });

  const [images, setImages] = useState({
    admin_logo: "",
    admin_favicon: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const fileChangedHandler = async (e) => {
    const { name, files } = e.target;
    if (files[0]) {
      setIsPicLoading(name);
      const formData = new FormData();
      formData.append("image", files[0]);
      const result = await _upload_image_s3_api(formData);
      if (result.code == 200) {
        setImages({ ...images, [name]: URL.createObjectURL(files[0]) });
        setInputs({ ...inputs, [name]: result.path });
        setIsPicLoading("");
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsPicLoading("");
      }
    }
  };

  const get_setting_data = async () => {
    setIsGetLoading(true);
    const result = await _get_gernal_settings_api();
    if (result.code == 200) {
      setInputs(result.setting?.general_settings);
      setIsGetLoading(false);
    } else {
      setIsGetLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let general_settings = {
      meta_title: inputs.meta_title,
      meta_discription: inputs.meta_discription,
      meta_keyword: inputs.meta_keyword,
      brand_name: inputs.brand_name,
      copyright_text: inputs.copyright_text,
      admin_logo: inputs.admin_logo,
      admin_favicon: inputs.admin_favicon,
    };

    let postData = {
      type: "general_settings",
      general_settings: general_settings,
    };

    const result = await _edit_gernal_settings_api(postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      handleSettingList(result.gernal_settings?.general_settings);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    get_setting_data();
  }, []);

  if (isGetLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      <form onSubmit={handleSubmit}>
        <div className="row ">
          <div className="page-title-box">
            <h4>Default Settings</h4>
          </div>
          <div className="col-12 col-md-6 mt-3">
            <TextField
              id="outlined-basic"
              label="Meta Title"
              variant="outlined"
              fullWidth
              placeholder="Meta Title"
              name="meta_title"
              value={inputs.meta_title}
              onChange={handleChange}
            />
          </div>
          <div className="col-12 col-md-6 mt-3">
            <TextField
              id="outlined-basic"
              label="Meta Discription"
              variant="outlined"
              fullWidth
              placeholder="Meta Discription"
              name="meta_discription"
              value={inputs.meta_discription}
              onChange={handleChange}
            />
          </div>
          <div className="col-12 col-md-6 mt-3">
            <TextField
              id="outlined-basic"
              label="Meta Keyword"
              variant="outlined"
              fullWidth
              placeholder="Meta Keyword"
              name="meta_keyword"
              value={inputs.meta_keyword}
              onChange={handleChange}
            />
          </div>
          <div className="col-12 col-md-6 mt-3">
            <TextField
              id="outlined-basic"
              label="Brand Name"
              variant="outlined"
              fullWidth
              placeholder="Brand Name"
              name="brand_name"
              value={inputs.brand_name}
              onChange={handleChange}
            />
          </div>
          <div className="col-12 col-md-6 mt-3">
            <TextField
              id="outlined-basic"
              label="Copyright Text"
              variant="outlined"
              fullWidth
              placeholder="Copyright Text"
              name="copyright_text"
              value={inputs.copyright_text}
              onChange={handleChange}
            />
          </div>
          <div className="col-12 mt-4">
            <div className="row w-100 div-style ms-0 pt-0">
              <div className="col-lg-5">
                <p className="text-black">Logo</p>
                <FormHelperText className="pt-0 helper_text">
                  Recommended Size 778 X 430
                </FormHelperText>
              </div>
              <div className="col-lg-2">
                {/* <div className="del_img_icon"> */}
                {isPicLoading === "admin_logo" ? (
                  <CircularProgress size="1.5rem" color="primary" />
                ) : (
                  <img
                    src={
                      images.admin_logo
                        ? images.admin_logo
                        : inputs?.admin_logo
                        ? s3BaseUrl + inputs?.admin_logo
                        : ""
                    }
                    height="50"
                  />
                )}
                {/* <Tooltip title="Delete image">
                      <span
                        className="pointer"
                        // onClick={() =>
                        //   handleRemove(
                        //     template,
                        //     inputs[template.attribute_db_name]
                        //   )
                        // }
                      >
                        X
                      </span>
                    </Tooltip> */}
                {/* </div> */}
              </div>
              <div className="col-lg-5 text-end pt-2">
                <label htmlFor="contained-button-file">
                  <Input
                    accept="image/*"
                    id="contained-button-file"
                    multiple
                    type="file"
                    name="admin_logo"
                    onChange={fileChangedHandler}
                  />

                  <Button
                    className="btn btn-primary "
                    startIcon={<FileUploadIcon />}
                    component="span"
                  >
                    Upload
                  </Button>
                </label>
              </div>
            </div>
            {inputs.admin_logo?.name == "" ? (
              ""
            ) : (
              <p className="text-secondary">{inputs.admin_logo?.name}</p>
            )}
          </div>
          <div className="col-12 mt-4">
            <div className="row w-100 div-style ms-0 pt-0">
              <div className="col-lg-5">
                <p className="text-black">Favicon</p>
                <FormHelperText className="pt-0 helper_text">
                  Recommended Size 32 X 32
                </FormHelperText>
              </div>
              <div className="col-lg-2">
                {isPicLoading === "admin_favicon" ? (
                  <CircularProgress size="1.5rem" color="primary" />
                ) : (
                  <img
                    src={
                      images.admin_favicon
                        ? images.admin_favicon
                        : inputs?.admin_favicon
                        ? s3BaseUrl + inputs?.admin_favicon
                        : ""
                    }
                    height="50"
                  />
                )}
              </div>
              <div className="col-lg-5 text-end pt-2">
                <label htmlFor="contained-button-file1">
                  <Input
                    accept="image/*"
                    id="contained-button-file1"
                    multiple
                    type="file"
                    name="admin_favicon"
                    onChange={fileChangedHandler}
                  />

                  <Button
                    className="btn btn-primary "
                    startIcon={<FileUploadIcon />}
                    component="span"
                  >
                    Upload
                  </Button>
                </label>
              </div>
            </div>
            {inputs.admin_favicon?.name == "" ? (
              ""
            ) : (
              <p className="text-secondary">{inputs.admin_favicon?.name}</p>
            )}
          </div>
          <div className="text-end mt-4">
            <button
              className="btn btn-primary mt-4 "
              disabled={isLoading || isPicLoading}
            >
              {isLoading ? "Updating..." : "Update"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Setting;
