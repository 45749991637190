import React, { useEffect, useState } from "react";
import CustomTable from "../../../../components/customTable/CustomTable";

const AllTicket = ({
  ticketList,
  TABLE_HEAD,
  totalCount,
  rowsPerPage,
  page,
  pageCount,
  totalPages,
  handleChangePage,
  handleChangeRowsPerPage,
  handleChangePages,
  handleMenu,
  searchText,
  setSearchText,
  searchFunction,
}) => {
  const [dataList, setDataList] = useState([]);

  const get_data_list = async () => {
    let data =
      ticketList &&
      ticketList.map((item) => {
        let user_name = "N/A";
        if (item.user_info) {
          let name = item.user_info;
          user_name =
            name.first_name + " " + name.last_name + " (" + name.email + ")";
        }
        return {
          ...item,
          user_name,
          MENU_OPTIONS: handleMenu(item),
        };
      });
    setDataList(data);
  };

  useEffect(() => {
    get_data_list();
  }, [ticketList]);

  return (
    <>
      <CustomTable
        data={dataList}
        TABLE_HEAD={TABLE_HEAD}
        pagePagination={true}
        custom_pagination={{
          total_count: totalCount,
          rows_per_page: rowsPerPage,
          page: page,
          handleChangePage: handleChangePage,
          onRowsPerPageChange: handleChangeRowsPerPage,
        }}
        custom_search={{
          searchText: searchText,
          setSearchText: setSearchText,
          handleSubmit: searchFunction,
        }}
        pageCount={pageCount}
        totalPages={totalPages}
        handleChangePages={handleChangePages}
      />
    </>
  );
};

export default AllTicket;
