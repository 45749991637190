import React, { useEffect, useState } from "react";
import { Button, TextField } from "@mui/material";
import ActiveLastBreadcrumb from "../../components/ActiveLastBreadcrumb";
import { useSnackbar } from "notistack";
import { _add_transaction_api } from "../../DAL/Transaction/Transaction";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import GeneralCkeditor from "../../components/GeneralCkeditor";
import {
  _add_stripe_products_api,
  _update_stripe_products_api,
} from "../../DAL/StripeProducts";

const AddOrUpdateProducts = () => {
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [inputs, setInputs] = useState({
    name: "",
    description: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let postData = {
      name: inputs.name,
      description: inputs.description,
    };
    const result = params.id
      ? await _update_stripe_products_api(postData, params.id)
      : await _add_stripe_products_api(postData);
    if (result.code === 200) {
      navigate(`/stripe-products`);
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  let breadCrumbMenu = [
    {
      title: "Products",
      navigation: `/stripe-products`,
      active: false,
    },
    {
      title: params.id ? "Edit Product" : "Add Product",
      active: true,
    },
  ];

  useEffect(() => {
    if (state) {
      setInputs(state);
    }
  }, [state]);

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap ">
        <div className="page-title-box">
          <h4>{params.id ? "Edit Product" : "Add Product"} </h4>
        </div>
        <div>
          <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
        </div>
      </div>
      <div className="card mt-3">
        <div className="card-body">
          <form className="row" onSubmit={handleSubmit}>
            <div className="col-12 col-md-6 mt-4">
              <TextField
                id="outlined-basic"
                label="Name"
                placeholder="Name"
                variant="outlined"
                fullWidth
                required
                name="name"
                value={inputs.name}
                onChange={handleChange}
              />
            </div>
            <div className="col-12 mt-3">
              <h6 className="text-black">Description *</h6>
              <GeneralCkeditor
                setInputs={setInputs}
                inputs={inputs}
                name="description"
                editorHeight={320}
              />
            </div>
            <div className="text-end mt-4">
              <Button type="submit" variant="contained">
                {params.id
                  ? `${isLoading ? "Loading.." : "Update"}`
                  : `${isLoading ? "Loading.." : "Add Product"}`}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddOrUpdateProducts;
