import { replace } from "lodash";
import numeral from "numeral";

export const PAYMENT_STATUS = [
  {
    name: "Paid",
    value: "paid",
    class: "badge bg-success-subtle text-success p-2",
  },
  {
    name: "Unpaid",
    value: "unpaid",
    class: "badge bg-warning-subtle text-warning p-2",
  },
  {
    name: "Refund",
    value: "refund",
    class: "badge bg-info-subtle text-info p-2",
  },
  {
    name: "Cancel",
    value: "cancel",
    class: "badge bg-danger-subtle text-danger p-2",
  },
  {
    name: "Canceled",
    value: "canceled",
    class: "badge bg-danger-subtle text-danger p-2",
  },
  {
    name: "Failed",
    value: "failed",
    class: "badge bg-danger-subtle text-danger p-2",
  },
  {
    name: "Incompletd",
    value: "incomplete",
    class: "badge bg-danger-subtle text-danger p-2",
  },
  {
    name: "Enabled",
    value: "enabled",
    class: "badge bg-success-subtle text-success p-2",
  },
  {
    name: "Credit Card",
    value: "credit_card",
    class: "badge bg-danger-subtle text-danger p-2",
  },
  {
    name: "Pending",
    value: "pending",
    class: "badge bg-danger-subtle text-danger p-2",
  },
  {
    name: "Disabled",
    value: "disabled",
    class: "badge bg-danger-subtle text-danger p-2",
  },
  {
    name: "Disabled",
    value: false,
    class: "badge bg-danger-subtle text-danger p-2",
  },
  {
    name: "Active",
    value: true,
    class: "badge bg-success-subtle text-success p-2",
  },
  {
    name: "Debit",
    value: "debit",
    class: "badge bg-danger-subtle text-danger p-2",
  },
  {
    name: "Google Pay",
    value: "google_pay",
    class: "badge bg-success-subtle text-success p-2",
  },
  {
    name: "Approved",
    value: "approved",
    class: "badge bg-success-subtle text-success p-2",
  },
  {
    name: "Active",
    value: "active",
    class: "badge bg-success-subtle text-success p-2",
  },
  {
    name: "Credit",
    value: "credit",
    class: "badge bg-success-subtle text-success p-2",
  },
  {
    name: "Cash",
    value: "cash",
    class: "badge bg-warning-subtle text-warning p-2",
  },
  {
    name: "Processing",
    value: "processing",
    class: "badge bg-warning-subtle text-warning p-2",
  },
];

export const ADMIN_USERS_STATUS = [
  {
    name: "Active",
    value: true,
    class: "badge bg-success-subtle text-success p-2",
  },
  {
    name: "Inactive",
    value: false,
    class: "badge bg-danger-subtle text-danger p-2",
  },
];

export const EMAIL_TEMPLATES_STATUS = [
  {
    name: "Active",
    value: true,
    class: "badge bg-success-subtle text-success p-2",
  },
  {
    name: "Inactive",
    value: false,
    class: "badge bg-danger-subtle text-danger p-2",
  },
];
export const TRSNS_STATUS = [
  {
    name: "Debit",
    value: false,
    class: "badge bg-danger-subtle text-danger p-2",
  },
  {
    name: "Credit",
    value: true,
    class: "badge bg-success-subtle text-success p-2",
  },
];

export const TRANSACTION_STATUS = [
  {
    name: "Succeeded",
    value: "succeded",
    class: "succeeded_bg",
  },
  {
    name: "Pending",
    value: "pending",
    class: "pending_bg",
  },
  {
    name: "Failed",
    value: "failed",
    class: "payment_failed_bg",
  },
  {
    name: "Canceled",
    value: "canceled",
    class: "canceled_bg",
  },
  {
    name: "Refunded",
    value: "refunded",
    class: "refunded_bg",
  },
  {
    name: "Initiated",
    value: "initiated_fire_payment",
    class: "Initiated_bg",
  },
];

export const TRANSACTION_STATUS_COLOR = [
  {
    name: "Succeeded",
    value: "succeded",
    class: "succeeded_bg_color",
  },
  {
    name: "Pending",
    value: "pending",
    class: "pending_bg_color",
  },
  {
    name: "Failed",
    value: "failed",
    class: "payment_failed_bg_color",
  },
  {
    name: "Canceled",
    value: "canceled",
    class: "canceled_bg_color",
  },
  {
    name: "Refunded",
    value: "refunded",
    class: "refunded_bg_color",
  },
  {
    name: "Initiated",
    value: "initiated_fire_payment",
    class: "Initiated_bg_color",
  },
];

export const show_proper_words = (text) => {
  let replace_string = "";
  if (text) {
    // Replace hyphens and underscores with spaces
    replace_string = text.replace(/[-_]/g, " ");
    // Capitalize the first letter of every word
    replace_string = replace_string
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }

  return replace_string;
};

export const convertCurrencyToSign = (currency) => {
  let currency_lowercase = currency?.toLowerCase();
  if (currency_lowercase === "gbp" || currency_lowercase === "GBP") {
    return "£";
  } else if (currency_lowercase === "eur" || currency_lowercase === "EUR") {
    return "€";
  } else if (currency_lowercase === "usd" || currency_lowercase === "USD") {
    return "$";
  } else {
    return "";
  }
};

// export function fShortenNumber(number) {
//   return replace(numeral(number).format("0.00a"), ".00", ".00");
// }

export function fShortenNumber(number) {
  return numeral(number)
    .format("0.00a") // format the number as 0.00a (e.g., 1.5k, 2.3m)
    .replace("k", "K") // replace lowercase 'k' with uppercase 'K'
    .replace("m", "M") // replace lowercase 'm' with uppercase 'M'
    .replace("b", "B"); // replace lowercase 'b' with uppercase 'B'
}

export function truncateDescription(description, maxLength) {
  if (description.length > maxLength) {
    return description.substring(0, maxLength) + "...";
  } else {
    return description;
  }
}

export function validateEmail(email) {
  // Regular expression to check the format of the email
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // Test the email against the regex pattern
  return emailRegex.test(email);
}
