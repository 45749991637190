import React, { useState } from "react";
import CustomTable from "../../components/customTable/CustomTable";
import { PAYMENT_STATUS } from "../../utils/constant";
import { Dialog, Menu, MenuItem, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MUIAutocomplete from "../../components/MUIAutocomplete";
import DeleteConfirmation from "../../components/DeleteConfirmation";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const PaymentSummary = () => {
  const [open1, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const [paymentType, setPaymentType] = useState([
    { option: "Credit Card" },
    { option: "Google Pay" },
    { option: "Cash" },
    { option: "Bank Transfer" },
  ]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const handleAgreeDelete = () => {
    setOpenDelete(true);
  };

  const handleDelete = () => {
    setOpenDelete(false);
  };

  const [userList, setUserList] = useState([
    {
      _id: 1,
      invoice_id: "30 Nov, 2022",
      client: "Lec-2158	",
      email: "Jena Hall",
      date: "$170.00",
      billed: "240.00",
      status: "credit_card",
    },
    {
      _id: 2,
      invoice_id: "30 Nov, 2022",
      client: "Lec-2158	",
      email: "Jena Hall",
      date: "$170.00",
      billed: "240.00",
      status: "google_pay",
    },
    {
      _id: 3,
      invoice_id: "30 Nov, 2022",
      client: "Lec-2158	",
      email: "Jena Hall",
      date: "$170.00",
      billed: "240.00",
      status: "credit_card",
    },
    {
      _id: 4,
      invoice_id: "30 Nov, 2022",
      client: "Lec-2158	",
      email: "Jena Hall",
      date: "$170.00",
      billed: "240.00",
      status: "credit_card",
    },
    {
      _id: 2,
      invoice_id: "30 Nov, 2022",
      client: "Lec-2158	",
      email: "Jena Hall",
      date: "$170.00",
      billed: "240.00",
      status: "google_pay",
    },
    {
      _id: 2,
      invoice_id: "30 Nov, 2022",
      client: "Lec-2158	",
      email: "Jena Hall",
      date: "$170.00",
      billed: "240.00",
      status: "cash",
    },
    {
      _id: 2,
      invoice_id: "30 Nov, 2022",
      client: "Lec-2158	",
      email: "Jena Hall",
      date: "$170.00",
      billed: "240.00",
      status: "google_pay",
    },
    {
      _id: 2,
      invoice_id: "30 Nov, 2022",
      client: "Lec-2158	",
      email: "Jena Hall",
      date: "$170.00",
      billed: "240.00",
      status: "google_pay",
    },
  ]);

  const TABLE_HEAD = [
    { id: "invoice_id", label: "DATE" },
    {
      id: "client",
      label: "INVOICE ID",
    },
    { id: "email", label: "CLIENT" },
    { id: "date", label: "Date" },
    { id: "billed", label: "BILLED" },
    {
      id: "status",
      label: "PAYMENT TYPE",
      renderData: (row) => {
        let find_status = PAYMENT_STATUS.find(
          (status) => status.value == row.status
        );
        return (
          <>
            <span className={`${find_status.class}`}>{find_status.name}</span>
          </>
        );
      },
    },
    {
      id: "action",
      label: "Action",
      type: "action",
    },
  ];

  const handleClickEdit = () => {};

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleClickOpen,
    },
    {
      label: "View",
      icon: "ep:view",
      handleClick: handleClickEdit,
    },
    {
      label: "Download",
      icon: "material-symbols:download",
      handleClick: handleClickEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];
  return (
    <>
      <DeleteConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to remove this Payment ?"}
        handleAgree={handleDelete}
      />
      <div className="page-title-box">
        <h4>PAYMENT SUMMARY</h4>
      </div>
      <div className="d-lg-flex align-items-center">
        <div className="position-relative search_input_table dashboard_input mb-lg-0 mb-3">
          <input
            type="text"
            className="form-control ps-3"
            placeholder="Search..."
            id="search-options"
          />
          <i className="fa-solid fa-magnifying-glass search_icon"></i>
        </div>
        <div className="ms-auto d-flex gap-2">
          <button type="button" className="btn btn-soft-primary fs-14">
            <i className="fa-solid fa-filter me-2 "></i>
            Filter
          </button>
          <button className="btn btn-soft-info" onClick={handleClick}>
            <i className="fa-solid fa-ellipsis-vertical "></i>
          </button>
        </div>
      </div>
      <div className="mt-4">
        <CustomTable
          data={userList}
          TABLE_HEAD={TABLE_HEAD}
          MENU_OPTIONS={MENU_OPTIONS}
          is_hide={true}
        />
      </div>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0 5px 10px rgba(30,32,37,.12))",
            mt: 1.5,
          },
        }}
      >
        <MenuItem
          style={{ fontSize: "15px", color: "#0f0f12" }}
          onClick={handleClose}
          sx={{
            paddingY: "4px",
            paddingRight: "16px",
          }}
        >
          Print
        </MenuItem>
        <MenuItem
          style={{ fontSize: "15px", color: "#0f0f12" }}
          onClick={handleClose}
          sx={{
            paddingY: "4px",
            paddingRight: "16px",
          }}
        >
          Export to Excel
        </MenuItem>
      </Menu>
      <Dialog open={open1} onClose={handleClose}>
        <div className="p-3">
          <div className="d-flex justify-content-between align-items-baseline">
            <div className="page-title-box">
              <h4>Edit Payment Summary</h4>
            </div>
            <div className="pointer" onClick={handleClose}>
              <CloseIcon />
            </div>
          </div>
          <div className="row">
            <div className="col-12 mt-3">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker label="Date" />
              </LocalizationProvider>
            </div>
            <div className="col-12  mt-3">
              <TextField
                id="outlined-basic"
                label="Invoice ID"
                variant="outlined"
                placeholder="Invoice ID"
                fullWidth
              />
            </div>
            <div className="col-12 mt-3">
              <TextField
                id="outlined-basic"
                label="Client"
                variant="outlined"
                placeholder="Client"
                fullWidth
              />
            </div>
            <div className="col-12 col-lg-6 mt-3">
              <TextField
                id="outlined-basic"
                label="Billed"
                variant="outlined"
                placeholder="Billed"
                fullWidth
              />
            </div>
            <div className="col-12 col-lg-6 mt-3">
              <MUIAutocomplete
                inputLabel="Payment Type"
                setSelectedOption={(e) => {
                  handleClick(e);
                }}
                optionsList={paymentType}
                name="option"
              />
            </div>
          </div>
          <div className="d-flex justify-content-end gap-3 mt-3">
            <a className="btn btn-light" onClick={handleClose}>
              Close
            </a>
            <a className="btn btn-primary" onClick={handleClose}>
              Edit Payment Summary
            </a>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default PaymentSummary;
