import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  TextareaAutosize,
} from "@mui/material";
import ActiveLastBreadcrumb from "../../components/ActiveLastBreadcrumb";
import { useSnackbar } from "notistack";
import {
  _add_transaction_api,
  _add_transaction_v1,
} from "../../DAL/Transaction/Transaction";
import { useNavigate, useParams } from "react-router-dom";
import MUIAutocomplete from "../../components/MUIAutocomplete";
import { TRANSACTION_STATUS } from "../../utils/constant";
import { _users_list_for_payment_request_api } from "../../DAL/User/User";
import { makeStyles } from "@mui/styles";
import { _list_payment_request_api } from "../../DAL/Payment";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const AddorUpdatedTransaction = ({ value }) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const params = useParams();
  const navigate = useNavigate();
  const [personName, setPersonName] = useState([]);
  const [member, setMember] = useState(null);
  const [userName, setUserName] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [getLoading, setGetLoading] = useState(true);
  const [paymentType, setPaymentType] = useState(["Credit Card"]);
  const [loadFirstTime, setLoadFirstTime] = useState(false);

  const [search, setSearch] = useState("");
  const [allInvoices, setAllInvoices] = useState([]);
  const [isLoadingInvoices, setIsLoadingInvoices] = useState(false);

  const [inputs, setInputs] = useState({
    total_amount: "",
    request_description: "",
    created_for: "client",
    payment_made_by_platform: "cash",
    invoiceObj: null,
    invoice_obj_id: "",
    is_disabled_fields: false,
  });

  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name == "total_amount") {
      let amount = parseInt(value);
      if (amount <= 0) {
        amount = 0;
      }
      setInputs((values) => ({ ...values, [name]: amount }));
    } else {
      setInputs((values) => ({ ...values, [name]: value }));
    }
  };

  const handleChangeOthers = (name, value) => {
    setInputs((values) => ({ ...values, [name]: value }));
  };

  // const handleFileChange = (e) => {
  //   const file = e.target.files[0];
  //   setInputs({
  //     ...inputs,
  //     ["attachment_url"]: file,
  //   });
  //   setUploadedFile(file);
  // };

  // const handleDragOver = (e) => {
  //   e.preventDefault();
  // };

  // const handleDrop = (e) => {
  //   e.preventDefault();
  //   const file = e.dataTransfer.files[0];
  //   setUploadedFile(file);
  // };

  // const formatFileSize = (sizeInBytes) => {
  //   const sizeInKB = sizeInBytes / 1024;
  //   return sizeInKB.toFixed(2) + " KB";
  // };

  const handleInputChange = (event, newInputValue) => {
    setSearch(newInputValue);
  };

  const handleChangeForInvoice = (event, newValue) => {
    let totalAmount = "";
    let isDisabled = false;
    let createdFor = "client";

    // business
    // client
    // business

    if (newValue?.request_type == "onetime") {
      totalAmount = newValue?.total_amount;
    } else if (newValue?.request_type == "recurring") {
      totalAmount = newValue?.installment_amount;
    }

    if (newValue) {
      isDisabled = true;
      if (newValue?.created_for == "business") {
        createdFor = "business";
      }
    }

    setInputs({
      ...inputs,
      created_for: createdFor,
      is_disabled_fields: isDisabled,
      total_amount: totalAmount,
      invoiceObj: newValue ? newValue : null,
      invoice_obj_id: newValue ? newValue?._id : "",
    });
  };

  const get_user_list = async () => {
    let type = "customer";
    if (inputs.created_for == "business") {
      type = "business";
    }
    const result = await _users_list_for_payment_request_api(params.id, type);

    if (result.code === 200) {
      let data = result.users.map((item) => {
        let member_name = "N/A";
        if (item.first_name) {
          member_name =
            item.first_name +
            " " +
            item.last_name +
            " (" +
            item.user_id.email +
            ")";
        }
        return {
          ...item,
          member_name,
        };
      });
      setPersonName(data);
      setUserName(result.stripe_products);

      setGetLoading(false);
      setIsLoaded(true);
    } else {
      setGetLoading(false);
    }
  };

  const getInvoiceList = async () => {
    if (allInvoices.length > 9 && search == "") {
      return;
    }

    try {
      setIsLoadingInvoices(true);
      const res = await _list_payment_request_api(search, 0, 15, "pending");
      if (res.code === 200) {
        setAllInvoices(res?.payment_request_list);
        setLoadFirstTime(true);
      } else {
        enqueueSnackbar(res.message, { variant: "error" });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoadingInvoices(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let error = "";
    let clientInfo = null;
    let totalAmount = parseInt(inputs?.total_amount);

    if (!totalAmount) {
      error = "Total Amount is required!";
      enqueueSnackbar(error, { variant: "error" });
      return;
    }
    if (!inputs?.payment_made_by_platform) {
      error = "Payment Made By Platform is required!";
      enqueueSnackbar(error, { variant: "error" });
      return;
    }

    if (inputs?.invoiceObj) {
      clientInfo = inputs?.invoiceObj?.client_info;
    } else {
      if (member) {
        clientInfo = {
          first_name: member?.first_name,
          last_name: member?.last_name,
          email: member?.user_id?.email,
          user_id: member?.user_id?._id,
        };
      } else {
        error = "Client is required!";
        enqueueSnackbar(error, { variant: "error" });
        return;
      }
    }

    if (!clientInfo) {
      error = "Client is required!";
      enqueueSnackbar(error, { variant: "error" });
      return;
    }

    let postData = {
      payment_request_id: inputs?.invoice_obj_id,
      total_amount: totalAmount,
      client_info: clientInfo,
      note: inputs?.request_description,
      payment_made_by_platform: inputs?.payment_made_by_platform,
    };

    setIsLoading(true);
    const result = await _add_transaction_v1(postData);
    if (result.code === 200) {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
      navigate(`/transactions`);
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  let breadCrumbMenu = [
    {
      title: "Transactions",
      navigation: `/transactions`,
      active: false,
    },
    {
      title: value ? "Edit Transaction" : "Add Transaction",
      active: true,
    },
  ];

  useEffect(() => {
    if (!params.id) {
      setGetLoading(false);
    }
    get_user_list();
    setMember(null);
  }, [inputs.created_for]);

  // useEffect(() => {
  //   // if (search.length % 2 == 0) {
  //   getInvoiceList();
  //   // }
  // }, [search]);

  useEffect(() => {
    // if (loadFirstTime) {
    const timeoutId = setTimeout(() => {
      getInvoiceList();
    }, 500);

    return () => clearTimeout(timeoutId);
    // }
  }, [search]);

  useEffect(() => {
    if (inputs?.invoiceObj) {
      const targetId = inputs?.invoiceObj?.client_info?.user_id;

      const filteredMember = personName.find(
        (user) => user?.user_id?._id === targetId
      );

      if (filteredMember) {
        setMember(filteredMember);
      }
    }
  }, [inputs, member, personName]);

  if (getLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap ">
        <div className="page-title-box">
          <h4>{value ? "Edit TRANSACTION" : "Add TRANSACTION"} </h4>
        </div>
        <div>
          <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
        </div>
      </div>
      <div className="card mt-3">
        <div className="card-body">
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-12 col-md-6 mt-4">
                <>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    // options={
                    //   allInvoices && allInvoices.length == 0
                    //     ? [{ _id: "loading", request_title: "Loading..." }]
                    //     : allInvoices
                    // }

                    options={
                      isLoadingInvoices
                        ? [
                            {
                              _id: "",
                              request_title: "Loading...",
                            },
                          ]
                        : allInvoices && allInvoices.length === 0
                        ? [
                            {
                              _id: "empty",
                              request_title: "No Invoice available",
                            },
                          ]
                        : allInvoices
                    }
                    getOptionLabel={(option) => option.request_title || ""}
                    isOptionEqualToValue={(option, value) =>
                      option._id === value._id
                    }
                    value={inputs.search}
                    // onFocus={(e) => allActiveEmp()}
                    onChange={handleChangeForInvoice}
                    // onChange={(e, v) =>
                    //   setInputs((values) => ({
                    //     ...values,
                    //     ["search"]: v?._id ? v?._id : "",
                    //   }))
                    // }
                    onInputChange={handleInputChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Search Invoice"
                        placeholder="Search Invoice by Title"
                      />
                    )}
                  />

                  {/* <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={
                      isLoadingInvoices
                        ? [
                            {
                              _id: "",
                              request_title: "Loading...",
                            },
                          ]
                        : allInvoices && allInvoices.length === 0
                        ? [
                            {
                              _id: "empty",
                              request_title: "No Invoice available",
                            },
                          ]
                        : allInvoices
                    }
                    getOptionLabel={(option) =>
                      option?._id
                        ? // ? option?.request_title + " | " + option?._id
                          option?.request_title
                        : option?.request_title || ""
                    }
                    isOptionEqualToValue={(option, value) =>
                      option._id === value.invoice_obj_id
                    }
                    value={inputs?.invoiceObj}
                    // onFocus={(e) => allActiveEmp()}
                    onChange={handleChangeForInvoice}
                    onInputChange={handleInputChange}
                    filterOptions={(options) => options} // Returns all options without filtering
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Search Invoice"
                        placeholder="Search Invoice by Title"
                      />
                    )}
                  /> */}
                </>
              </div>

              <div className="col-12 col-md-6 mt-4">
                <FormControl fullWidth required>
                  <InputLabel id="demo-simple-select-label">
                    Created For
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="created_for"
                    value={inputs.created_for}
                    label="Created For"
                    onChange={handleChange}
                    disabled={inputs?.is_disabled_fields}
                  >
                    <MenuItem value="business">Business Clients</MenuItem>
                    <MenuItem value="client">Users</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="col-12 col-md-6 mt-4">
                <TextField
                  id="outlined-basic"
                  label="Total Amount"
                  placeholder="Total Amount"
                  variant="outlined"
                  fullWidth
                  type="number"
                  required
                  name="total_amount"
                  value={inputs.total_amount}
                  onChange={handleChange}
                  disabled={inputs?.is_disabled_fields}
                />
              </div>
              {/* <div className="col-12 col-md-6 mt-4">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Currency *
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="currency"
                    value={inputs.currency}
                    label="currency"
                    onChange={handleChange}
                  >
                    <MenuItem value="GBP">UK Pounds</MenuItem>
                    <MenuItem value="EUR">Euro</MenuItem>
                  </Select>
                </FormControl>
              </div> */}
              <div className="col-12 col-md-6 mt-4">
                <MUIAutocomplete
                  inputLabel={
                    // inputs?.created_for == "customer" ? "Users" : "Clients"
                    inputs?.created_for == "business"
                      ? "Business Clients"
                      : "Users"
                    // memberLabel
                  }
                  required={true}
                  selectedOption={member}
                  setSelectedOption={setMember}
                  optionsList={personName}
                  name="member_name"
                  disabled={inputs?.is_disabled_fields}
                />
              </div>

              <div className="col-12 col-md-6 mt-4">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Platform
                  </InputLabel>
                  <Select
                    fullWidth
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="payment_made_by_platform"
                    value={inputs?.payment_made_by_platform}
                    label="Platform"
                    onChange={handleChange}
                    required
                  >
                    <MenuItem value="cash">Cash</MenuItem>
                    <MenuItem value="stripe">Stripe</MenuItem>
                    <MenuItem value="fire">Bank</MenuItem>
                  </Select>
                </FormControl>
              </div>

              <div className="col-12 mt-3">
                <div className="mb-1 fw-14 ms-1">Transaction Note</div>
                <TextareaAutosize
                  placeholder="Transaction Note"
                  aria-label="empty textarea"
                  className="textarea-autosize"
                  name="request_description"
                  value={inputs.request_description}
                  onChange={handleChange}
                  style={{
                    width: "100%",
                    height: "100px",
                    backgroundColor: "white",
                    color: "#000",
                    borderRadius: "5px",
                    outline: "none",
                    paddingTop: "8px",
                    paddingLeft: "10px",
                    border: "1px solid #00000038",
                  }}
                />
              </div>
              {/* <div className="text-end mt-4">
                <Button type="submit" variant="contained" disabled={isLoading}>
                  {params?.id
                    ? `${isLoading ? "Loading.." : "Update"}`
                    : `${isLoading ? "Loading.." : "Add Invoice"}`}
                </Button>
              </div> */}

              <div className="col-12 mt-4">
                <div className="d-flex justify-content-end gap-3 mt-3">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={isLoading}
                  >
                    {params?.id
                      ? `${isLoading ? "Loading.." : "Update"}`
                      : `${isLoading ? "Loading.." : "Add Transaction"}`}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddorUpdatedTransaction;
