import { invokeApi } from "./invokeApi";

export const _update_nav_items = async (data) => {
  const requestObj = {
    path: `api/website_setting/update_nav_items`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _get_nav_items = async (type) => {
  const requestObj = {
    path: `api/website_setting/get_nav_items?type=${type}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _get_nav_item_details_api = async (type, user_id) => {
  const requestObj = {
    path: `api/app_api/get_nav_items?type=${type}&user_id=${user_id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _update_nav_items_access_api = async (data) => {
  const requestObj = {
    path: `api/app_api/update_nav_items`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
