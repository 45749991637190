import { invokeApi } from "../invokeApi";

export const _upload_image_s3_api = async (data) => {
  const requestObj = {
    path: `api/app_api/upload_image_s3`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _add_email_template = async (data) => {
  const requestObj = {
    path: `api/email_templates/add_email_template`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _update_email_template_by_id = async (data, id) => {
  const requestObj = {
    path: `api/email_templates/update_email_template_by_id/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _get_email_template_by_id = async (id) => {
  const requestObj = {
    path: `api/email_templates/get_email_template_by_id/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _edit_gernal_settings_api = async (data) => {
  const requestObj = {
    path: `api/website_setting/edit_website_gernal_settings`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _update_invoice_settings = async (data) => {
  const requestObj = {
    path: `api/website_setting/update_invoice_settings`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _update_email_settings = async (data) => {
  const requestObj = {
    path: `api/website_setting/update_email_settings`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _update_business_client_settings_api = async (data) => {
  const requestObj = {
    path: `api/business_client_settings/update_business_client_settings`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _get_fire_settings_api = async () => {
  const requestObj = {
    path: `api/website_setting/get_fire_settings`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _get_stripe_settings_api = async () => {
  const requestObj = {
    path: `api/website_setting/get_stripe_settings`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _get_business_client_settings_api = async () => {
  const requestObj = {
    path: `api/business_client_settings/get_business_client_settings`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _get_email_settings = async () => {
  const requestObj = {
    path: `api/website_setting/get_email_settings`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _get_gernal_settings_api = async () => {
  const requestObj = {
    path: `api/website_setting/gernal_settings`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _get_invoice_settings = async () => {
  const requestObj = {
    path: `api/website_setting/get_invoice_settings`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _update_fire_settings_api = async (data) => {
  const requestObj = {
    path: `api/website_setting/update_fire_settings`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _update_stripe_settings_api = async (data) => {
  const requestObj = {
    path: `api/website_setting/update_stripe_settings`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _admin_admin_without_token_api = async () => {
  const requestObj = {
    path: `api/admin/admin_admin_without_token`,
    method: "GET",
  };
  return invokeApi(requestObj);
};

export const _admin_with_token_api = async () => {
  const requestObj = {
    path: `api/admin/detail_admin`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _update_payment_settings_api = async (data) => {
  const requestObj = {
    path: `api/website_setting/update_payment_settings`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _get_thanks_settings_api = async () => {
  const requestObj = {
    path: `api/website_setting/get_thanks_settings`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _get_all_email_templates = async () => {
  const requestObj = {
    path: `api/email_templates/get_all_email_templates`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _delete_email_templates = async (id) => {
  const requestObj = {
    path: `api/email_templates/delete_email_template_by_id/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
