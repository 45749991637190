export const set_to_localStorage = (key, data) => {
  if (typeof data === "object") {
    localStorage.setItem(key, JSON.stringify(data));
  } else {
    localStorage.setItem(key, data);
  }
};

export const get_from_localStorage = (key) => {
  let parsed = undefined;
  let parse_success = true;
  try {
    parsed = JSON.parse(localStorage.getItem(key));
  } catch (error) {
    parse_success = false;
  }
  if (!parse_success) {
    let item = localStorage.getItem(key);

    return item;
  }
  return parsed;
};
export const del_from_local_storage = (key) => {
  let item = localStorage.getItem(key);
  if (item) {
    localStorage.removeItem(key);
  } else {
    console.error("item not found");
  }
};
export const check_from_local_storage = (key) => {
  if (localStorage.getItem(key)) {
    return true;
  } else {
    return false;
  }
};
export const logout_user = () => {
  localStorage.clear();
  // del_from_local_storage('token');
  // del_from_local_storage('user');
};
