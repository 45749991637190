import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import { useSnackbar } from "notistack";
import { _change_password_api } from "../DAL/Login/Login";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import CustomPasswordField from "./CustomPasswordField";
import { minWidth } from "@mui/system";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: { xs: 360, sm: 595 },
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
  borderRadius: 1,
};

export default function ChangePassword({
  openPasswordModal,
  setopenPasswordModal,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState("other");
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({
    password: "",
    repeatPassword: "",
    oldPassword: "",
  });

  const handleClose = () => {
    setopenPasswordModal(false);
    setInputs({
      password: "",
      repeatPassword: "",
      oldPassword: "",
    });
  };

  const handleChangeRadioButton = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleChange = (event) => {
    let { name, value } = event.target;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (inputs.password !== inputs.repeatPassword) {
      enqueueSnackbar("Password and Confirm Password do not match.", {
        variant: "error",
      });
      setIsLoading(false);
    } else {
      let postData = {
        old_password: inputs.oldPassword,
        new_password: inputs.password,
        confirm_password: inputs.repeatPassword,
        logout_from: selectedOption,
      };
      const result = await _change_password_api(postData);
      if (result.code === 200) {
        setInputs({});
        setIsLoading(false);
        handleClose();
        if (selectedOption == "all") {
          localStorage.clear();
          navigate("/login");
        }
        enqueueSnackbar("Password Changed Successfully", {
          variant: "success",
        });
      } else {
        setIsLoading(false);
        enqueueSnackbar(result.message, { variant: "error" });
      }
    }
  };

  return (
    <div>
      <Modal
        disableTypography="true"
        open={openPasswordModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form onSubmit={handlePasswordSubmit}>
          <Box sx={style} className="modal-theme">
            <div
              // clo-12
              className="text-end modalIcon pointer"
              onClick={() => {
                handleClose();
              }}
            >
              <i className="fa-solid fa-xmark"></i>
            </div>
            <Typography
              className="text-center mb-4"
              id="spring-modal-title"
              variant="h6"
              component="h2"
            >
              Change Password
            </Typography>
            <div className="col-12 mt-3">
              <CustomPasswordField
                id="outlined-basic"
                label="Old Password"
                name="oldPassword"
                Required={true}
                onChange={handleChange}
                value={inputs.oldPassword}
              />
            </div>
            <div className="col-12 mt-3">
              <CustomPasswordField
                id="outlined-basic"
                label="New Password"
                name="password"
                Required={true}
                onChange={handleChange}
                value={inputs.password}
              />
            </div>
            <div className="col-12 mt-3">
              <CustomPasswordField
                id="outlined-basic"
                label="Confirm Password"
                name="repeatPassword"
                Required={true}
                onChange={handleChange}
                value={inputs.repeatPassword}
              />
            </div>

            {/* <p className="mt-3">For security reasons, would you like to:</p> */}
            {/* <Typography
              className="text-center mb-4"
              id="spring-modal-title"
              variant="h6"
              component="h6"
            >
              For security reasons, would you like to:
            </Typography> */}

            <div className="mt-3">
              <FormControl component="fieldset">
                {/* <FormLabel component="legend">
                  For security reasons, would you like to:
                </FormLabel> */}
                <p className="mb-1">For security reasons, would you like to:</p>
                <RadioGroup
                  aria-label="logout-options"
                  name="logout-options"
                  value={selectedOption}
                  onChange={handleChangeRadioButton}
                  row
                >
                  <FormControlLabel
                    value="other"
                    control={<Radio />}
                    label="Logout from other devices"
                  />
                  <FormControlLabel
                    value="all"
                    control={<Radio />}
                    label="Logout from all devices"
                  />
                </RadioGroup>
              </FormControl>
            </div>

            <div className="col-12 mt-3">
              <Button type="submit" variant="contained" className="w-100">
                {isLoading ? "Submitting..." : "Submit"}
              </Button>
            </div>
          </Box>
        </form>
      </Modal>
    </div>
  );
}
