import { invokeApi } from "../invokeApi";

export const _add_user_api = async (data) => {
  const requestObj = {
    path: `api/business/add_business`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _edit_user_api = async (data, _id) => {
  const requestObj = {
    path: `api/business/edit_business/${_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _delete_user_api = async (_id) => {
  const requestObj = {
    path: `api/business/delete_business/${_id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _user_list_api = async (search_text, page, limit) => {
  const requestObj = {
    path: `api/business/list_business?text=${search_text}&page=${page}&limit=${limit}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _bussiness_detail_for_admin_api = async (id) => {
  const requestObj = {
    path: `api/business/bussiness_detail_for_admin/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _detail_customer_for_admin_api = async (id) => {
  const requestObj = {
    path: `api/customer/detail_customer_for_admin/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _users_list_for_payment_request_api = async (id, type) => {
  const requestObj = {
    path: `api/payment_request/users_list_for_payment_request?type=${type}&payment_request_id=${
      id ? id : ""
    }`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _add_customer_api = async (data) => {
  const requestObj = {
    path: `api/customer/add_customer`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _detail_client_api = async (data) => {
  const requestObj = {
    path: `api/business/detail_client`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _list_customer_api = async (search_text, page, limit) => {
  const requestObj = {
    path: `api/customer/list_customer?text=${search_text}&page=${page}&limit=${limit}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _edit_customer_by_admin_api = async (data, _id) => {
  const requestObj = {
    path: `api/customer/edit_customer_by_admin/${_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _delete_customer_api = async (_id) => {
  const requestObj = {
    path: `api/customer/delete_customer/${_id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _member_login_api = async (data) => {
  const requestObj = {
    path: `api/app_api/token_generate_for_impersonate`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _updated_fire_settings_by_admin_api = async (data) => {
  const requestObj = {
    path: `api/business/updated_fire_settings_by_admin`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _get_fire_settings_by_admin_api = async (id) => {
  const requestObj = {
    path: `api/business/get_fire_settings/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
