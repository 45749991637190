import React, { useState } from "react";
import CustomTable from "../../components/customTable/CustomTable";
import { Menu, MenuItem } from "@mui/material";
import DeleteConfirmation from "../../components/DeleteConfirmation";
import { PAYMENT_STATUS } from "../../utils/constant";

const ExpensesReport = () => {
  const [openDelete, setOpenDelete] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [userList, setUserList] = useState([
    {
      _id: 1,
      invoice_id: "Office Rent",
      client: "Mertz, Hand and Marks",
      Category: "16 Aug, 2022",
      price: "$820.00",
      status: "approved",
    },
    {
      _id: 1,
      invoice_id: "Office Rent",
      client: "Mertz, Hand and Marks",
      Category: "16 Aug, 2022",
      price: "$820.00",
      status: "pending",
    },
    {
      _id: 1,
      invoice_id: "Office Rent",
      client: "Mertz, Hand and Marks",
      Category: "16 Aug, 2022",
      price: "$820.00",
      status: "approved",
    },
    {
      _id: 1,
      invoice_id: "Office Rent",
      client: "Mertz, Hand and Marks",
      Category: "16 Aug, 2022",
      price: "$820.00",
      status: "pending",
    },
    {
      _id: 1,
      invoice_id: "Office Rent",
      client: "Mertz, Hand and Marks",
      Category: "16 Aug, 2022",
      price: "$820.00",
      status: "approved",
    },
    {
      _id: 1,
      invoice_id: "Office Rent",
      client: "Mertz, Hand and Marks",
      Category: "16 Aug, 2022",
      price: "$820.00",
      status: "pending",
    },
    {
      _id: 1,
      invoice_id: "Office Rent",
      client: "Mertz, Hand and Marks",
      Category: "16 Aug, 2022",
      price: "$820.00",
      status: "approved",
    },
    {
      _id: 1,
      invoice_id: "Office Rent",
      client: "Mertz, Hand and Marks",
      Category: "16 Aug, 2022",
      price: "$820.00",
      status: "pending",
    },
    {
      _id: 1,
      invoice_id: "Office Rent",
      client: "Mertz, Hand and Marks",
      Category: "16 Aug, 2022",
      price: "$820.00",
      status: "approved",
    },
    {
      _id: 1,
      invoice_id: "Office Rent",
      client: "Mertz, Hand and Marks",
      Category: "16 Aug, 2022",
      price: "$820.00",
      status: "pending",
    },
  ]);

  const TABLE_HEAD = [
    { id: "invoice_id", label: "EXPENSES" },
    { id: "client", label: "COMPANY" },
    { id: "Category", label: "EXPENSE DATE" },
    { id: "price", label: "AMOUNT" },
    {
      id: "status",
      label: "STATUS",
      renderData: (row) => {
        let find_status = PAYMENT_STATUS.find(
          (status) => status.value == row.status
        );
        return (
          <>
            {!!find_status && (
              <span className={`${find_status.class}`}>{find_status.name}</span>
            )}
          </>
        );
      },
    },
  ];

  return (
    <>
      <div className="page-title-box">
        <h4>EXPENSES REPORT</h4>
      </div>
      <div className="d-lg-flex align-items-center">
        <div className="position-relative search_input_table dashboard_input mb-lg-0 mb-3">
          <input
            type="text"
            className="form-control ps-3"
            placeholder="Search..."
            id="search-options"
          />
          <i className="fa-solid fa-magnifying-glass search_icon"></i>
        </div>
        <div className="ms-auto d-flex gap-2">
          <button type="button" className="btn btn-soft-primary fs-14">
            <i className="fa-solid fa-filter me-2 "></i>
            Filter
          </button>
          <button className="btn btn-soft-info" onClick={handleClick}>
            <i className="fa-solid fa-ellipsis-vertical"></i>
          </button>
        </div>
      </div>
      <div className="mt-4">
        <CustomTable data={userList} TABLE_HEAD={TABLE_HEAD} is_hide={true} />
      </div>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0 5px 10px rgba(30,32,37,.12))",
            mt: 1.5,
            // width: "160px",
          },
        }}
      >
        <MenuItem
          style={{ fontSize: "15px", color: "#0f0f12" }}
          onClick={handleClose}
          sx={{
            paddingY: "4px",
            paddingRight: "16px",
          }}
        >
          Print
        </MenuItem>
        <MenuItem
          style={{ fontSize: "15px", color: "#0f0f12" }}
          onClick={handleClose}
          sx={{
            paddingY: "4px",
            paddingRight: "16px",
          }}
        >
          Export to Excel
        </MenuItem>
      </Menu>
    </>
  );
};

export default ExpensesReport;
