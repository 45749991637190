import React, { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  FormHelperText,
  TextField,
} from "@mui/material";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { styled } from "@mui/material/styles";
import { s3BaseUrl } from "../../config/config";
import {
  _edit_gernal_settings_api,
  _get_gernal_settings_api,
  _get_thanks_settings_api,
  _update_payment_settings_api,
  _upload_image_s3_api,
} from "../../DAL/Setting/Setting";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import GeneralCkeditor from "../../components/GeneralCkeditor";
import { LoadingButton } from "@mui/lab";

const Input = styled("input")({
  display: "none",
});

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const ThankYouSetting = () => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [isGetLoading, setIsGetLoading] = useState(true);
  const [isPicLoading, setIsPicLoading] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const [inputs, setInputs] = useState({
    button_text: "",
    button_link: "",
    thanks_img: "",
    thanks_description: "",
  });

  const [images, setImages] = useState({
    thanks_img: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const fileChangedHandler = async (e) => {
    const { name, files } = e.target;
    if (files[0]) {
      setIsPicLoading(name);
      const formData = new FormData();
      formData.append("image", files[0]);
      const result = await _upload_image_s3_api(formData);
      if (result.code == 200) {
        setImages({ ...images, [name]: URL.createObjectURL(files[0]) });
        setInputs({ ...inputs, [name]: result.path });
        setIsPicLoading("");
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsPicLoading("");
      }
    }
  };

  const get_setting_data = async () => {
    setIsGetLoading(true);
    const result = await _get_thanks_settings_api();
    if (result.code == 200) {
      setInputs(result?.payment_thanks_settings);
      setIsGetLoading(false);
    } else {
      setIsGetLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let postData = {
      button_text: inputs.button_text,
      button_link: inputs.button_link,
      thanks_img: inputs.thanks_img,
      thanks_description: inputs.thanks_description,
    };
    const result = await _update_payment_settings_api(postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    get_setting_data();
  }, []);

  if (isGetLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      <form onSubmit={handleSubmit}>
        <div className="row ">
          <div className="page-title-box">
            <h4>Thanks Settings</h4>
          </div>
          <div className="col-12 col-md-6 mt-3">
            <TextField
              id="outlined-basic"
              label="Button Text"
              variant="outlined"
              fullWidth
              placeholder="Button Text"
              name="button_text"
              value={inputs.button_text}
              onChange={handleChange}
            />
          </div>
          <div className="col-12 col-md-6 mt-3">
            <TextField
              id="outlined-basic"
              label="Button Link"
              variant="outlined"
              fullWidth
              placeholder="Button Link"
              name="button_link"
              value={inputs.button_link}
              onChange={handleChange}
            />
          </div>
          <div className="col-12 mt-4">
            <div className="row w-100 div-style ms-0 pt-0">
              <div className="col-lg-5">
                <p className="text-black">Thanks Image</p>
                <FormHelperText className="pt-0 helper_text">
                  Recommended size (1200px * 800px )
                </FormHelperText>
              </div>
              <div className="col-lg-2">
                {isPicLoading === "thanks_img" ? (
                  <CircularProgress size="1.5rem" color="primary" />
                ) : (
                  <img
                    src={
                      images.thanks_img
                        ? images.thanks_img
                        : inputs?.thanks_img
                        ? s3BaseUrl + inputs?.thanks_img
                        : ""
                    }
                    height="50"
                  />
                )}
              </div>
              <div className="col-lg-5 text-end pt-2">
                <label htmlFor="contained-button-file">
                  <Input
                    accept="image/*"
                    id="contained-button-file"
                    multiple
                    type="file"
                    name="thanks_img"
                    onChange={fileChangedHandler}
                  />

                  <Button
                    className="btn btn-primary "
                    startIcon={<FileUploadIcon />}
                    component="span"
                  >
                    Upload
                  </Button>
                </label>
              </div>
            </div>
            {inputs.thanks_img?.thanks_img == "" ? (
              ""
            ) : (
              <p className="text-secondary">{inputs.thanks_img?.name}</p>
            )}
          </div>
          <div className="col-12 mt-3">
            <h6 className="text-black">Thanks Description *</h6>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="thanks_description"
              editorHeight={320}
            />
          </div>
          <div className="text-end mt-4">
            <button
              className="btn btn-primary mt-4 "
              disabled={isLoading || isPicLoading}
            >
              {isLoading ? "Updating..." : "Update"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ThankYouSetting;
