import React, { useEffect, useState } from "react";
import ActiveLastBreadcrumb from "../../components/ActiveLastBreadcrumb";
import CustomTable from "../../components/customTable/CustomTable";
import {
  _get_payment_request_api,
  _send_invoice_reminder_api,
} from "../../DAL/Payment";
import { useParams } from "react-router-dom";
import {
  TRANSACTION_STATUS,
  convertCurrencyToSign,
  show_proper_words,
} from "../../utils/constant";
import moment from "moment";
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Tooltip,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import FullPagePopupForTitle from "../../components/FullPagePopupForTitle";
import EmailSetting from "./EmailSetting";
import CustomConfirmation from "../../components/CustomConfirmation";
import { useSnackbar } from "notistack";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const PaymentDetail = () => {
  const params = useParams();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [filterOpen, setFilterOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [userList, setUserList] = useState([]);
  const [checked, setChecked] = useState(true);
  const [open, setOpen] = useState(false);

  const get_payment_list = async () => {
    setIsLoading(true);
    const result = await _get_payment_request_api(params.id);
    if (result.code === 200) {
      let data = result?.transaction_list.map((item) => {
        let created_At = "N/A";
        if (item.createdAt) {
          created_At = moment(item.createdAt).format("DD-MM-YYYY");
        }
        return {
          ...item,
          created_At,
        };
      });
      setUserInfo(result.payment_request);
      setUserList(data);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleSendReminder = () => {
    setOpen(true);
  };

  const handleSubmit = async () => {
    setLoading(true);
    const result = await _send_invoice_reminder_api(params.id);
    if (result.code == 200) {
      setOpen(false);
      enqueueSnackbar(result.message, { variant: "success" });
      setLoading(false);
    } else {
      setLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleOpenFilterDrawer = () => {
    setFilterOpen(true);
  };

  const handleCloseFilterDrawer = () => {
    setFilterOpen(false);
  };

  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    {
      id: "transaction_amount",
      label: "Transaction Amount",
      renderData: (row) => {
        let trans_info = row.payment_request_info;
        return (
          <span>{`${
            convertCurrencyToSign(trans_info?.currency) +
            row?.transaction_amount
          }`}</span>
        );
      },
    },
    { id: "created_At", label: "Transaction Date" },
    {
      id: "currency",
      label: "Currency",
      renderData: (row) => {
        let trans_info = row.payment_request_info;
        return (
          <span>{trans_info.currency === "GBP" ? "UK Pounds" : "Euro"}</span>
        );
      },
    },
    {
      id: "status",
      label: "Status",
      renderData: (row) => {
        let find_status = TRANSACTION_STATUS.find(
          (status) => status.value == row.status
        );
        return (
          <span className={`${find_status?.class}`}>{find_status?.name}</span>
        );
      },
    },
  ];
  let breadCrumbMenu = [
    {
      title: "Invoices",
      navigation: `/invoices`,
      active: false,
    },
    {
      title:
        userInfo?.client_info?.first_name +
        " " +
        userInfo?.client_info?.last_name +
        " (" +
        userInfo?.client_info?.email +
        ")",
      active: true,
    },
  ];

  useEffect(() => {
    get_payment_list();
  }, []);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      <div className="row">
        <div className="d-flex justify-content-between flex-wrap ">
          <div className="page-title-box">
            {/* <h4>Payment Request Detail</h4> */}
            <h4>Invoice Detail</h4>
          </div>
          <div>
            <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
          </div>
        </div>
        <div className="col-12 mt-3">
          <div className="card">
            <div className="card-body">
              <div className="row px-3 client_profile ">
                <div className="col-12 p-2">
                  <h5>{userInfo?.request_title}</h5>
                </div>
                <div className="col-6 col-md-3 p-2">
                  <span>Request Type:</span>
                </div>
                <div className="col-6 col-md-3 p-2 text_muted">
                  {show_proper_words(userInfo?.request_type)}
                </div>

                <div className="col-6 col-md-3 p-2">
                  <span>Total Amount:</span>
                </div>
                <div className="col-6 col-md-3 p-2 text_muted ">
                  {convertCurrencyToSign(userInfo?.currency) +
                    (userInfo?.total_amount ?? 0)}
                </div>

                {userInfo?.request_type == "recurring" ? (
                  <>
                    {" "}
                    <div className="col-6 col-md-3 p-2">
                      <span>Interval Type:</span>
                    </div>
                    <div className="col-6 col-md-3 p-2 text_muted">
                      {show_proper_words(userInfo?.interval_type)}
                    </div>
                  </>
                ) : null}

                {userInfo.request_type == "recurring" && (
                  <>
                    <div className="col-6 col-md-3 p-2">
                      <span>Initial Amount:</span>
                    </div>
                    <div className="col-6 col-md-3 p-2 text_muted ">
                      {convertCurrencyToSign(userInfo?.currency) +
                        (userInfo?.initial_amount ?? 0)}
                    </div>
                    <div className="col-6 col-md-3 p-2">
                      <span> Installment Amount:</span>
                    </div>
                    <div className="col-6 col-md-3 p-2 text_muted ">
                      {convertCurrencyToSign(userInfo?.currency) +
                        (userInfo?.installment_amount ?? 0)}
                    </div>
                    <div className="col-6 col-md-3 p-2">
                      <span>No of Installment:</span>
                    </div>
                    <div className="col-6 col-md-3 p-2 text_muted">
                      {userInfo?.no_of_installment}
                    </div>
                  </>
                )}
                <div className="col-6 col-md-3 p-2">
                  <span>Created At:</span>
                </div>
                <div className="col-6 col-md-3 p-2 text_muted">
                  {moment(userInfo?.createdAt).format("DD-MM-YYYY")}
                </div>

                {userInfo?.request_type == "recurring" &&
                userInfo?.interval_type == "custom" ? (
                  <>
                    {" "}
                    <div className="col-6 col-md-3 p-2">
                      <span>Number of days:</span>
                    </div>
                    <div className="col-6 col-md-3 p-2 text_muted">
                      {userInfo?.no_of_days}
                    </div>
                  </>
                ) : null}
              </div>
              <div className="mt-3 d-flex justify-content-between align-align-items-center">
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checked}
                        onChange={(e) => {
                          setChecked(e.target.checked);
                        }}
                      />
                    }
                    label="Email"
                    sx={{
                      marginRight: "5px",
                    }}
                  />
                  {checked && (
                    <Tooltip title="Update Email Content">
                      <BorderColorIcon
                        style={{
                          cursor: "pointer",
                          // marginLeft: "10px",
                          fontSize: "18px",
                        }}
                        onClick={handleOpenFilterDrawer}
                      />
                    </Tooltip>
                  )}
                </div>
                {checked && (
                  <div>
                    <Button variant="contained" onClick={handleSendReminder}>
                      Send Reminder
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="mt-2">
            <CustomTable
              data={userList}
              TABLE_HEAD={TABLE_HEAD}
              is_hide={true}
            />
          </div>
        </div>
      </div>
      <FullPagePopupForTitle
        open={filterOpen}
        handleClosePopup={handleCloseFilterDrawer}
        title="Email Reminder Configuration"
        componentToPassDown={<EmailSetting onClose={handleCloseFilterDrawer} />}
      />
      <CustomConfirmation
        open={open}
        setOpen={setOpen}
        loading={loading}
        title={"Are you sure you want to send reminder ?"}
        handleAgree={handleSubmit}
      />
    </div>
  );
};

export default PaymentDetail;
