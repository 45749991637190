import React, { useEffect, useRef, useState } from "react";
import ReactTextEditorKit from "./ReactTextEditorKit";
import { invokeApi } from "../DAL/invokeApi";
import { s3BaseUrl } from "../config/config";

export default function GeneralCkeditor({
  inputs,
  setInputs,
  name,
  editorHeight,
  is_array,
  index,
  value,
  resetData,
  setResetData = () => {},
}) {
  console.log(inputs, "inputsinputsinputs");
  const editorRef = useRef(null);
  const [isChanged, setIsChanged] = useState(false); // Flag state

  const log = () => {
    if (editorRef.current) {
      if (is_array) {
        const list = [...inputs];
        list[index][name] = editorRef.current.getContent();
        setInputs(list);
      } else if (inputs) {
        setInputs((prevState) => ({
          ...prevState,
          [name]: editorRef.current.getContent(),
        }));
      } else {
        setInputs(editorRef.current.getContent());
      }
    }
  };

  const handleChange = (value) => {
    setResetData(false);
    if (is_array) {
      const list = [...inputs];
      list[index][name] = value;
      setInputs(list);
    } else if (inputs) {
      setInputs((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    } else {
      setInputs(value);
    }
  };

  function example_image_upload_handler(blobInfo, success, failure, progress) {
    let requestObj = {
      path: "/app/upload_image/for_editor",
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };

    let _formData = new FormData();
    _formData.append("image", blobInfo.blob());
    requestObj["postData"] = _formData;

    invokeApi(requestObj).then((res) => {
      if (res.code === 200) {
        success(s3BaseUrl + res.image_path);
      } else {
        failure(res.message);
      }
    });
  }

  const editor_value = () => {
    let value_data = "";
    if (is_array && inputs[index][name]) {
      value_data = inputs[index][name];
    } else if (inputs[name]) {
      value_data = inputs[name];
    } else if (value) {
      value_data = value;
    }
    return value_data;
  };

  React.useEffect(() => {
    console.log("Data changed in Child Component:", value);
  }, [value]); // Re-run effect when `data` changes

  // Effect that listens to changes in the `value` and sets flag with a timer
  useEffect(() => {
    console.log("Value changed in GeneralCkeditor:", value);

    if (resetData) {
      setIsChanged(true);
      const timer = setTimeout(() => {
        setIsChanged(false);
      }, 1);

      return () => clearTimeout(timer);
    }
  }, [inputs[name]]);

  return (
    <>
      {!isChanged ? (
        <ReactTextEditorKit
          onChange={handleChange}
          value={editor_value()}
          get_editor_ref={(ref) => (editorRef.current = ref)}
        />
      ) : (
        <div
          style={{
            height: "315px",
            backgroundColor: "#fff",
            border: "1px solid #c4c4c4",
          }}
        ></div>
      )}
    </>
  );
}
