import React, { useEffect, useState } from "react";
import { Button, CircularProgress, MenuItem, TextField } from "@mui/material";
import {
  _add_email_template,
  _get_email_template_by_id,
  _update_email_template_by_id,
} from "../../DAL/Setting/Setting";
import { useSnackbar } from "notistack";
import TagsInput from "react-tagsinput";
import GeneralCkeditor from "../../components/GeneralCkeditor";
import { useNavigate, useParams } from "react-router-dom";
import ActiveLastBreadcrumb from "../../components/ActiveLastBreadcrumb";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const AddEmailTemplates = () => {
  const classes = useStyles();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [emailTags, setEmailTags] = useState([]);
  const navigate = useNavigate();
  const [isGetLoading, setIsGetLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [inputs, setInputs] = useState({
    title: "",
    email_body_heading: "",
    email_subject: "",
    main_email: "",
    status: true,
    email_message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const get_email_template_by_id_data = async () => {
    setIsGetLoading(true);
    const result = await _get_email_template_by_id(id);
    if (result.code === 200) {
      setInputs({
        ...result.email_template,
        body: result.email_template.email_body_heading,
      });
      setEmailTags(result.email_template.cc_email);
      setIsGetLoading(false);
    } else {
      setIsGetLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let postData = {
      title: inputs.title,
      email_subject: inputs.email_subject,
      email_message: inputs.email_message,
      status: inputs.status,
      email_body_heading: inputs.email_body_heading,
      main_email: inputs.main_email,
      cc_email: emailTags,
    };
    const result = id
      ? await _update_email_template_by_id(postData, id)
      : await _add_email_template(postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      navigate(`/email-templates`);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  let breadCrumbMenu = [
    {
      title: "Email Templates",
      navigation: `/email-templates`,
      active: false,
    },
    {
      title: id ? "Edit Email Template" : "Add Email Template",
      active: true,
    },
  ];

  useEffect(() => {
    if (id) {
      get_email_template_by_id_data();
    } else {
      setIsGetLoading(false);
    }
  }, []);

  if (isGetLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      <form onSubmit={handleSubmit}>
        <div className="row ">
          <div className="d-flex justify-content-between align-items-center flex-wrap ">
            <div className="page-title-box">
              <h4>{id ? "Edit Email Template" : "Add Email Template"}</h4>
            </div>
            <div>
              <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} />
            </div>
          </div>
          <div className="col-12 col-md-6 mt-3">
            <TextField
              id="outlined-basic"
              label="Template Title"
              variant="outlined"
              fullWidth
              placeholder="Template Title"
              name="title"
              value={inputs.title}
              onChange={handleChange}
              required
            />
          </div>
          <div className="col-12 col-md-6 mt-3">
            <TextField
              id="outlined-basic"
              label="Email Body Heading"
              variant="outlined"
              fullWidth
              placeholder="Email Body Heading"
              name="email_body_heading"
              value={inputs.email_body_heading}
              onChange={handleChange}
              required
            />
          </div>
          <div className="col-12 col-md-6 mt-3">
            <TextField
              id="outlined-basic"
              label="Email Subject"
              variant="outlined"
              fullWidth
              placeholder="Email Subject"
              name="email_subject"
              value={inputs.email_subject}
              onChange={handleChange}
              required
            />
          </div>
          <div className="col-12 col-md-6 mt-3">
            <TextField
              id="outlined-basic"
              label="Main Email"
              variant="outlined"
              fullWidth
              placeholder="Main Email"
              name="main_email"
              value={inputs.main_email}
              onChange={handleChange}
              type="email"
            />
          </div>
          <div className="col-12 col-md-6 mt-3">
            <TextField
              fullWidth
              id="outlined-basic"
              label="Status"
              variant="outlined"
              select
              value={inputs.status}
              onChange={handleChange}
              name="status"
              required
            >
              <MenuItem value={true}>Active</MenuItem>
              <MenuItem value={false}>Inactive</MenuItem>
            </TextField>
          </div>
          <div className="col-12 col-md-12 mt-3">
            <TagsInput
              value={emailTags}
              onChange={setEmailTags}
              inputProps={{ placeholder: "CC Emails" }}
              name="cc_emails"
              type="email"
              label="cc_emails"
            />
          </div>
          <div className="col-12 mt-3">
            <h6 className="text-black">Email Body Message *</h6>
            <GeneralCkeditor
              setInputs={setInputs}
              inputs={inputs}
              name="email_message"
              editorHeight={320}
            />
          </div>
          <div className="text-end mt-4">
            <Button variant="contained" type="submit">
              {id
                ? `${isLoading ? "Loading.." : " Update"}`
                : `${isLoading ? "Loading.." : "Add Email Template"}`}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddEmailTemplates;
