import { invokeApi } from "../invokeApi";

export const _admin_users_list = async (data, page, limit) => {
  const requestObj = {
    path: `api/admin/admin_list?page=${page}&limit=${limit}&text=${data}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: [],
  };
  return invokeApi(requestObj);
};

export const _add_admin_user = async (data) => {
  const requestObj = {
    path: `api/admin/add_admin`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _delete_admin_user = async (_id) => {
  const requestObj = {
    path: `api/admin/delete_admin/${_id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _edit_admin_by_admin = async (data, _id) => {
  const requestObj = {
    path: `api/admin/edit_admin_by_admin/${_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _detail_admin_by_userid = async (id) => {
  const requestObj = {
    path: `api/admin/detail_admin_by_userid/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
