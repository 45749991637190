import { invokeApi } from "./invokeApi";

export const _add_department_api = async (data) => {
  const requestObj = {
    path: `api/department/add_department`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _update_department_api = async (data, id) => {
  const requestObj = {
    path: `api/department/update_department/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _get_department_by_id_api = async (id) => {
  const requestObj = {
    path: `api/department/get_department_by_id/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _list_department_api = async (page, limit, search) => {
  const requestObj = {
    path: `api/department/list_all_department?text=${search}&page=${page}&limit=${limit}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _delete_department_api = async (id) => {
  const requestObj = {
    path: `api/department/delete_department_by_id/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

// Autoresponder Message Api

export const _add_autoresponder_message_api = async (data) => {
  const requestObj = {
    path: `api/autoresponder_message/add_autoresponder_message`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _list_autoresponder_message_api = async () => {
  const requestObj = {
    path: `api/autoresponder_message/list_autoresponder_message`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _list_all_autoresponder_message_api = async (
  page,
  limit,
  search
) => {
  const requestObj = {
    path: `api/autoresponder_message/list_all_autoresponder_message?text=${search}&page=${page}&limit=${limit}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _get_autoresponder_message_by_id_api = async (id) => {
  const requestObj = {
    path: `api/autoresponder_message/get_autoresponder_message_by_id/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _updated_autoresponder_message_api = async (data, id) => {
  const requestObj = {
    path: `api/autoresponder_message/updated_autoresponder_message/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _delete_message_api = async (id) => {
  const requestObj = {
    path: `api/autoresponder_message/delete_autoresponder_message_by_id/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
