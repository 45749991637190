import React from "react";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CustomTable from "../../../components/customTable/CustomTable";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const Payments = ({ paymentList, TABLE_HEAD, isTabLoading }) => {
  const classes = useStyles();

  if (isTabLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <CustomTable data={paymentList} TABLE_HEAD={TABLE_HEAD} is_hide={true} />
    </>
  );
};

export default Payments;
