import React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { useEffect } from "react";
import {
  _get_stripe_settings_api,
  _update_stripe_settings_api,
} from "../../DAL/Setting/Setting";
import { Button, CircularProgress } from "@mui/material";
import { LoadingButton } from "@mui/lab";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function StripeSetting() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingForm, setIsLoadingForm] = useState(true);

  const [inputs, setInputs] = useState({
    sandBox_publish_key: "",
    sandBox_secret_key: "",
    live_publish_key: "",
    live_secret_key: "",
    stripe_mode: "sandBox",
    sandbox_stripe_reader_registration_code: "",
    stripe_reader_registration_code: "",
    stripe_payment_fee_percentage: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleChangefloat = (event) => {
    const inputValue = event.target.value;
    const isValidInput = /^\d*\.?\d*$/.test(inputValue);
    if (isValidInput || inputValue === "") {
      setInputs({
        ...inputs,
        stripe_payment_fee_percentage: inputValue,
      });
    }
  };

  const get_stripe_settings = async () => {
    setIsLoadingForm(true);
    const result = await _get_stripe_settings_api();
    if (result.code == 200) {
      setInputs(result?.setting);
      setIsLoadingForm(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingForm(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let postData = {
      sandBox_publish_key: inputs.sandBox_publish_key,
      sandBox_secret_key: inputs.sandBox_secret_key,
      live_publish_key: inputs.live_publish_key,
      live_secret_key: inputs.live_secret_key,
      stripe_mode: inputs.stripe_mode,
      stripe_reader_registration_code: inputs.stripe_reader_registration_code,
      stripe_payment_fee_percentage: inputs.stripe_payment_fee_percentage,
      sandbox_stripe_reader_registration_code:
        inputs.sandbox_stripe_reader_registration_code,
    };
    const result = await _update_stripe_settings_api(postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    get_stripe_settings();
  }, []);

  if (isLoadingForm == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <h4>Stripe Settings</h4>
            </div>
          </div>
          <div className="col-12 col-md-6 mt-3">
            <TextField
              id="outlined-basic"
              label="Sandbox Publish Key"
              placeholder="Sandbox Publish Key"
              variant="outlined"
              fullWidth
              required
              aria-readonly
              name="sandBox_publish_key"
              value={inputs.sandBox_publish_key}
              onChange={handleChange}
            />
          </div>
          <div className="col-12 col-md-6 mt-3">
            <TextField
              id="outlined-basic"
              label="Sandbox Secret Key "
              placeholder="Sandbox Secret Key "
              variant="outlined"
              fullWidth
              required
              aria-readonly
              name="sandBox_secret_key"
              value={inputs.sandBox_secret_key}
              onChange={handleChange}
            />
          </div>
          <div className="col-12 col-md-6 mt-3">
            <TextField
              id="outlined-basic"
              label="Live Publish Key"
              placeholder="Live Publish Key"
              variant="outlined"
              fullWidth
              required
              name="live_publish_key"
              value={inputs.live_publish_key}
              onChange={handleChange}
            />
          </div>
          <div className="col-12 col-md-6 mt-3">
            <TextField
              id="outlined-basic"
              label="Live Secret Key"
              placeholder="Live Secret Key"
              variant="outlined"
              fullWidth
              required
              name="live_secret_key"
              value={inputs.live_secret_key}
              onChange={handleChange}
            />
          </div>
          <div className="col-12 col-md-6 mt-3">
            <TextField
              id="outlined-basic"
              label="Sandbox Stripe Reader Registration Code"
              placeholder="Sandbox Stripe Reader Registration Code"
              variant="outlined"
              fullWidth
              name="sandbox_stripe_reader_registration_code"
              value={inputs.sandbox_stripe_reader_registration_code}
              onChange={handleChange}
            />
          </div>
          <div className="col-12 col-md-6 mt-3">
            <TextField
              id="outlined-basic"
              label="Live Stripe Reader Registration Code"
              placeholder="Live Stripe Reader Registration Code"
              variant="outlined"
              fullWidth
              name="stripe_reader_registration_code"
              value={inputs.stripe_reader_registration_code}
              onChange={handleChange}
            />
          </div>
          <div className="col-12 col-md-6 mt-3">
            <TextField
              id="outlined-basic"
              label="Dynamite Fire Payment Fee Percentage"
              placeholder="Dynamite Fire Payment Fee Percentage"
              variant="outlined"
              fullWidth
              aria-readonly
              type="text"
              InputProps={{
                endAdornment: <span>%</span>,
                inputProps: { min: 0, max: 100 },
              }}
              name="stripe_payment_fee_percentage"
              value={inputs.stripe_payment_fee_percentage}
              onChange={handleChangefloat}
            />
          </div>
          <div className="col-12 col-md-6 mt-3">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">Stripe Mode</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="stripe_mode"
                value={inputs.stripe_mode}
                label="Stripe Mode *"
                onChange={handleChange}
              >
                <MenuItem value="sandBox">SandBox</MenuItem>
                <MenuItem value="live">Live</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="text-end mt-4">
            <button className="btn btn-primary mt-4 " disabled={isLoading}>
              {isLoading ? "Updating..." : "Update"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
