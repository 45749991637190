import { invokeApi } from "./invokeApi";

export const _add_stripe_products_api = async (data) => {
  const requestObj = {
    path: `api/stripe_products/add_stripe_products`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _update_stripe_products_api = async (data, id) => {
  const requestObj = {
    path: `api/stripe_products/update_stripe_products/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _list_stripe_products_api = async (search_text, page, limit) => {
  const requestObj = {
    path: `api/stripe_products/list_stripe_products?text=${search_text}&page=${page}&limit=${limit}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _delete_stripe_products_api = async (id) => {
  const requestObj = {
    path: `api/stripe_products/delete_stripe_products/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
